import { DRAG_CIRCLE_DIAMETER, WALL_THICKNESS, PLAYABLE_AREA } from './constants.js';
import defaultSettings from './defaultSettings.js';
import { calculateForce, checkIfMobile } from './utils';

export default class InputManager {
    constructor(app, ballManager, gameStateManager, renderManager, gameWorld, viewportManager, uiManager, playerList, chat) {
        this.app = app;
        this.ballManager = ballManager;
        this.gameStateManager = gameStateManager;
        this.renderManager = renderManager;
        this.gameWorld = gameWorld;
        this.viewportManager = viewportManager;
        this.uiManager = uiManager;
        this.playerList = playerList;
        this.chat = chat;
        this.isMouseInput = true;

        this.settings = JSON.parse(localStorage.getItem('golfUpSettings')) || defaultSettings;
        this.currentControlType = this.settings.player.controlType;

        this.mousePosition = { x: 0, y: 0 };
        this.forceText = this.renderManager.createForceText();

        this.cursorBounce = 0;

        this.lastMouseEvent = null;

        this.touchStartPosition = null;

        this.leftJoystickY = 0;
        this.rightJoystickX = 0;
        this.rightJoystickY = 0;
        this.buttonAPressed = false;
        this.buttonBPressed = false;
        this.buttonYPressed = false;
        this.buttonXPressed = false;

        this.horizontalMargin = 0.1; // 10% of viewport width
        this.verticalMargin = 0.1; // 20% of viewport height
        this.updateDragZoneDimensions();

        this.setupEventListeners();
        this.setInitialScrollState();
        this.setupControlScheme(this.currentControlType);
        window.addEventListener('resize', this.handleResize.bind(this));


    }

    setupControlScheme(controlType) {
        this.currentControlType = controlType;
        if (controlType === "click-drag" || checkIfMobile()) {
            this.setupClickDragMode();
        } else {
            this.setupOneClickMode();
        }
    }

    setupOneClickMode() {
        this.isClickDragMode = false;
        this.dragStartPosition = null;
        this.currentDragPosition = null;
        this.puttingLine = null;
        this.renderManager.clearDragZone();
        this.renderManager.clearCursorGraphics();
    }


    setupEventListeners() {
        this.app.view.addEventListener('mousemove', (e) => {
            this.isMouseInput = true;
            this.handleMouseMove(e);
        });

        this.app.view.addEventListener('touchstart', (e) => {
            this.isMouseInput = false;
            this.handleTouchStart.bind(this)
        });
        this.app.view.addEventListener('mousedown', this.handleMouseDown.bind(this));
        this.app.view.addEventListener('mouseup', this.handleClickEnd.bind(this));
        this.app.view.addEventListener('touchstart', this.handleTouchStart.bind(this));
        this.app.view.addEventListener('touchmove', this.handleTouchMove.bind(this));
        this.app.view.addEventListener('touchend', this.handleClickEnd.bind(this));
        window.addEventListener('wheel', this.handleScroll.bind(this), { passive: false });

        // prevent right click context menu
        this.app.view.addEventListener('contextmenu', (e) => e.preventDefault());

    }

    handleScroll(event) {
        if (this.uiManager?.popupManager?.open) return;
        const scrollChanged = this.viewportManager.updateScrollPosition(event.deltaY);
        if (scrollChanged) {
            this.updateMousePositionOnScroll();
        }
    }

    setInitialScrollState() {
        this.scrollPosition = 0;
        this.gameWorld.y = 0;
        this.viewportManager.updateScrollPosition(this.scrollPosition);
    }

    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.gameStateManager.roomState.roundStartTime + this.gameStateManager.roomState.roundDuration >= Date.now()) {
            if (event.button === 0) {
                if (this.isClickDragMode) {
                    const clickPosition = this.getMousePosition(event).screen;
                    if (this.isInsideDragZone(clickPosition)) {
                        this.dragStartPosition = clickPosition;
                        this.currentDragPosition = clickPosition;
                        this.updateDragLine();
                    }
                } else {
                    const position = this.getMousePosition(event);
                    this.ballManager.strike(position.world.x, position.world.y);
                }
            }
        }
    }

    handleClickEnd(event) {
        if (this.gameStateManager.isGameOver) return;
        if (this.isClickDragMode && this.dragStartPosition && this.currentDragPosition) {
            const dx = this.currentDragPosition.x - this.dragStartPosition.x;
            const dy = this.currentDragPosition.y - this.dragStartPosition.y;
            const dragDistance = Math.sqrt(dx * dx + dy * dy);

            const minDragDistance = 5;

            if (dragDistance >= minDragDistance) {
                const ballPosition = this.ballManager.getPosition();
                const virtualPutPosition = {
                    x: ballPosition.x + dx + WALL_THICKNESS,
                    y: ballPosition.y + dy
                };

                this.ballManager.strike(virtualPutPosition.x, virtualPutPosition.y);
            } else {
            }
            this.resetDragState();
        }

        if (this.isMouseInput) {
            this.updateAimingGraphics(this.mousePosition);
        }
    }

    isInsideCenterCircle(position) {
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight * 0.75;
        const dx = position.x - centerX;
        const dy = position.y - centerY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance <= DRAG_CIRCLE_DIAMETER / 2;
    }

    updateDragLine() {
        if (this.dragStartPosition && this.currentDragPosition) {
            this.renderManager.updatePuttingLine(this.dragStartPosition, this.currentDragPosition);
            this.updateDragCursorGraphics(this.currentDragPosition.x, this.currentDragPosition.y);
        } else {
            this.renderManager.clearCursorGraphics();
            this.renderManager.clearPuttingLine();
        }
    }

    updateDragCursorGraphics(endX, endY) {
        if (!this.dragStartPosition) return;

        const dx = endX - this.dragStartPosition.x;
        const dy = endY - this.dragStartPosition.y;
        const dragDistance = Math.sqrt(dx * dx + dy * dy);

        const maxDragDistance = Math.max(this.dragZoneWidth, this.dragZoneHeight);
        const normalizedDragDistance = Math.min(dragDistance / maxDragDistance, 1);
        const force = normalizedDragDistance * (0.04 - 0.002) + 0.002;

        const normalisedForce = Math.floor(normalizedDragDistance * 9) + 1;
        const _pos = this.touchStartPosition ? this.touchStartPosition : { x: endX, y: endY }
        const position = this.getMousePosition(this.lastMouseEvent ? this.lastMouseEvent : { clientX: endX, clientY: endY });
        this.renderManager.updateCursorTriangle(
            position.screen,
            _pos,
            force,
            this.cursorBounce,
            this.isClickDragMode
        );
        this.renderManager.updateForceText(this.forceText, normalisedForce, endX, endY);
    }

    handleTouchStart(event) {
        event.preventDefault();
        this.isMouseInput = false;
        const touch = event.touches[0];
        this.touchStartPosition = {
            x: touch.clientX,
            y: touch.clientY
        };
        if (this.isClickDragMode && this.isInsideCenterCircle(this.touchStartPosition)) {
            this.dragStartPosition = this.touchStartPosition;
            const currentPosition = this.dragStartPosition
            if (this.isClickDragMode && this.dragStartPosition) {
                this.currentDragPosition = currentPosition;
                this.updateDragLine();
            }
        }
        this.viewportManager.lastTouchY = touch.clientY;
        this.viewportManager.isTouching = true;
    }

    handleTouchMove(event) {
        event.preventDefault();
        const touch = event.touches[0];
        const currentPosition = {
            x: touch.clientX,
            y: touch.clientY
        };
        if (this.isClickDragMode && this.dragStartPosition) {
            this.currentDragPosition = currentPosition;
            this.updateDragLine();
        } else {
            const deltaY = this.viewportManager.lastTouchY - currentPosition.y;
            this.viewportManager.updateScrollPosition(deltaY);
            this.viewportManager.lastTouchY = currentPosition.y;
        }
    }

    handleResize() {
        this.updateDragZoneDimensions();
        if (this.isClickDragMode) {
            this.renderManager.updateDragZone(this.dragZoneWidth, this.dragZoneHeight, this.dragZoneLeft, this.dragZoneTop);
        }
    }

    updateDragZoneDimensions() {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Adjust these values to change the size and position of the drag zone
        this.horizontalMargin = 0.08; // 5% margin on left and right
        this.verticalMargin = 0.05; // 5% margin on bottom
        this.dragZoneHeightPercentage = 0.4; // 30% of viewport height

        this.dragZoneWidth = viewportWidth * (1 - 2 * this.horizontalMargin);
        this.dragZoneHeight = viewportHeight * this.dragZoneHeightPercentage;
        this.dragZoneLeft = viewportWidth * this.horizontalMargin;
        this.dragZoneTop = viewportHeight - (this.dragZoneHeight + viewportHeight * this.verticalMargin);

    }

    setupClickDragMode() {
        this.isClickDragMode = true;
        this.dragStartPosition = null;
        this.currentDragPosition = null;
        this.puttingLine = this.renderManager.createPuttingLine();
        this.updateDragZoneDimensions();
        this.renderManager.createDragZone(this.dragZoneWidth, this.dragZoneHeight, this.dragZoneLeft, this.dragZoneTop);
        this.renderManager.clearCursorGraphics();
    }

    isInsideDragZone(position) {
        return position.x >= this.dragZoneLeft &&
            position.x <= this.dragZoneLeft + this.dragZoneWidth &&
            position.y >= this.dragZoneTop &&
            position.y <= this.dragZoneTop + this.dragZoneHeight;
    }

    getMousePosition(event) {
        const rect = this.app.view.getBoundingClientRect();
        const screenX = event.clientX - rect.left;
        const screenY = event.clientY - rect.top;

        let worldX, worldY;

        if (window.innerWidth <= PLAYABLE_AREA) {
            const scale = window.innerWidth / PLAYABLE_AREA;
            worldX = (screenX / scale) + WALL_THICKNESS * 2;
            worldY = (screenY / scale) + this.viewportManager.scrollPosition;
        } else {
            worldX = this.viewportManager.getWorldXFromScreenX(screenX) + WALL_THICKNESS;
            worldY = this.viewportManager.getWorldYFromScreenY(screenY);
        }

        return {
            screen: { x: screenX, y: screenY },
            world: { x: worldX, y: worldY }
        };
    }

    updateMousePositionOnScroll() {
        if (this.lastMouseEvent) {
            this.mousePosition = this.getMousePosition(this.lastMouseEvent);
            this.updateAimingGraphics(this.mousePosition);
        }
    }

    updateMousePositionRelativeToViewport() {
        if (this.lastMouseEvent) {
            this.mousePosition = this.getMousePosition(this.lastMouseEvent);
            this.updateAimingGraphics(this.mousePosition);
        }
    }

    updateAimingGraphics(position) {
        if (!position || !position.world || !position.screen) return;
        const ballPosition = this.ballManager.getPosition();
        if (!ballPosition) return;

        const forceOffset = localStorage.getItem('forceOffset') || defaultSettings.player.forceOffset;
        const force = calculateForce(position.world.x - WALL_THICKNESS, position.world.y, ballPosition.x, ballPosition.y, false, forceOffset);
        const rangeDiff = 0.04 - 0.002;
        const normalisedForce = Math.floor((force - 0.002) / rangeDiff * 9) + 1;

        const screenBallPosition = {
            x: this.viewportManager.getScreenXFromWorldX(ballPosition.x),
            y: this.viewportManager.getScreenYFromWorldY(ballPosition.y)
        };

        const shouldShowCursor = this.isMouseInput ||
            (this.isClickDragMode && this.dragStartPosition) ||
            (!this.isClickDragMode && !this.isMouseInput);

        if (shouldShowCursor) {
            this.renderManager.updateCursorTriangle(
                position.screen,
                this.isClickDragMode ? { x: window.innerWidth / 2, y: window.innerHeight * 0.75 } : screenBallPosition,
                force,
                this.isClickDragMode
            );
            this.renderManager.updateForceText(this.forceText, normalisedForce, position.screen.x, position.screen.y);
        } else {
            this.renderManager.clearCursorGraphics();
        }

        if (!this.isClickDragMode) {
            this.renderManager.updateThroughLine(position.world, ballPosition, normalisedForce);
        }
    }
    handleMouseMove(event) {
        this.isMouseInput = true;
        this.lastMouseEvent = event;
        this.mousePosition = this.getMousePosition(event);
        this.updateAimingGraphics(this.mousePosition);
        if (this.isClickDragMode && this.dragStartPosition) {
            this.currentDragPosition = this.mousePosition.screen;
            this.updateDragLine();
        }
    }
    clearAimingGraphics() {
        this.forceText.visible = false;
        this.renderManager.clearAimingGraphics();
    }


    updateMousePositionWithJoystick(deltaX, deltaY) {
        const newScreenX = this.mousePosition.screen.x + deltaX;
        const newScreenY = this.mousePosition.screen.y + deltaY;
        const newPosition = this.getMousePosition({ clientX: newScreenX, clientY: newScreenY });
        this.mousePosition = newPosition;
        this.updateAimingGraphics(this.mousePosition);
    }

    peekPlayerList(show) {
        this.playerList.toggleVisibility(show);
    }

    toggleChatInputFocus() {
        this.chat.toggleInputFocus();
    }

    reset() {
        this.clearAimingGraphics();
    }

    update(delta) {
        // we should also call this.renderManager.updateThroughLine, so its always pointing to the ball
        if (!this.mousePosition.world || !this.ballManager.getPosition()) return
        if (this.settings.player.controlType !== "click-drag") {
            this.renderManager.updateThroughLine(this.mousePosition.world, this.ballManager.getPosition(), 10);
        }
    }

    resetDragState() {
        this.dragStartPosition = null;
        this.currentDragPosition = null;
        this.renderManager.clearPuttingLine();
        if (!this.isMouseInput) {
            this.renderManager.clearCursorGraphics();
        }
    }
}