import { PLAYABLE_AREA, CANVAS_HEIGHT, FLOOR_HEIGHT, CEILING_HEIGHT } from '../constants';
import { platformDistributor } from './platformDistribution';
import { RoomSettings} from '../../shared/sharedTypes';

interface RectangleParams {
    centerX: number;
    y: number;
    width: number;
    height: number;
    isGoal?: boolean;
    goalType?: string;
}

export function createRectangularPlatforms(settings: { roomSettings: RoomSettings }, platformData: any[]): void {
    const { roomSettings } = settings;
    const numberOfRectangles = roomSettings.platforms.Rectangle.density;
    const temperature = 0.4; // Adjust this value as needed
    const minDistance = 10; // Adjust this value based on typical rectangle height

    const rectangleHeights = platformDistributor.distributePlatforms(numberOfRectangles, temperature, minDistance);

    rectangleHeights.forEach(y => {
        const rectParams = createRectangularPlatformParams(y, roomSettings.platforms.Rectangle.snap);
        platformData.push({
            type: "rectangularMesh",
            params: rectParams,
        });
    });
}

function createRectangularPlatformParams(y: number, snap_to_edge: boolean = false): RectangleParams {
    let centerX = PLAYABLE_AREA * Math.random();
    let width = 30 + (PLAYABLE_AREA * 0.45) * Math.random();
    let height = 25 + Math.random() * 60;

    if (snap_to_edge) {
        centerX = centerX < PLAYABLE_AREA / 2 ? width / 2 : PLAYABLE_AREA - width / 2;
    }

    // Guarantee that the platform is within the bounds of the playable area
    if (centerX + width / 2 > PLAYABLE_AREA) {
        centerX = PLAYABLE_AREA - width / 2;
    }
    if (centerX - width / 2 < 0) {
        centerX = width / 2;
    }

    return {
        centerX,
        y,
        width,
        height
    };
}

export function generateRectangularMeshFromParams(settings: any, params: RectangleParams): {
    meshType: string;
    vertices: [number, number][];
} {
    const meshData = generateRectangularMesh(params.width, params.height);
    return {
        ...meshData,
        vertices: meshData.vertices.map(v => [v[0] + params.centerX, v[1] + params.y])
    };
}

function generateRectangularMesh(width: number, height: number): {
    meshType: string;
    vertices: [number, number][];
} {
    return {
        meshType: "rect",
        vertices: [
            [-width / 2, -height / 2], // top left
            [width / 2, -height / 2],  // top right
            [-width / 2, height / 2],  // bottom left
            [width / 2, height / 2],   // bottom right
        ],
    };
}