import { PLAYABLE_AREA } from './constants.js';
import playerIcon from './images/icons/players.png';
import levelsIcon from './images/icons/levels.png';

export default class WindowsManager {
    constructor() {
        this.windows = [
            { id: 'player-list', icon: playerIcon, left: '15px' },
            { id: 'levels-list', icon: levelsIcon, left: '75px' }
        ];

        this.activeWindowId = localStorage.getItem('golfup-activeWindowId') || null;
        this.buttons = [];
        this.createButtons();
        this.initializeWindows();
        this.handleViewportChange();
        window.addEventListener('resize', this.handleViewportChange.bind(this));

        // if no initial open window state exists in localStorage, open the first window
        if (!this.activeWindowId) {
            this.openWindow(this.windows[0].id);
        }
    }

    createButtons() {
        this.windows.forEach(window => {
            const button = document.createElement('button');
            button.innerHTML = `<img class="w-9 h-9" src="${window.icon}"/>`;
            button.style.cssText = `
                position: fixed;
                left: ${window.left};
                top: 15px;
                padding: 10px;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                background-color: transparent;
                transition: background-color 0.3s ease;
            `;

            button.addEventListener('mouseover', () => {
                if (this.activeWindowId !== window.id) {
                    button.style.backgroundColor = '#CF0';
                }
            });

            button.addEventListener('mouseout', () => {
                if (this.activeWindowId !== window.id) {
                    button.style.backgroundColor = 'transparent';
                }
            });

            button.addEventListener('click', () => this.toggleWindow(window.id));
            button.addEventListener('dblclick', () => this.closeAllWindows());

            document.body.appendChild(button);
            this.buttons.push(button);
        });
    }

    initializeWindows() {
        this.windows.forEach(window => {
            const windowElement = document.getElementById(window.id);
            if (windowElement) {
                if (window.id === this.activeWindowId) {
                    windowElement.style.display = 'block';
                } else {
                    windowElement.style.display = 'none';
                }
            }
        });
        this.updateButtonStyles();
    }

    toggleWindow(windowId) {
        if (this.activeWindowId === windowId) {
            this.closeWindow(windowId);
        } else {
            this.closeAllWindows();
            this.openWindow(windowId);
        }
    }

    openWindow(windowId) {
        const windowElement = document.getElementById(windowId);
        if (windowElement) {
            windowElement.style.display = 'block';
            this.activeWindowId = windowId;
            this.updateButtonStyles();
            this.saveActiveWindowId();
        }
    }

    closeWindow(windowId) {
        const windowElement = document.getElementById(windowId);
        if (windowElement) {
            windowElement.style.display = 'none';
            this.activeWindowId = null;
            this.updateButtonStyles();
            this.saveActiveWindowId();
        }
    }

    closeAllWindows() {
        this.windows.forEach(window => {
            const windowElement = document.getElementById(window.id);
            if (windowElement) {
                windowElement.style.display = 'none';
            }
        });
        this.activeWindowId = null;
        this.updateButtonStyles();
        this.saveActiveWindowId();
    }

    closeAllWindowsWithoutChangingActiveWindow() {
        // this is for when the viewport changes to mobile and then back to desktop,
        // we don't want to wipe the player's preference of which window was open
        this.windows.forEach(window => {
            const windowElement = document.getElementById(window.id);
            if (windowElement) {
                windowElement.style.display = 'none';
            }
        });
    }

    updateButtonStyles() {
        this.buttons.forEach((button, index) => {
            if (this.windows[index].id === this.activeWindowId) {
                button.style.backgroundColor = '#FFF3';
            } else {
                button.style.backgroundColor = 'transparent';
            }
        });
    }

    saveActiveWindowId() {
        if (this.activeWindowId) {
            localStorage.setItem('golfup-activeWindowId', this.activeWindowId);
        } else {
            localStorage.removeItem('golfup-activeWindowId');
        }
    }

    handleViewportChange() {
        const viewportIsNarrowerThanPlayableAreaWithoutScaling = window.innerWidth < PLAYABLE_AREA;

        // Handle window visibility
        if (viewportIsNarrowerThanPlayableAreaWithoutScaling) {
            // On mobile, close all windows
            this.closeAllWindowsWithoutChangingActiveWindow();
        } else {
            // On desktop, restore the previous state
            this.initializeWindows();
        }

        // Update button styles
        this.updateButtonStyles();
    }
}