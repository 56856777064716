import { Assets, AnimatedSprite, Texture, Spritesheet, Container, Graphics, Sprite } from 'pixi.js';
import coin_spritesheet_json from './coin_spritesheet.json';
import { WALL_THICKNESS, CANVAS_WIDTH, PLAYABLE_AREA, CANVAS_HEIGHT, FLOOR_HEIGHT, CEILING_HEIGHT, SPECIAL_PLATFORM_IDS } from './constants.js';
import { PlatformWithShadow, PlatformWithRainbow } from './PlatformEffects.js';
import { generateRectangularMeshFromParams } from './platforms/rectangle.js';
import { generateSplineMeshFromParams } from './platforms/spline.js';
import { decodeBinaryCluster } from './platforms/binaryCluster.js';
import { generateArcMeshFromParams } from './platforms/arc.js';
import coin_spritesheet from './spritesheets/pickup/coin_spritesheet_reduced.png';
import { createPlatformParams } from './platforms/platformParams.js';
import GameStateManager from './gameStateManager.js';

const SHADOWS = true;
const ANIMATE_SHADOWS = true;

function seededRandom(seed: string) {
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
        const char = seed.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32-bit integer
    }
    return function () {
        const x = Math.sin(hash++) * 10000;
        return x - Math.floor(x);
    };
}

export default class PlatformManager {
    private matterWorker: Worker;
    private gameWorld: Container;
    private socketClient: any;
    private viewportManager: any;
    private inputManager: any;
    private platforms: any[] = [];
    private platformData: any[] = [];
    private coins: any[] = [];
    private coinTextures: Texture[] = [];
    private roomState: any;
    private platformContainer: Container;
    private shadowContainer: Container;
    private app: any;
    private entityIdCounter: number = 1;
    private debugGraphics: Graphics;
    private ballManager: any;
    private coinSpritesheet: Spritesheet | null = null;
    private assetsLoaded: boolean = false;
    private gameStateManager: GameStateManager;
    ceilingSprite: any;
    boundaryGraphics: any;
    lastPlatformUpdate: number;
    private finishLineVideoTexture: Texture | null = null;


    constructor(gameStateManager: GameStateManager, matterWorker: Worker, gameWorld: Container, socketClient: any, viewportManager: any, app: any, inputManager: any) {
        const bootstrapRoomState = gameStateManager.roomState;
        this.gameStateManager = gameStateManager;
        this.matterWorker = matterWorker;
        this.gameWorld = gameWorld;
        this.socketClient = socketClient;
        this.viewportManager = viewportManager;
        this.inputManager = inputManager;
        this.app = app;
        this.lastPlatformUpdate = Date.now();
        this.platformContainer = new Container();
        this.gameWorld.addChild(this.platformContainer);
        this.shadowContainer = new Container();
        this.gameWorld.addChild(this.shadowContainer);

        this.debugGraphics = new Graphics();
        this.platformContainer.addChild(this.debugGraphics);

        this.setupCoinSpritesheet();
        this.setupSocketListeners();

        this.loadAssets().then(() => {
            this.assetsLoaded = true;
            this.onPlatformsReceived(bootstrapRoomState);
        });

        this.matterWorker.onmessage = this.handleWorkerMessage.bind(this);
        this.createWorldBounds();

        this.initializeFinishLineVideo();
    }

    private initializeFinishLineVideo(): void {
        const videoElement = document.getElementById('button-video') as HTMLVideoElement;
        if (videoElement) {
            this.finishLineVideoTexture = Texture.from(videoElement);
            videoElement.loop = true;
            videoElement.muted = true;
            videoElement.play().catch(error => console.error("Video autoplay failed:", error));
        } else {
            console.error('Background video element not found');
        }
    }

    private async loadAssets(): Promise<void> {
        try {
            // Load coin spritesheet
            const coinTexture = await Assets.load(coin_spritesheet);
            this.coinSpritesheet = new Spritesheet(coinTexture, coin_spritesheet_json);
            await this.coinSpritesheet.parse();

            // Remove setupFinishLineVideo call from here
        } catch (error) {
            console.error("Error loading assets:", error);
        }
    }


    private handleWorkerMessage(event: MessageEvent): void {
        switch (event.data.type) {
            case 'worldBoundsCreated':
                this.drawWorldBounds(event.data.bounds);
                break;
            case 'platformCreated':
                break;
            case 'coinCreated':
                break;
            case 'platformAtPosition':
                break;
        }
    }

    private drawWorldBounds(bounds: any[]): void {
        const debugGraphics = new Graphics();
        debugGraphics.setStrokeStyle({ width: 2, color: 0xFF0000 });  // Red color for visibility

        bounds.forEach(bound => {
            const vertices = bound.vertices;
            debugGraphics.moveTo(vertices[0].x, vertices[0].y);
            for (let i = 1; i < vertices.length; i++) {
                debugGraphics.lineTo(vertices[i].x, vertices[i].y);
            }
            debugGraphics.lineTo(vertices[0].x, vertices[0].y);
        });

        this.gameWorld.addChild(debugGraphics);
    }

    private setupSocketListeners(): void {
        this.socketClient.socket.on('platformsReceived', (newRoomState: any) => this.onPlatformsReceived(newRoomState));
        this.socketClient.socket.on('platformsUpdated', (newRoomState: any) => this.onPlatformsReceived(newRoomState));
        this.socketClient.socket.on('removeCoin', (index: number, local: boolean) => this.removeCoin(index, local));
        this.socketClient.socket.on('regenAndSharePlatforms', () => this.regenAndSharePlatforms());
    }

    private async createWorldBounds(): Promise<void> {
        this.matterWorker.postMessage({
            type: 'createWorldBounds',
            canvasWidth: CANVAS_WIDTH,
            canvasHeight: CANVAS_HEIGHT,
            floorHeight: FLOOR_HEIGHT,
            wallThickness: WALL_THICKNESS,
            playableArea: PLAYABLE_AREA
        });

        // Create visual representations of the bounds
        const boundaryGraphics = new Graphics();
        boundaryGraphics.moveTo(WALL_THICKNESS, 50);
        boundaryGraphics.lineTo(WALL_THICKNESS + PLAYABLE_AREA, 50);
        boundaryGraphics.moveTo(WALL_THICKNESS, 50);
        boundaryGraphics.lineTo(WALL_THICKNESS, CANVAS_HEIGHT - FLOOR_HEIGHT);
        boundaryGraphics.moveTo(CANVAS_WIDTH - WALL_THICKNESS, 50);
        boundaryGraphics.lineTo(CANVAS_WIDTH - WALL_THICKNESS, CANVAS_HEIGHT - FLOOR_HEIGHT);
        // draw a floor
        boundaryGraphics.moveTo(WALL_THICKNESS, CANVAS_HEIGHT - FLOOR_HEIGHT);
        boundaryGraphics.lineTo(CANVAS_WIDTH - WALL_THICKNESS, CANVAS_HEIGHT - FLOOR_HEIGHT);
        boundaryGraphics.stroke({ width: 1, color: 0xFFFFFF });
        this.gameWorld.addChild(boundaryGraphics);
    }

    generateQuads(vertices) {
        const quads = [];
        for (let i = 0; i < vertices.length - 2; i += 2) {
            quads.push([i, i + 1, i + 3, i + 2]);
        }
        return quads;
    }

    async createPlatformFromMesh(meshData, i: number, isUpdate = false) {
        try {
            if (meshData.type === "binaryCluster" || meshData.type === "decodedBinaryCluster") {
                return this.createOrUpdateBinaryCluster(meshData, i, isUpdate);
            }

            const entityId = isUpdate ? this.platforms[i].entityId : ++this.entityIdCounter;

            if (!meshData.quads) {
                meshData.quads = this.generateQuads(meshData.vertices);
            }

            const forest_colours = [0x00FF00, 0xCCFF00, 0x61D095, 0xFFEA70, 0x008080, 0x00FF7F];
            const mountain_colours = [0x8B4513, 0x8B7355, 0x8B795E, 0x8B7D6B, 0x8B7D7B, 0x8B7D7D];
            const autumn_colours = [0xFFD1DC, 0xFFA07A, 0xFFB6C1, 0xFFE4E1, 0xFFDAB9, 0xFFE4B5, 0x00FF00, 0x00FF7F, 0x00FFD8, 0x00FFFF, 0x00D8FF, 0x00BFFF, 0xFF0000, 0xFF4500, 0xFF6347, 0xFF6A6A, 0xFF7256, 0xFF7F50, 0xFF8247, 0xFF8C00, 0xFFA07A, 0xFFA500, 0xFFA54F, 0xFFAEB9, 0xFFB6C1, 0xFFC0CB, 0xFFD700, 0xFFDAB9, 0xFFDEAD, 0xFFE4B5, 0xFFE4C4, 0xFFE4E1, 0xFFEBCD, 0xFFEC8B, 0xFFEFD5]
            const light_colors = [
                0xFFF0F0, // Slightly more vibrant Snow
                0xFFE6DF, // Slightly more vibrant Seashell
                0xFFE1E6, // Slightly more vibrant Lavender Blush
                0xFFD5D2, // Slightly more vibrant Misty Rose
                0xFFE0C6, // Slightly more vibrant Papaya Whip
                0xFFEBE1, // Slightly more vibrant Floral White
                0xFFE6D7, // Slightly more vibrant Old Lace
                0xEBE1D7, // Slightly more vibrant Linen
                0xFFE1E1, // Slightly more vibrant Light Pink
                0xFFD5D2, // Slightly more vibrant Misty Rose
                0xFFDCBE, // Slightly more vibrant Blanched Almond
                0xFFEBBE, // Slightly more vibrant Lemon Chiffon
                0xFFF0E1, // Slightly more vibrant Ivory
                0xE1FFE1, // Slightly more vibrant Honeydew
                0xE1FFFF, // Slightly more vibrant Azure
                0xE1E9FF, // Slightly more vibrant Alice Blue
                0xE9E9FF, // Slightly more vibrant Ghost White
                0xE6E6E6, // Slightly more vibrant White Smoke
                0xFFE6DF, // Slightly more vibrant Seashell
                0xE1D77D, // Slightly more vibrant Khaki (light)
                0xD7D7EB, // Slightly more vibrant Lavender
                0xFFE1E6, // Slightly more vibrant Lavender Blush
                0xFFD5A6, // Slightly more vibrant Moccasin
                0xFFCBAA, // Slightly more vibrant Peach Puff
                0xFFD5D2, // Slightly more vibrant Misty Rose
                0xFFF0F0, // Slightly more vibrant Snow
                0xE1FFE1, // Slightly more vibrant Honeydew
                0xE6FFEB, // Slightly more vibrant Mint Cream
                0xE1FFFF, // Slightly more vibrant Azure
                0xE1E9FF, // Slightly more vibrant Alice Blue
                0xE9E9FF, // Slightly more vibrant Ghost White
                0xFFEBE1, // Slightly more vibrant Floral White
                0xFFF0E1, // Slightly more vibrant Ivory
                0xFFEBBE, // Slightly more vibrant Lemon Chiffon
                0xFFEBE1  // Slightly more vibrant Floral White
            ];
            const vibrant_colors = [
                0xFF0000, // Red
                0xFF4500, // Orange Red
                0xFF6347, // Tomato
                0xFF7F50, // Coral
                0xFF8C00, // Dark Orange
                0xFFA500, // Orange
                0xFFD700, // Gold
                0xFFFF00, // Yellow
                0xFFFF54, // Laser Lemon
                0xADFF2F, // Green Yellow
                0x7FFF00, // Chartreuse
                0x00FF00, // Lime
                0x32CD32, // Lime Green
                0x00FA9A, // Medium Spring Green
                0x00FF7F, // Spring Green
                0x00FFFF, // Cyan
                0x00BFFF, // Deep Sky Blue
                0x1E90FF, // Dodger Blue
                0x0000FF, // Blue
                0x4169E1, // Royal Blue
                0x8A2BE2, // Blue Violet
                0x9400D3, // Dark Violet
                0xFF00FF, // Magenta
                0xFF1493, // Deep Pink
                0xFF69B4, // Hot Pink
                0xFF6347, // Tomato
                0xDC143C, // Crimson
                0xFFD700, // Gold
                0xFFA07A, // Light Salmon
                0x20B2AA, // Light Sea Green
                0x00CED1, // Dark Turquoise
                0x6A5ACD, // Slate Blue
                0xDA70D6, // Orchid
                0xFA8072, // Salmon
                0xFF6347  // Tomato
            ];
            const random = seededRandom(this.roomState.roomSettings.levelId);

            // Shuffle the light_colors array deterministically
            for (let j = light_colors.length - 1; j > 0; j--) {
                const k = Math.floor(random() * (j + 1));
                [light_colors[j], light_colors[k]] = [light_colors[k], light_colors[j]];
            }

            // shuffle the vibrant_colors array deterministically
            for (let j = vibrant_colors.length - 1; j > 0; j--) {
                const k = Math.floor(random() * (j + 1));
                [vibrant_colors[j], vibrant_colors[k]] = [vibrant_colors[k], vibrant_colors[j]];
            }

            const randomFill = i % light_colors.length;
            const currentColor = light_colors[randomFill];

            const randomStroke = (i + 1) % vibrant_colors.length;
            const nextColor = vibrant_colors[randomStroke];

            // Apply WALL_THICKNESS offset to vertices
            const offsetVertices = meshData.vertices.map(vertex => [vertex[0] + WALL_THICKNESS, vertex[1]]);

            // Create platform in physics world
            await new Promise((resolve, reject) => {
                const messageId = Date.now();
                this.matterWorker.postMessage({
                    type: 'createPlatform',
                    meshData: {
                        ...meshData,
                        vertices: offsetVertices,
                        entityId,
                        isGoal: meshData.isGoal,
                        goalType: meshData.goalType,
                        WALL_THICKNESS,
                        SPECIAL_PLATFORM_IDS
                    },
                    messageId: messageId
                });

                const handler = (event) => {
                    if (event.data.type === 'platformCreated' && event.data.messageId === messageId) {
                        this.matterWorker.removeEventListener('message', handler);
                        resolve(event.data.platform);
                    }
                };

                this.matterWorker.addEventListener('message', handler);
            });

            const platformGraphics = new Graphics();
            let platformWithEffect: Container;

            // Draw the platform
            if (meshData.meshType === "spline" && offsetVertices.length > 3 && meshData.thickness) {
                const startPoint = [
                    (offsetVertices[0][0] + offsetVertices[1][0]) / 2,
                    (offsetVertices[0][1] + offsetVertices[1][1]) / 2
                ];
                const endPoint = [
                    (offsetVertices[offsetVertices.length - 2][0] + offsetVertices[offsetVertices.length - 1][0]) / 2,
                    (offsetVertices[offsetVertices.length - 2][1] + offsetVertices[offsetVertices.length - 1][1]) / 2
                ];
                const radius = meshData.thickness / 2;
                platformGraphics.circle(startPoint[0], startPoint[1], radius);
                platformGraphics.circle(endPoint[0], endPoint[1], radius);
                platformGraphics.fill(currentColor);
            }

            meshData.quads.forEach((quad, i) => {
                if (meshData.isGoal) {
                    const quadGraphics = new Graphics();
                    quadGraphics.moveTo(offsetVertices[quad[0]][0], offsetVertices[quad[0]][1]);
                    for (let j = 1; j < quad.length; j++) {
                        quadGraphics.lineTo(offsetVertices[quad[j]][0], offsetVertices[quad[j]][1]);
                    }
                    quadGraphics.closePath();
                    quadGraphics.fill({ color: 0xFF0000 });

                    const bounds = quadGraphics.getBounds();
                    let videoSprite;
                    if (this.finishLineVideoTexture) {
                        videoSprite = new Sprite(this.finishLineVideoTexture);
                        videoSprite.x = bounds.x;
                        videoSprite.y = bounds.y;
                        videoSprite.width = bounds.width;
                        videoSprite.height = bounds.height * 3;
                        videoSprite.mask = quadGraphics;
                        platformGraphics.addChild(videoSprite);
                    } else {
                        console.warn('Video texture not available for goal platform');
                    }

                    platformGraphics.addChild(quadGraphics);
                } else {
                    platformGraphics.moveTo(offsetVertices[quad[0]][0], offsetVertices[quad[0]][1]);
                    for (let i = 1; i < quad.length; i++) {
                        platformGraphics.lineTo(offsetVertices[quad[i]][0], offsetVertices[quad[i]][1]);
                    }
                    platformGraphics.closePath();
                    platformGraphics.fill({ color: currentColor });
                    platformGraphics.stroke({ width: 5, color: nextColor, alpha: 0.5 });
                }
            });

            if (meshData.isGoal) {
                const glowGraphics = new Graphics();
                meshData.quads.forEach((quad) => {
                    glowGraphics.moveTo(offsetVertices[quad[0]][0], offsetVertices[quad[0]][1]);
                    for (let j = 1; j < quad.length; j++) {
                        glowGraphics.lineTo(offsetVertices[quad[j]][0], offsetVertices[quad[j]][1]);
                    }
                    glowGraphics.closePath();
                });
                glowGraphics.fill({ color: 0xFFFFFF, alpha: 0.5 });
                glowGraphics.scale.set(1.1);

                platformWithEffect = new Container();
                const glowBounds = glowGraphics.getBounds();
                const platformBounds = platformGraphics.getBounds();
                const glowCenter = {
                    x: glowBounds.x + glowBounds.width / 2,
                    y: glowBounds.y + glowBounds.height / 2
                };
                const platformCenter = {
                    x: platformBounds.x + platformBounds.width / 2,
                    y: platformBounds.y + platformBounds.height / 2
                };
                const offsetX = platformCenter.x - glowCenter.x;
                const offsetY = platformCenter.y - glowCenter.y;
                glowGraphics.position.set(offsetX, offsetY);

                if (meshData.goalType === 'bucket') {
                    const bucketCenter = this.computeBoundingBox(offsetVertices).center;
                    const backgroundRadius = 100;
                    const goalBackground = new Graphics();
                    goalBackground.circle(bucketCenter[0], bucketCenter[1], backgroundRadius);
                    goalBackground.fill({ color: 0xFF0000 });
                    goalBackground.alpha = 0.5;

                    this.matterWorker.postMessage({
                        type: 'createBucketSensor',
                        center: bucketCenter,
                        radius: backgroundRadius
                    });

                    platformWithEffect.addChild(goalBackground);
                }

                platformWithEffect.addChild(glowGraphics);
                platformWithEffect.addChild(platformGraphics);
            } else {
                const shadowGraphics = new Graphics();
                if (SHADOWS) {
                    meshData.quads.forEach(quad => {
                        shadowGraphics.moveTo(offsetVertices[quad[0]][0], offsetVertices[quad[0]][1]);
                        for (let j = 1; j < quad.length; j++) {
                            shadowGraphics.lineTo(offsetVertices[quad[j]][0], offsetVertices[quad[j]][1]);
                        }
                    });
                    if (meshData.meshType === "spline" && offsetVertices.length > 3 && meshData.thickness) {
                        const startPoint = [
                            (offsetVertices[0][0] + offsetVertices[1][0]) / 2,
                            (offsetVertices[0][1] + offsetVertices[1][1]) / 2
                        ];
                        const endPoint = [
                            (offsetVertices[offsetVertices.length - 2][0] + offsetVertices[offsetVertices.length - 1][0]) / 2,
                            (offsetVertices[offsetVertices.length - 2][1] + offsetVertices[offsetVertices.length - 1][1]) / 2
                        ];
                        const radius = meshData.thickness / 2;

                        shadowGraphics.circle(startPoint[0], startPoint[1], radius);
                        shadowGraphics.circle(endPoint[0], endPoint[1], radius);
                    }
                    shadowGraphics.fill({ color: 0x000000, alpha: 0.1 });
                }
                platformWithEffect = new PlatformWithShadow(platformGraphics, shadowGraphics);
            }

            platformWithEffect.entityId = entityId;
            this.platformContainer.addChild(platformWithEffect);

            if (meshData.goalType === 'bucket') {
                const bucketCenter = this.computeBoundingBox(offsetVertices).center;
                const bucketWidth = 100;
                const bucketHeight = 30;

                this.matterWorker.postMessage({
                    type: 'createBucketSensor',
                    center: bucketCenter,
                    width: bucketWidth,
                    height: bucketHeight
                });
            }

            this.platforms.push({ ...meshData, entityId, container: platformWithEffect, i });

        } catch (error) {
            console.error('Error creating/updating platform:', error);
        }
    }

    private async createOrUpdateBinaryCluster(meshData, i: number, isUpdate = false) {

        const id = isUpdate ? this.platforms[i].id : ++this.entityIdCounter;


        // Ensure meshData has all necessary properties
        if (!meshData.cellSize || !meshData.columns || !meshData.rows || !meshData.x || !meshData.y) {
            console.error('Invalid meshData for binary cluster:', meshData);
            return;
        }

        const decodedCluster = decodeBinaryCluster(
            this.roomState.roomSettings.platforms.BinaryCluster,
            meshData,
            Date.now()
        );


        // Create a Set of active cell keys for faster lookup
        const activeCellSet = new Set(decodedCluster.activeCells.map(cell => `${cell.row}_${cell.column}`));
        let binaryClusterContainer: Container;
        let container = this.platforms[i]?.container;

        if (!isUpdate) {
            // Create new binary cluster
            binaryClusterContainer = new Container();
            container = binaryClusterContainer;
            binaryClusterContainer.id = id;
            binaryClusterContainer.position.set(decodedCluster.x, decodedCluster.y);
            this.platformContainer.addChild(binaryClusterContainer);

            // Create grid lines
            const gridGraphics = new Graphics();
            gridGraphics.setStrokeStyle({ color: 0xCCFF00, alpha: 0.5 }); // Light grey, semi-transparent lines

            // Draw vertical lines
            for (let col = 0; col <= decodedCluster.columns; col++) {
                gridGraphics.moveTo(col * decodedCluster.cellSize, 0);
                gridGraphics.lineTo(col * decodedCluster.cellSize, decodedCluster.rows * decodedCluster.cellSize);
            }

            // Draw horizontal lines
            for (let row = 0; row <= decodedCluster.rows; row++) {
                gridGraphics.moveTo(0, row * decodedCluster.cellSize);
                gridGraphics.lineTo(decodedCluster.columns * decodedCluster.cellSize, row * decodedCluster.cellSize);
            }
            gridGraphics.stroke();

            binaryClusterContainer.addChild(gridGraphics);

            // Create all cells
            for (let row = 0; row < decodedCluster.rows; row++) {
                for (let col = 0; col < decodedCluster.columns; col++) {
                    const cellGraphics = new Graphics();
                    cellGraphics.rect(0, 0, decodedCluster.cellSize, decodedCluster.cellSize);
                    cellGraphics.fill(0xFFFFFF);
                    cellGraphics.alpha = activeCellSet.has(`${row}_${col}`) ? 0.8 : 0.4; // Inactive cells are less opaque

                    cellGraphics.position.set(
                        col * decodedCluster.cellSize,
                        row * decodedCluster.cellSize
                    );
                    cellGraphics.row = row;
                    cellGraphics.column = col;
                    binaryClusterContainer.addChild(cellGraphics);
                }
            }

            this.platforms[i] = { ...meshData, id, container: binaryClusterContainer, i };

            this.matterWorker.postMessage({
                type: 'createBinaryCluster',
                meshData: {
                    id,
                    cellSize: decodedCluster.cellSize,
                    x: decodedCluster.x,
                    y: decodedCluster.y,
                    rows: decodedCluster.rows,
                    columns: decodedCluster.columns,
                    activeCells: decodedCluster.activeCells
                },
                messageId: Date.now()
            });
        }

        // Update cell visibility (for both new and existing clusters)
        if (container) {
            container.children.forEach((child) => {
                if (child instanceof Graphics && child.row !== undefined && child.column !== undefined) {
                    const isActive = activeCellSet.has(`${child.row}_${child.column}`);
                    child.alpha = isActive ? 0.8 : 0.4;
                }
            });
        }

        if (isUpdate) {
            this.matterWorker.postMessage({
                type: 'updateBinaryCluster',
                meshData: {
                    id,
                    cellSize: decodedCluster.cellSize,
                    rows: decodedCluster.rows,
                    columns: decodedCluster.columns,
                    activeCells: decodedCluster.activeCells
                },
                messageId: Date.now()
            });
        }

        return decodedCluster;
    }

    computeBoundingBox(vertices) {
        let minX = Number.MAX_VALUE;
        let minY = Number.MAX_VALUE;
        let maxX = Number.MIN_VALUE;
        let maxY = Number.MIN_VALUE;

        vertices.forEach(vertex => {
            minX = Math.min(minX, vertex[0]);
            minY = Math.min(minY, vertex[1]);
            maxX = Math.max(maxX, vertex[0]);
            maxY = Math.max(maxY, vertex[1]);
        });

        return {
            center: [(minX + maxX) / 2, (minY + maxY) / 2],
            width: maxX - minX,
            height: maxY - minY
        };
    }

    private async setupCoinSpritesheet(): Promise<void> {
        try {
            const texture = await Assets.load(coin_spritesheet);
            this.coinSpritesheet = new Spritesheet(texture, coin_spritesheet_json);
            await this.coinSpritesheet.parse();
        } catch (error) {
            console.error("Error loading coin spritesheet:", error);
        }
    }

    private debounceTimer: number | null = null;
    private latestRoomState: any = null;

    private debouncedOnPlatformsReceived(newRoomState: any): void {
        this.latestRoomState = newRoomState;
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(() => {
            this.onPlatformsReceived(this.latestRoomState);
            this.debounceTimer = null;
        }, 50) as unknown as number;
    }

    private async onPlatformsReceived(newRoomState: any): Promise<void> {
        console.log('onPlatformsReceived >>>>>>', newRoomState);
        if (!this.assetsLoaded) {
            setTimeout(() => this.debouncedOnPlatformsReceived(newRoomState), 100);
            return;
        }
        this.signalReady()
        if (!newRoomState) return;

        if (this.roomState && JSON.stringify(this.roomState.platforms) === JSON.stringify(newRoomState.platforms)) {
            return;
        }

        this.roomState = newRoomState;
        this.updateLocalStorageRecentLevels(newRoomState);
        await this.clearExistingPlatforms();
        this.entityIdCounter = 0;

        if (newRoomState && newRoomState.platforms?.length) {
            const sortedPlatforms = newRoomState.platforms.sort((a, b) => {
                if (a.type === "binaryCluster" && b.type !== "binaryCluster") {
                    return -1;
                } else if (a.type !== "binaryCluster" && b.type === "binaryCluster") {
                    return 1;
                } else if (a.type === "splineMesh" && b.type !== "splineMesh") {
                    return -1;
                } else if (a.type !== "splineMesh" && b.type === "splineMesh") {
                    return 1;
                } else if (a.type === "rectangularMesh" && b.type !== "rectangularMesh") {
                    return -1;
                } else if (a.type !== "rectangularMesh" && b.type === "rectangularMesh") {
                    return 1;
                } else if (a.type === "arcMesh" && b.type !== "arcMesh") {
                    return -1;
                } else if (a.type !== "arcMesh" && b.type === "arcMesh") {
                    return 1;
                } else {
                    return 0;
                }
            })

            for (let i = 0; i < sortedPlatforms.length; i++) {
                const platform = sortedPlatforms[i];
                let meshData;
                try {
                    switch (platform.type) {
                        case "binaryCluster":
                            meshData = platform
                            break;
                        case "arcMesh":
                            meshData = generateArcMeshFromParams(newRoomState.roomSettings.platforms.Arc, platform.params);
                            break;
                        case "rectangularMesh":
                            meshData = generateRectangularMeshFromParams(newRoomState.roomSettings.platforms.Rectangle, platform.params);
                            break;
                        case "splineMesh":
                            meshData = generateSplineMeshFromParams(newRoomState.roomSettings.platforms.Spline, platform.params);
                            break;
                        default:
                            meshData = platform;
                    }
                    if (meshData && meshData.vertices && meshData.vertices.length > 0 || meshData.type === "binaryCluster") {
                        if (platform?.params?.isGoal) {
                            meshData.isGoal = platform?.params?.isGoal || false;
                            meshData.goalType = platform?.params?.goalType;
                        }
                        await this.createPlatformFromMesh(meshData, i);
                    } else {
                        console.error('Invalid mesh data for platform:', platform);
                    }
                } catch (error) {
                    console.error('Error processing platform:', platform, error);
                }
            }

            if (newRoomState.roomSettings.gameMode === 'coinDash') {
                this.createCoins();
            }
        } else {
            createPlatformParams(false, this.socketClient, this.platformData);
        }
    }

    private createCoins(): void {
        if (!this.coinSpritesheet) {
            console.error("Coin spritesheet not loaded");
            return;
        }

        const coins = [];
        const coinRadius = 10;
        const coinRows = 20;
        const coinCols = 8;
        const coinSpacingX = PLAYABLE_AREA / (coinCols + 1);
        const coinSpacingY = (CANVAS_HEIGHT - CEILING_HEIGHT - FLOOR_HEIGHT) / (coinRows + 1);
        const coinXOffset = WALL_THICKNESS + coinSpacingX;
        const coinYOffset = CEILING_HEIGHT + coinSpacingY;

        const textures = [];
        for (let i = 0; i < 100; i++) {
            const framekey = `coin_${i}.png`;
            const texture = this.coinSpritesheet.textures[framekey];
            const time = coin_spritesheet_json.frames[framekey].duration;
            textures.push({ texture, time });
        }

        for (let i = 0; i < coinRows; i++) {
            for (let j = 0; j < coinCols; j++) {
                const index = i * coinCols + j;
                if (this.roomState.removedCoins.includes(index)) continue;

                const animatedCoin = new AnimatedSprite(textures);
                animatedCoin.anchor.set(0.5);
                animatedCoin.x = coinXOffset + j * coinSpacingX;
                animatedCoin.y = coinYOffset + i * coinSpacingY;
                animatedCoin.scale.set(0.5);
                animatedCoin.animationSpeed = Math.random() > 0.5 ? -0.3 : 0.3;
                animatedCoin.gotoAndStop(Math.floor(Math.random() * 100));
                animatedCoin.play();
                this.platformContainer.addChild(animatedCoin);

                // Instead of creating Matter.js bodies directly, send a message to the worker
                this.matterWorker.postMessage({
                    type: 'createCoin',
                    x: animatedCoin.x,
                    y: animatedCoin.y,
                    radius: coinRadius + 15,
                    index: index
                });

                coins.push({ sprite: animatedCoin, index: index });
            }
        }
        this.coins = coins;
    }

    public removeCoin(index: number, local: boolean): void {
        const coinIndex = this.coins.findIndex(coin => coin && coin.index === index);
        if (coinIndex !== -1) {
            const coin = this.coins[coinIndex];
            this.coins.splice(coinIndex, 1);

            // Send message to worker to remove coin from physics world
            this.matterWorker.postMessage({
                type: 'removeCoin',
                index: index
            });

            if (local) {
                this.startRemoveCoinAnimation(coin);
            } else {
                this.platformContainer.removeChild(coin.sprite);
                coin.sprite.stop();
            }
        }
    }

    private startRemoveCoinAnimation(coin: any): void {
        const duration = 0.5;
        const initialScale = coin.sprite.scale.x;
        const initialPosition = { x: coin.sprite.x, y: coin.sprite.y };
        let elapsedTime = 0;

        const lerp = (start: number, end: number, t: number) => start + (end - start) * t;

        const updateAnimation = (delta: number) => {
            elapsedTime += delta / 60;
            const t = Math.min(elapsedTime / duration, 1);

            const newScale = lerp(initialScale, 0, t);
            coin.sprite.scale.set(newScale);

            const newX = lerp(initialPosition.x, this.ballManager.ball.position.x, t);
            const newY = lerp(initialPosition.y, this.ballManager.ball.position.y, t);
            coin.sprite.position.set(newX, newY);

            coin.sprite.animationSpeed = 0.75;

            if (t >= 1) {
                coin.sprite.stop();
                this.platformContainer.removeChild(coin.sprite);
                this.app.ticker.remove(updateAnimation);
            }
        };

        coin.sprite.play();
        this.app.ticker.add(updateAnimation);
    }

    public regenAndSharePlatforms(): void {
        createPlatformParams(false, this.socketClient, this.platformData);
    }

    private clearExistingPlatforms(): void {
        return new Promise((resolve) => {
            try {
                this.matterWorker.postMessage({ type: 'clearAllPlatforms' });

                this.platforms = [];
                this.platformData = [];

                if (this.coins && this.coins.length > 0) {
                    this.coins.forEach(coin => {
                        if (coin.sprite && coin.sprite.parent) {
                            coin.sprite.parent.removeChild(coin.sprite);
                        }
                    });
                    this.coins = [];
                }

                if (this.platformContainer && this.platformContainer.parent) {
                    this.platformContainer.removeChildren();

                    this.ceilingSprite && this.platformContainer.addChild(this.ceilingSprite);
                    this.boundaryGraphics && this.platformContainer.addChild(this.boundaryGraphics);
                    this.debugGraphics && this.platformContainer.addChild(this.debugGraphics);
                } else {
                    console.warn('Platform container or its parent is null');
                }
                resolve();
            } catch (error) {
                console.error('Error clearing existing platforms:', error);
                resolve();
            }
        });
    }

    public getPlatformAt(x: number, y: number): Promise<any> {
        return new Promise((resolve) => {
            const messageId = Date.now(); // Simple unique ID
            this.matterWorker.postMessage({
                type: 'getPlatformAt',
                x: x,
                y: y,
                messageId: messageId
            });

            const handler = (event: MessageEvent) => {
                if (event.data.type === 'platformAtPosition' && event.data.messageId === messageId) {
                    this.matterWorker.removeEventListener('message', handler);
                    resolve(event.data.platform);
                }
            };

            this.matterWorker.addEventListener('message', handler);
        });
    }

    private updateLocalStorageRecentLevels(roomState: any): void {
        if (!roomState.roomSettings) return;
        const recentLevels = JSON.parse(localStorage.getItem('golfup-recentLevels') || '[]');
        const existingIndex = recentLevels.findIndex((level: any) => level?.roomSettings?.levelId === roomState.roomSettings.levelId);
        if (existingIndex > -1) {
            recentLevels.splice(existingIndex, 1);
        }
        recentLevels.unshift({ campaignName: roomState.campaignName, roomSettings: roomState.roomSettings, platforms: roomState.platforms });
        if (recentLevels.length > 10) {
            recentLevels.pop();
        }
        localStorage.setItem('golfup-recentLevels', JSON.stringify(recentLevels));
    }

    public update(): void {
        if (ANIMATE_SHADOWS) {
            this.platformContainer.children.forEach(child => {
                if (child instanceof PlatformWithShadow && child.updateShadow) {
                    if (this.inputManager?.mousePosition?.world) {
                        child.updateShadow(this.inputManager.mousePosition.world.x - WALL_THICKNESS, this.inputManager.mousePosition.world.y, this.viewportManager.scrollPosition);
                    }
                } else if (child instanceof PlatformWithRainbow) {
                    child.updateRainbow();
                }
            });
        }
        if (this.gameStateManager) {
            if (this.lastPlatformUpdate + 200 < Date.now()) {
                const binaryClusters = this.platforms.filter(platform => platform.type === 'decodedBinaryCluster' || platform.type === 'binaryCluster');
                binaryClusters.forEach((cluster) => {
                    this.createOrUpdateBinaryCluster(cluster, cluster.i, true);
                });
                this.lastPlatformUpdate = Date.now();
            }
        }
    }

    public reset(): void {
        this.clearExistingPlatforms();
        this.matterWorker.postMessage({ type: 'resetPlatforms' });
        this.createWorldBounds();

        if (this.assetsLoaded) {
            this.onPlatformsReceived(this.roomState);
        } else {
            setTimeout(() => this.reset(), 100);
        }
    }
    signalReady(): void { }
}