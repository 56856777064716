import { Container, Sprite, Graphics, Text, TextStyle, Assets } from 'pixi.js';
import ConfettiEffect from './confettiEffect';
import { BALL_RADIUS, CANVAS_HEIGHT, FLOOR_HEIGHT, PLAYABLE_AREA, WALL_THICKNESS, SPECIAL_PLATFORM_IDS } from './constants';
import { calculateForce, cameraShake, checkIfMobile } from './utils';
import defaultSettings from './defaultSettings';
import golfball from './images/golfball-zoom-web-optimized.webp';
import { BALL_UPDATE_RATE, BallUpdate, GameMode, PlayerId, RoomState, Time } from '../shared/sharedTypes';
import { advancedInterpolation } from './AdvancedInterpolation.ts';
import SocketClient from './socket-client.ts';
import GameStateManager from './gameStateManager.ts';

interface BallData {
    position: { x: number; y: number };
    velocity: { x: number; y: number };
    angle: number;
    graphics: Container;
}

interface OtherBallData {
    graphics: Container;
    trailGraphics: Graphics;
    trailPoints: { x: number; y: number }[];
}

interface PositionData {
    previous: { x: number; y: number };
    target: { x: number; y: number };
    lerpTime: number;
}

export default class BallManager {
    private gameWorld: Container;
    private socketClient: SocketClient;
    private platformManager: any;
    private app: any;
    private viewportManager: any;
    private gameStateManager: GameStateManager;
    private roomState: RoomState;
    private soundManager: any;
    private chat: any;
    private matterWorker: Worker;
    private isMobile: boolean = false;

    private ball: BallData | null = null;
    private trailPoints: { x: number; y: number }[] = [];
    private trailGraphics: Graphics;
    private otherBalls: { [key: string]: OtherBallData } = {};
    private previousAndTargetPositions: { [key: string]: PositionData } = {};
    public inMotion: boolean = false;
    private hasLeftTheGroundSinceLastStrike: boolean = false;
    private colliding: boolean = false;
    private initialPosition: { x: number; y: number } | null = null;
    private highestHeight: number = 0;
    private localBallId: string;
    private confettiEffect: ConfettiEffect;
    private ringGraphics: Graphics;
    private pulsationTime: number = 0;
    private lastUpdate: number = 0;

    private previouslyHittable: boolean = false;

    constructor(
        bootstrapRoomState: RoomState,
        matterWorker: Worker,
        gameWorld: Container,
        socketClient: any,
        platformManager: any,
        app: any,
        gameStateManager: any,
        chat: any,
        viewportManager: any,
        soundManager: any
    ) {
        advancedInterpolation.setInterpolationMethod(false);
        advancedInterpolation.setSmoothingFactor(1); // Adjust as needed
        this.isMobile = checkIfMobile();
        this.matterWorker = matterWorker;
        this.gameWorld = gameWorld;
        this.socketClient = socketClient;
        this.socketClient.handleWebRTCBallUpdate = this.updateOtherPlayerBall.bind(this);
        this.setupListeners();

        this.platformManager = platformManager;
        this.app = app;
        this.viewportManager = viewportManager;
        this.gameStateManager = gameStateManager;
        this.roomState = bootstrapRoomState;
        this.soundManager = soundManager;
        this.chat = chat;

        this.trailGraphics = new Graphics();
        this.gameWorld.addChild(this.trailGraphics);

        this.localBallId = this.socketClient.socket.id;
        this.confettiEffect = new ConfettiEffect(this.app);

        this.ringGraphics = new Graphics();
        this.gameWorld.addChild(this.ringGraphics);

        this.previouslyHittable = !this.cantHit();

        this.matterWorker.onmessage = this.handleWorkerMessage.bind(this);
        this.setEventListeners();
        setTimeout(() => this.createBall(), 1000);
        // render other initial player balls 
        Object.entries(this.roomState.players).forEach(([id, playerData]: [string, any]) => {
            if (id !== this.socketClient.socket.id) {
                this.createOtherPlayerBall(id, playerData);
            }
        });
    }

    private handleWorkerMessage(event: MessageEvent): void {
        switch (event.data.type) {
            case 'ballUpdated':
                this.updateBallState(event.data);
                break;
            case 'collisionResults':
                this.handleCollisionResults(event.data.collisions);
                break;
            case 'ballTouchingPlatform':
                // Update any necessary state based on whether the ball is touching a platform
                break;
            case 'worldUpdated':
                break;
        }
    }

    private updateBallState(data: any): void {
        if (this.ball && data.ball) {
            const targetPosition = data.ball.position;
            const currentPosition = this.ball.position;

            // Smoothly interpolate to the target position
            this.ball.position.x += (targetPosition.x - currentPosition.x) * 0.3;
            this.ball.position.y += (targetPosition.y - currentPosition.y) * 0.3;

            this.ball.velocity = data.ball.velocity;
            this.ball.angle = data.ball.angle;

            this.updateBallGraphics();
            this.updateTrail();
            this.updateHighestHeight();
            this.updateBallPositionOnSocket();
            this.inMotion = !this.isBallStationary();
            if (!this.inMotion && this.hasLeftTheGroundSinceLastStrike) {
                this.hasLeftTheGroundSinceLastStrike = false;
            }
            this.gameStateManager.ball = this.ball;
        }
    }

    private setEventListeners(): void {
        this.socketClient.socket.on('roundEnded', (data: any) => {
            this.confettiEffect.start();
            this.soundManager.playVictorySound();
            setTimeout(() => {
                this.confettiEffect.stop();
            }, data.timeUntilNextRound);
        });
        this.socketClient.socket.on('platformsUpdated', () => {
            this.resetBallPosition();
        });
        this.socketClient.socket.on('roomStateReceived', this.handleInitialState.bind(this));

        this.socketClient.socket.on('batchedBallUpdates', (updates: { [playerId: string]: BallUpdate }) => {
            Object.entries(updates).forEach(([playerId, update]) => {
                if (playerId !== this.socketClient.socket.id) {
                    this.updateOtherPlayerBall(playerId, update);
                }
            });
        });
    }

    private handleInitialState(state: any): void {
        Object.keys(this.otherBalls).forEach(id => this.removeOtherPlayerBall(id));
        Object.entries(state.players).forEach(([id, playerData]: [string, any]) => {
            if (id !== this.socketClient.socket.id) {
                this.createOtherPlayerBall(id, playerData);
            }
        });
    }

    public async createBall(): Promise<void> {
        try {
            this.localBallId = this.socketClient.socket.id;

            const ballTexture = await Assets.load(golfball);
            const ballSprite = new Sprite({ texture: ballTexture });
            ballSprite.width = BALL_RADIUS * 2;
            ballSprite.height = BALL_RADIUS * 2;
            ballSprite.anchor.set(0.5);

            const nameText = new Text({
                text: this.socketClient.name,
                style: new TextStyle({
                    fontFamily: 'Poppins',
                    fontSize: this.isMobile ? 20 : 14,
                    fill: { color: 0xFFFFFF },
                    stroke: { color: 0xAAAAAA, width: 2 }
                })
            });
            nameText.anchor.set(0.5, 1);
            nameText.y = -BALL_RADIUS - 5;

            const container = new Container();
            container.addChild(ballSprite);
            container.addChild(nameText);
            this.gameWorld.addChild(container);

            this.ball = {
                position: { x: PLAYABLE_AREA / 2 + WALL_THICKNESS, y: CANVAS_HEIGHT - FLOOR_HEIGHT - BALL_RADIUS },
                velocity: { x: 0, y: 0 },
                angle: 0,
                graphics: container
            };

            this.matterWorker.postMessage({
                type: 'createBall',
                radius: BALL_RADIUS,
                position: this.ball.position,
                collisionFilter: {
                    category: 0x0001,
                    mask: 0x0002
                }
            });

            this.ringGraphics.clear();
            this.ringGraphics.circle(0, 0, BALL_RADIUS + (this.isMobile ? 10 : 5));
            this.ringGraphics.stroke({ width: this.isMobile ? 20 : 4, color: 0xFFFFFF, alpha: 0.5 });
            this.ringGraphics.x = this.ball.position.x;
            this.ringGraphics.y = this.ball.position.y;

            this.soundManager.playPutSound(3);
            this.updateBallPositionOnSocket();
        } catch (error) {
            console.error("error creating ball", error);
        }
    }


    public async createOtherPlayerBall(id: string, playerData: any): Promise<void> {
        console.log('creating other player ball', id, playerData);
        if (id === this.localBallId) return;
        if (this.otherBalls[id]) {
            this.removeOtherPlayerBall(id);
        }
        const position = playerData.position || { x: PLAYABLE_AREA / 2, y: CANVAS_HEIGHT - FLOOR_HEIGHT - BALL_RADIUS };
        const ballColor = this.hexToHex(playerData.ballColor || 'rgb(255, 255, 255)');

        const ballTexture = await Assets.load(golfball);
        const ballSprite = new Sprite({ texture: ballTexture, anchor: { x: 0.5, y: 0.5 } });
        // ballSprite.tint = ballColor;
        ballSprite.width = BALL_RADIUS * 2;
        ballSprite.height = BALL_RADIUS * 2;

        const nameText = new Text({
            text: playerData.name || 'Unknown',
            style: new TextStyle({
                fontFamily: 'Verdana',
                fontSize: this.isMobile ? 20 : 14,
                fill: { color: 0xFFFFFF },
                stroke: { color: 0xAAAAAA, width: 2 }
            })
        });
        nameText.anchor.set(0.5, 1);
        nameText.y = -BALL_RADIUS - 5;

        const container = new Container();
        container.addChild(ballSprite);
        container.addChild(nameText);
        container.x = position.x + WALL_THICKNESS;
        container.y = position.y;
        this.gameWorld.addChild(container);

        const trailGraphics = new Graphics();
        this.gameWorld.addChild(trailGraphics);

        this.otherBalls[id] = { graphics: container, trailGraphics, trailPoints: [] };
        this.previousAndTargetPositions[id] = { previous: position, target: position, lerpTime: 0 };
    }

    public update(delta: number): void {
        if (this.ball) {
            this.updateBallGraphics();
            this.updateRingGraphics(delta);
        }

        this.updateOtherBalls(delta);

        const currentlyHittable = !this.cantHit();
        if (currentlyHittable && !this.previouslyHittable) {
            this.soundManager.playClickSound();
        }
        this.previouslyHittable = currentlyHittable;

    }
    private updateBallGraphics(): void {
        if (!this.ball) return;
        this.ball.graphics.x = this.ball.position.x;
        this.ball.graphics.y = this.ball.position.y;
        this.ball.graphics.rotation = this.ball.angle;
        (this.ball.graphics.children[0] as Sprite).tint = this.hexToHex(this.socketClient.ballColor);
    }

    private updateRingGraphics(delta: number): void {
        if (!this.ball) return;
        if (this.cantHit()) {
            this.ringGraphics.clear();
            return;
        }
        this.pulsationTime += delta * 0.1;
        const pulsation = Math.sin(this.pulsationTime) * 2 + (this.isMobile ? 20 : 5);
        this.ringGraphics.clear();
        this.ringGraphics.circle(0, 0, BALL_RADIUS + pulsation);
        this.ringGraphics.stroke({ width: this.isMobile ? 20 : 4, color: 0xFFFF00, alpha: 0.5 });
        this.ringGraphics.x = this.ball.position.x;
        this.ringGraphics.y = this.ball.position.y;
    }

    private updateTrail(): void {
        if (!this.ball) return;
        this.trailPoints.unshift({ x: this.ball.position.x, y: this.ball.position.y });
        if (this.trailPoints.length > 20) this.trailPoints.pop();

        this.trailGraphics.clear();
        for (let i = 0; i < this.trailPoints.length - 1; i++) {
            const alpha = 0.9 - (i / this.trailPoints.length);
            this.trailGraphics.moveTo(this.trailPoints[i].x, this.trailPoints[i].y);
            this.trailGraphics.lineTo(this.trailPoints[i + 1].x, this.trailPoints[i + 1].y);
            this.trailGraphics.stroke({ width: BALL_RADIUS * 2 * (1 - i / this.trailPoints.length), color: 0xFFFFFF, alpha: alpha * 0.5 });
        }
    }

    private updateBallPositionOnSocket(now = false): void {
        if (now || Date.now() - this.lastUpdate < BALL_UPDATE_RATE) return;
        this.lastUpdate = Date.now();
        if (!this.ball) return;
        this.socketClient.updateBallPosition({
            position: { x: this.ball.position.x - WALL_THICKNESS, y: this.ball.position.y },
            inMotion: this.inMotion,
            initialPosition: this.initialPosition,
            highestHeight: this.highestHeight,
            angle: this.ball.angle,
            // we also wanna include this player's scoring data for this round
            scoring: this.gameStateManager.roomState.players[this.socketClient.socket.id].scoring,
        });
    }

    private cantHit(): boolean {
        return this.hasLeftTheGroundSinceLastStrike || !this.ball || this.gameStateManager.isroundEnded;
    }
    public strike(x: number, y: number): void {
        // log all conditions that would prevent a strike
        if (this.cantHit()) return;
        this.hasLeftTheGroundSinceLastStrike = true;
        const dx = this.ball.position.x - (x - WALL_THICKNESS);
        const dy = this.ball.position.y - y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const forceOffset = localStorage.getItem('forceOffset') || defaultSettings.player.forceOffset;
        const power = calculateForce(x - WALL_THICKNESS, y, this.ball.position.x, this.ball.position.y, false, Number(forceOffset));
        cameraShake(this.app.stage, power * 40, 220);
        const force = {
            x: (dx / distance) * power,
            y: (dy / distance) * power
        };

        this.matterWorker.postMessage({
            type: 'applyForce',
            force: force
        });

        this.inMotion = true;
        this.initialPosition = { x: this.ball.position.x, y: this.ball.position.y };
        this.gameStateManager.roomState.players[this.socketClient.socket.id].scoring.clicks[this.gameStateManager.roomState.round] += 1;
        const ballData: BallUpdate = {
            scoring: this.gameStateManager.roomState.players[this.socketClient.socket.id].scoring,
            roomId: this.socketClient.roomId,
            position: { x: this.ball.position.x - WALL_THICKNESS, y: this.ball.position.y },
            inMotion: true,
            initialPosition: this.initialPosition,
            highestHeight: this.highestHeight,
            strikePosition: { x: x - WALL_THICKNESS, y },
            strikePower: power,
            strike: true,
            angle: 0
        };
        this.socketClient.updateBallPosition(ballData);

        this.soundManager.playPutSound(power);
    }

    public resetBallPosition(): void {
        if (this.ball) {
            const newPosition = {
                x: PLAYABLE_AREA / 2 + WALL_THICKNESS,
                y: (CANVAS_HEIGHT - FLOOR_HEIGHT - BALL_RADIUS)
            };
            this.matterWorker.postMessage({
                type: 'resetBall',
                position: newPosition,
                velocity: { x: 0, y: 0 }
            });
            this.ball.position = newPosition;
            this.ball.velocity = { x: 0, y: 0 };
            this.updateBallGraphics();
            this.trailPoints = [];
            this.trailGraphics.clear();
            this.inMotion = false;
            this.initialPosition = null;
            this.highestHeight = 0;
            this.updateBallPositionOnSocket(true);
        }
    }

    public updateOtherPlayerBall(id: string, data: any): void {
        this.gameStateManager.updatePlayerFromWebRTCBallUpdate(id, data);

        const otherBall = this.otherBalls[id];
        if (otherBall) {
            advancedInterpolation.updateBallPosition(id, data.position, Date.now());
        } else {
            this.createOtherPlayerBall(id, data);
        }

        Object.assign(this.gameStateManager.roomState.players[id], data);
    }

    private updateOtherBalls(delta: number): void {
        Object.entries(this.otherBalls).forEach(([id, ball]) => {
            const playerData = this.gameStateManager.roomState.players[id];
            if (!playerData) return;

            const interpolatedPosition = advancedInterpolation.getInterpolatedPosition(id, Date.now());
            if (interpolatedPosition) {
                ball.graphics.x = interpolatedPosition.x + WALL_THICKNESS;
                ball.graphics.y = interpolatedPosition.y;
                this.updateOtherBallTrail(ball, id);
            }

            // Update ball color
            const ballSprite = ball.graphics.children[0] as Sprite;
            const currentColor = ballSprite.tint;
            const newColor = this.hexToHex(playerData.ballColor);
            if (currentColor !== newColor) {
                ballSprite.tint = newColor;
            }

            // Update player name
            const nameText = ball.graphics.children[1] as Text;
            if (nameText.text !== playerData.name) {
                nameText.text = playerData.name;
            }

            // Update ball angle if available
            if (playerData.angle !== undefined) {
                ball.graphics.rotation = playerData.angle;
            }
        });
    }
    private updateOtherBallTrail(ball: OtherBallData, id: string): void {
        ball.trailPoints.unshift({ x: ball.graphics.x, y: ball.graphics.y });
        if (ball.trailPoints.length > 20) ball.trailPoints.pop();

        ball.trailGraphics.clear();
        for (let i = 0; i < ball.trailPoints.length - 1; i++) {
            const alpha = 0.9 - (i / ball.trailPoints.length);
            ball.trailGraphics.moveTo(ball.trailPoints[i].x, ball.trailPoints[i].y);
            ball.trailGraphics.lineTo(ball.trailPoints[i + 1].x, ball.trailPoints[i + 1].y);
            ball.trailGraphics.stroke({ width: BALL_RADIUS * 2 * (1 - i / ball.trailPoints.length), color: 0xFFFFFF, alpha: alpha * 0.5 });
        }
    }

    public removeOtherPlayerBall(id: string): void {
        const otherBall = this.otherBalls[id];
        if (otherBall) {
            this.gameWorld.removeChild(otherBall.graphics);
            this.gameWorld.removeChild(otherBall.trailGraphics);
            delete this.otherBalls[id];
            delete this.previousAndTargetPositions[id];
        }
    }

    private handleCollisionResults(collisions: any[]): void {
        this.colliding = collisions.length > 0;
        if (this.colliding) {
            collisions.forEach(collision => {
                const otherElement = collision.bodyA.label === 'local_ball' ? collision.bodyB : collision.bodyA;
                switch (otherElement.entityId) {
                    case SPECIAL_PLATFORM_IDS.FINISH_LINE:
                        this.handleFinishLineCollision(otherElement);
                        break;
                    case SPECIAL_PLATFORM_IDS.FLOOR:
                        break;
                    case SPECIAL_PLATFORM_IDS.COIN:
                        this.handleCoinCollision(otherElement.coinIndex);
                        break;
                    default:
                }
            });

            if (this.ball && Math.sqrt(this.ball.velocity.x ** 2 + this.ball.velocity.y ** 2) > 2.5) {
                this.soundManager.playBounceSound(Math.sqrt(this.ball.velocity.x ** 2 + this.ball.velocity.y ** 2));
            }
        } else {
            this.hasLeftTheGroundSinceLastStrike = true;
        }
    }

    private handleFinishLineCollision(goal: any): void {
        if (this.gameStateManager.getCurrentPlayer().hasHitTheGoal || (!this.isBallStationary() && goal.label === "bucket")) return;
        if (this.gameStateManager.isroundEnded) return;
        this.gameStateManager.iHitTheGoal();
        this.gameStateManager.isroundEnded = true;


        this.socketClient.socket.emit('playerFinished', {
            roomId: this.socketClient.roomId,
            playerId: this.socketClient.socket.id
        });

        this.confettiEffect.start();
        setTimeout(() => {
            this.confettiEffect.stop();
        }, 3000);
    }

    private setupListeners() {
        this.socketClient.socket.on('playerJoined', this.createOtherPlayerBall.bind(this));
        this.socketClient.socket.on('ballUpdated', this.updateOtherPlayerBall.bind(this));
        this.socketClient.socket.on('playerLeft', this.removeOtherPlayerBall.bind(this));
    }

    private handleCoinCollision(coinIndex: number): void {
        this.platformManager.removeCoin(coinIndex, true);
        // old central server code
        // this.socketClient.socket.emit('coinCollected', {
        //     roomId: this.socketClient.roomId,
        //     index: coinIndex,
        //     timeStamp: Date.now()
        // });

        // new decentralized code
        this.socketClient.notifyCoinCollected({
            playerId: this.socketClient.socket.id,
            index: coinIndex,
            timeStamp: Date.now()
        });
    }

    private updateHighestHeight(): void {
        if (!this.ball) return;
        const height = CANVAS_HEIGHT - FLOOR_HEIGHT - BALL_RADIUS - this.ball.position.y;
        if (height > this.highestHeight) {
            this.highestHeight = height;
        }
    }

    private isBallStationary(): boolean {
        if (!this.ball) return true;
        const velocityThreshold = 0.1;
        return Math.abs(this.ball.velocity.x) < velocityThreshold &&
            Math.abs(this.ball.velocity.y) < velocityThreshold;
    }

    public isBallTouchingPlatform(): boolean {
        if (!this.ball) return false;
        if (this.ball.position.y + BALL_RADIUS >= CANVAS_HEIGHT - FLOOR_HEIGHT) return true;

        this.matterWorker.postMessage({
            type: 'isBallTouchingPlatform',
            platforms: this.platformManager.platforms
        });
        return false;
    }

    public removeAllBalls(): void {
        if (this.ball) {
            this.matterWorker.postMessage({ type: 'removeBall' });
            this.gameWorld.removeChild(this.ball.graphics);
            this.ball = null;
        }

        Object.values(this.otherBalls).forEach(otherBall => {
            this.gameWorld.removeChild(otherBall.graphics);
            this.gameWorld.removeChild(otherBall.trailGraphics);
        });

        this.otherBalls = {};
        this.previousAndTargetPositions = {};
        this.trailPoints = [];
        this.trailGraphics.clear();
    }

    private hexToHex(stringFormat: string): number {
        return parseInt(stringFormat.replace('#', '0x'));
    }

    public getPosition(): { x: number, y: number } | null {
        return this.ball?.position ? { ...this.ball?.position } : null;
    }

}