import { UPDATE_INTERVAL, UPDATE_ONLY_IF_MOVED } from './constants';
import { detectDevice } from './utils';
import { Socket, io } from 'socket.io-client';
import { RoomState, BallUpdate } from '../shared/sharedTypes';
import { createPlatformParams } from './platforms/platformParams';
import SimplePeer from 'simple-peer';
import 'webrtc-adapter';

const CONNECTION_TYPE = import.meta.env.VITE_CONNECTION_MODE

class LocalPeer {
    private socketClient: SocketClient;
    private handleData: (data: string) => void;
    constructor(socketClient: SocketClient, handleData: (data: string) => void) {
        this.socketClient = socketClient;
        this.handleData = handleData;
    }

    send(data: string): void {
        this.handleData(data);
    }
}

class SocketClient {
    public socket: Socket;
    public roomId: string;
    public name: string;
    public ballColor: string;
    private device: string;
    private lastUpdateTime: number = 0;
    private lastTransmittedPosition: { x: number; y: number } = { x: 0, y: 0 };
    public regenAndSharePlatforms: (() => void) | undefined;
    private bootStrapCallback: (roomState: RoomState) => void;
    private bootstrapCallbackCalled: boolean = false;
    private gameStateManager: any;
    private peerConnections: Map<string, RTCPeerConnection> = new Map();
    private dataChannels: Map<string, RTCDataChannel> = new Map();
    private peers: Map<string, SimplePeer.Instance | LocalPeer> = new Map();
    private attemptingReconnectionWithPeer: any = new Set();

    constructor(bootstrapCallback: (roomState: RoomState) => void) {
        this.bootStrapCallback = bootstrapCallback;
        this.socket = io({
            transports: ['websocket'],
            upgrade: false,
            secure: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 9
        });
        this.roomId = this.getRoomIdFromUrl();

        const savedSettings = localStorage.getItem('golfUpSettings');
        const settings = savedSettings ? JSON.parse(savedSettings) : {};
        this.name = settings.player?.name || `Player ${Math.floor(Math.random() * 1000)}`;
        this.ballColor = settings.player?.ballColor || `#ffffff`;
        this.device = detectDevice();

        this.setupSocketListeners();
        this.setupHeartbeat();
        this.setupReconnectionHandler();

        this.joinRoom.bind(this);

        if (CONNECTION_TYPE === 'webrtc') {
            this.initWebRTC();
        }

        // Add LocalPeer
        this.peers.set(this.socket.id, new LocalPeer(this, this.handleData.bind(this)));
    }

    private initWebRTC(): void {
        this.socket.on('peers_list', (peerIds: string[]) => {
            peerIds.forEach(peerId => this.connectToPeer(peerId, true));
        });

        this.socket.on('webrtc_signal', (data: { from: string, signal: any }) => {
            const peer = this.peers.get(data.from);
            if (peer instanceof SimplePeer) {
                peer.signal(data.signal);
            } else {
                const newPeer = this.connectToPeer(data.from, false);
                newPeer.signal(data.signal);
            }
        });

        this.socket.emit('request_peers', this.roomId);

        setInterval(() => {
            this.peers.forEach((peer, peerId) => {
                if (peer instanceof SimplePeer && !peer.connected) {
                    console.error('Peer not connected: A', peerId, 'attempting reconnection');
                    this.reconnectToPeer(peerId);
                }
            });
        }, 10000);
    }

    private connectToPeer(peerId: string, initiator: boolean): SimplePeer.Instance {
        const peer = new SimplePeer({
            initiator,
            trickle: true,
            wrtc: {
                RTCPeerConnection,
                RTCSessionDescription,
                RTCIceCandidate
            },
            config: {
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    { urls: 'stun:global.stun.twilio.com:3478' }
                ]
            }
        });

        peer.on('signal', signal => {
            this.socket.emit('webrtc_signal', { to: peerId, signal });
        });

        peer.on('data', data => {
            this.handleData(data);
        });

        peer.on('connect', () => {
            peer.send(JSON.stringify({ type: 'initialPeerData', data: this.gameStateManager?.getCurrentPlayer() }));
            this.deletePeerReconnectionAttempt(peerId);
        });

        peer.on('error', (err) => {
            console.error('Error with peer connection:', peerId, err);
            this.deletePeerReconnectionAttempt(peerId);
        });

        peer.on('close', () => {
            this.peers.delete(peerId);
            this.deletePeerReconnectionAttempt(peerId);
        });

        this.peers.set(peerId, peer);
        this.gameStateManager?.addPlayer({ ...this.gameStateManager?.player, id: peerId });

        return peer;
    }

    private handleData(data: any): void {
        data = JSON.parse(data);
        switch (data.type) {
            case 'initialPeerData':
                this.handleInitialPeerData(data.data);
                break;
            case 'updateBall':
                this.handleWebRTCBallUpdate(data.id, data.data);
                break;
            case 'chatMessage':
                this.onWebRTCMessage?.(data);
                break;
            case 'coinCollected':
                this.handleCoinCollected(data.data);
                break;
            case 'finalScore':
                this.gameStateManager.handleFinalScore(data.id, data.data);
                break;
        }
    }

    public handleInitialPeerData(data: any): void {
        // This method should be overwritten by the game logic to handle initial player

    }

    public handleWebRTCBallUpdate(id: string, data: BallUpdate): void {
        // This method should be overwritten by the game logic to handle ball updates
    }

    public handleCoinCollected(data: { playerId: string; index: number, timeStamp: number }): void {
        // This method should be overwritten in gameStateManager to handle coin collection
    }

    public onWebRTCMessage: ((data: any) => void) | null = null;

    private reconnectToPeer(peerId: string): void {
        if (this.attemptingReconnectionWithPeer.has(peerId)) {
            return;
        }
        this.peers.delete(peerId);
        this.connectToPeer(peerId, true);
        this.attemptingReconnectionWithPeer.add(peerId);
    }

    private deletePeerReconnectionAttempt(peerId: string): void {
        if (this.attemptingReconnectionWithPeer.has(peerId)) {
            this.attemptingReconnectionWithPeer.delete(peerId);
        }
    }

    public sendToPeers(data: any): void {
        const message = JSON.stringify(data);
        this.peers.forEach((peer, peerId) => {
            if (peer instanceof LocalPeer || (peer as SimplePeer.Instance).connected) {
                peer.send(message);
            } else {
                console.error('Peer is not connected: B', peerId, "attempting reconnection");
                if (peerId !== this.socket.id) {
                    this.reconnectToPeer(peerId);
                }
            }
        });
    }

    private getRoomIdFromUrl(): string {
        const urlParams = new URLSearchParams(window.location.search);
        let roomId = urlParams.get('room');
        if (!roomId) {
            roomId = 'welcome';
            window.history.replaceState(null, null, `?room=${roomId}`);
        }
        return roomId;
    }

    private setupSocketListeners(): void {
        this.socket.on('roomStateReceived', (roomDataAndPlatforms: RoomState) => {
            this.handleRoomStateReceived(roomDataAndPlatforms);
        });

        this.socket.on('connect', () => {
            this.joinRoom();
        });

        this.socket.on('newRoomPleaseGenerate', () => {
            this.handleNewRoomPleaseGenerate();
        });

        this.socket.on('disconnect', () => {
        });

        this.socket.on('connect_error', (error: Error) => {
            console.error('Connection error:', error);
        });

        this.socket.on('connect_timeout', () => {
            console.error('Connection timeout');
        });

        this.socket.on('error', (error: Error) => {
            console.error('Socket error:', error);
        });

        this.socket.on('relayed_message', (data: { from: string, data: any }) => {
        });
    }

    private setupHeartbeat(): void {
        setInterval(() => {
            this.socket.emit('heartbeat', { timestamp: Date.now() });
        }, 20000);
    }

    private setupReconnectionHandler(): void {
        this.socket.io.on("reconnect", () => {
            this.joinRoom();
        });
    }

    public createRoom(roomSettings: any, platforms: any, newRoom: boolean = true): void {
        if (newRoom) {
            this.socket.emit('createRoom', {
                roomSettings,
                roomId: this.roomId,
                name: this.name,
                ballColor: this.ballColor,
                platforms: platforms,
                device: this.device
            });
        } else {
            this.socket.emit('updateRoom', {
                roomSettings,
                roomId: this.roomId,
                platforms: platforms
            });
        }
    }

    public joinRoom(): void {
        this.socket.emit('joinRoom', {
            roomId: this.roomId,
            name: this.name,
            ballColor: this.ballColor,
            device: detectDevice()
        });
    }

    private handleNewRoomPleaseGenerate() {
        const platformData = []
        createPlatformParams(true, this, platformData);
    }

    private handleRoomStateReceived(roomState: RoomState): void {
        if (roomState.roomSettings) {
            if (!this.bootstrapCallbackCalled) {
                this.bootstrapCallbackCalled = true;
                this.bootStrapCallback(roomState);
            }
        } else {
            const platforms = [];
            createPlatformParams(true, this, platforms);
        }
    }

    public updateBallPosition(ballUpdate: BallUpdate): void {
        if (this.lastUpdateTime + UPDATE_INTERVAL > Date.now()) {
            return;
        }
        if (this.basicallyHasntMoved(ballUpdate.position, this.lastTransmittedPosition) && UPDATE_ONLY_IF_MOVED) {
            return;
        }
        switch (CONNECTION_TYPE) {
            case 'socket':
                this.socket.emit('updateBall', ballUpdate);
                break;
            case 'webrtc':
                this.sendToPeers({ type: 'updateBall', data: ballUpdate, id: this.socket.id });
                break;
            default:
                console.error('Invalid connection type');
        }
        this.lastUpdateTime = Date.now();
        this.lastTransmittedPosition = ballUpdate.position;
    }

    public notifyCoinCollected(coinData: { playerId: string; index: number; timeStamp: number }): void {
        this.sendToPeers({ type: 'coinCollected', data: coinData });
    }

    private basicallyHasntMoved(pos1: { x: number; y: number }, pos2: { x: number; y: number }): boolean {
        return Math.abs(pos1.x - pos2.x) < 0.1 && Math.abs(pos1.y - pos2.y) < 0.1;
    }

    public leaveRoom(): void {
        this.socket.emit('leaveRoom', this.roomId);
    }

    public platformsCreatedLocally(settings: any, platforms: any, newRoom: boolean): void {
        this.createRoom(settings.roomSettings, platforms, newRoom);
    }

    public loadSavedLevel(level: { roomSettings: any; platforms: any }): void {
        this.createRoom(level.roomSettings, level.platforms, false);
    }
}

export default SocketClient;