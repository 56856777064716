import { PLAYABLE_AREA, CANVAS_HEIGHT, FLOOR_HEIGHT } from '../constants';
import { platformDistributor } from './platformDistribution';
import { RoomSettings } from '../../shared/sharedTypes';

interface ArcParams {
    centerX: number;
    y: number;
    radius: number;
    thickness: number;
    startAngle: number;
    endAngle: number;
    divisions: number;
    isGoal?: boolean;
    goalType?: string;
}

export function createArcPlatforms(settings: { roomSettings: RoomSettings }, platformData: any[]): void {
    const { roomSettings } = settings;
    const numberOfArcs = roomSettings.platforms.Arc.density;
    const temperature = 0.5; // Adjust this value as needed
    const minDistance = 10; // Adjust this value based on typical arc size

    const arcHeights = platformDistributor.distributePlatforms(numberOfArcs, temperature, minDistance, true);

    arcHeights.forEach((y, index) => {
        const arcParams = createArcPlatformParams(y, index % 2 === 0, roomSettings.platforms.Arc.snap);
        platformData.push({
            type: "arcMesh",
            params: arcParams,
            height: y
        });
    });
}

function createArcPlatformParams(y: number, upward_symmetrical: boolean = false, snap_to_edge: boolean = false): ArcParams {
    const radii = [2, 2, 2, 8, 8, 10, 60, 70, 80, 90, 150];
    const thickness = 40 + Math.random() * 30;
    let startAngleDegrees: number, angleExtentDegrees: number;
    const range = 90 + Math.random() * 250;
    const divisions = [2, 3, 4, 2, 3, 4, 5, 6, 12, 12];
    const radius = radii[Math.floor(Math.random() * radii.length)];

    if (upward_symmetrical) {
        startAngleDegrees = 90 - range / 2;
        angleExtentDegrees = range;
    } else {
        startAngleDegrees = Math.random() * 360;
        angleExtentDegrees = range;
    }

    let centerX = Math.random() * PLAYABLE_AREA;

    if (snap_to_edge) {
        centerX = centerX < PLAYABLE_AREA / 2 ? radius + thickness / 2 : PLAYABLE_AREA - (radius + thickness / 2);
    }

    return {
        centerX,
        y,
        radius,
        thickness,
        startAngle: startAngleDegrees * (Math.PI / 180),
        endAngle: (startAngleDegrees + angleExtentDegrees) * (Math.PI / 180),
        divisions: divisions[Math.floor(Math.random() * divisions.length)]
    };
}

export function generateArcMeshFromParams(roomArcSettings: any, params: ArcParams): {
    meshType: string;
    vertices: [number, number][];
    y: number;
} {
    const meshData = generateArcMesh(
        params.startAngle,
        params.endAngle,
        params.radius,
        params.thickness,
        params.divisions
    );

    if (roomArcSettings.snap) {
        if (params.centerX < PLAYABLE_AREA / 2) {
            params.centerX = params.radius + params.thickness / 2;
        } else {
            params.centerX = PLAYABLE_AREA - (params.radius + params.thickness / 2);
        }
    }

    return {
        ...meshData,
        y: params.y,
        vertices: meshData.vertices.map(v => [v[0] + params.centerX, v[1] + params.y])
    };
}

function generateArcMesh(startAngle: number, endAngle: number, radius: number, thickness: number, divisions: number): {
    meshType: string;
    vertices: [number, number][];
} {
    const vertices: [number, number][] = [];
    const angleStep = (endAngle - startAngle) / divisions;

    for (let i = 0; i <= divisions; i++) {
        const angle = startAngle + i * angleStep;
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);

        vertices.push(
            [radius * cos, radius * sin],
            [(radius + thickness) * cos, (radius + thickness) * sin]
        );
    }

    return {
        meshType: "arc",
        vertices: vertices,
    };
}