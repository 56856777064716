import { PLAYABLE_AREA, CANVAS_HEIGHT, FLOOR_HEIGHT, WALL_THICKNESS } from '../constants';
import { platformDistributor } from './platformDistribution';
import { RoomSettings } from '../../shared/sharedTypes';

enum NoiseType {
    RANDOM = 'random',
    SIMPLEX = 'simplex',
}

enum AnimationType {
    NONE,
    LINEAR,
    PINGPONG,
}

enum StepType {
    FLUID,
    CLOCK,
}

interface BinaryClusterParams {
    x: number;
    y: number;
    width: number;
    height: number;
    rows: number;
    columns: number;
    seed: number;
    noiseType: NoiseType;
    isCheckered: boolean;
    probabilityThreshold: number;
    spaceBetweenCells: number;
    cellSize: number;
    animationType: AnimationType;
    stepType: StepType;
}

export function createBinaryClustersParams(settings: { roomSettings: RoomSettings }, platformData: any[], noiseType: NoiseType = NoiseType.SIMPLEX): void {
    const width = 750;
    const height = 90;
    const cellSize = 30;
    const spaceBetweenCells = 0;
    const isCheckered = false;
    const probabilityThreshold = 0;
    const numberOfClusters = settings.roomSettings.platforms.BinaryCluster.density;
    const temperature = 0.3;
    const minDistance = 50;

    const clusterHeights = platformDistributor.distributePlatforms(numberOfClusters, temperature, minDistance);

    const animationTypes = [AnimationType.NONE, AnimationType.LINEAR, AnimationType.PINGPONG];
    const stepTypes = [StepType.FLUID, StepType.CLOCK];

    clusterHeights.forEach((y, i) => {
        const rows = Math.floor(height / (cellSize + spaceBetweenCells));
        const columns = Math.floor(width / (cellSize + spaceBetweenCells));
        let clusterX = 1//Math.random() * (PLAYABLE_AREA - width);

        if (settings.roomSettings.platforms.BinaryCluster.snap) {
            clusterX = clusterX < PLAYABLE_AREA / 2 ? 0 : PLAYABLE_AREA - width;
        }

        const seed = Math.random() * 1000;
        const animationType = i % 2 === 1 ? AnimationType.NONE : AnimationType.LINEAR
        const stepType = StepType.FLUID;

        platformData.push({
            type: "binaryCluster",
            x: clusterX,
            y: y,
            width: width,
            height: height,
            rows: rows,
            columns: columns,
            seed: seed,
            noiseType: noiseType,
            isCheckered: isCheckered,
            probabilityThreshold: probabilityThreshold,
            spaceBetweenCells: spaceBetweenCells,
            cellSize: cellSize,
            animationType: animationType,
            stepType: stepType
        });
    });
}

export function decodeBinaryCluster(
    roomBinaryClusterSettings: any,
    params: BinaryClusterParams,
    currentTime: number
): {
    type: string;
    activeCells: { row: number; column: number }[];
    cellSize: number;
    rows: number;
    columns: number;
    x: number;
    y: number;
} {
    const { cellSize, rows, columns, seed, noiseType, isCheckered, probabilityThreshold, animationType, stepType, x, y } = params;
    const activeCells: { row: number; column: number }[] = [];

    // Calculate animation factor based on current time
    let animationFactor: number;
    switch (animationType) {
        case AnimationType.NONE:
            animationFactor = 0;
            break;
        case AnimationType.LINEAR:
            // Continuous linear growth, adjust the divisor to control speed
            animationFactor = currentTime / 10000;
            break;
        case AnimationType.PINGPONG:
            const pingPongPeriod = 8000;
            animationFactor = Math.abs(Math.sin((currentTime % pingPongPeriod) / pingPongPeriod * Math.PI));
            break;
        default:
            animationFactor = 0;
    }

    // Apply step type
    if (stepType === StepType.CLOCK) {
        animationFactor = Math.floor(currentTime / 1000); // Changes every second
    }

    // Create a new noise seed based on the original seed and the current animation factor
    const animatedSeed = seed + Math.floor(animationFactor * 1000);
    const animatedSimplexNoise = createSimplexNoise(animatedSeed);

    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < columns; j++) {
            let noiseValue: number;
            switch (noiseType) {
                case NoiseType.SIMPLEX:
                    noiseValue = (animatedSimplexNoise(i * 0.1 + animationFactor, j * 0.1 + animationFactor) + 1) / 2;
                    break;
                case NoiseType.RANDOM:
                default:
                    noiseValue = Math.random();
            }

            const shouldInclude = isCheckered
                ? (i + j) % 2 === 1 && noiseValue > probabilityThreshold
                : noiseValue > probabilityThreshold;

            if (shouldInclude) {
                activeCells.push({ row: i, column: j });
            }
        }
    }

    return {
        type: "decodedBinaryCluster",
        activeCells,
        cellSize,
        rows,
        columns,
        x: x + WALL_THICKNESS,
        y
    };
}
function createSimplexNoise(seed: number = Math.random()): (x: number, y: number) => number {
    const F2 = 0.5 * (Math.sqrt(3.0) - 1.0);
    const G2 = (3.0 - Math.sqrt(3.0)) / 6.0;

    const p = new Uint8Array(256);
    for (let i = 0; i < 256; i++) p[i] = i;

    let n: number;
    let q: number;
    for (let i = 255; i > 0; i--) {
        n = Math.floor((i + 1) * (seed * 1000 % 1));
        q = p[i];
        p[i] = p[n];
        p[n] = q;
    }

    const perm = new Uint8Array(512);
    const permMod12 = new Uint8Array(512);
    for (let i = 0; i < 512; i++) {
        perm[i] = p[i & 255];
        permMod12[i] = perm[i] % 12;
    }

    const grad3 = [
        [1, 1, 0], [-1, 1, 0], [1, -1, 0], [-1, -1, 0],
        [1, 0, 1], [-1, 0, 1], [1, 0, -1], [-1, 0, -1],
        [0, 1, 1], [0, -1, 1], [0, 1, -1], [0, -1, -1]
    ];

    return function (xin: number, yin: number): number {
        let n0 = 0, n1 = 0, n2 = 0;
        const s = (xin + yin) * F2;
        const i = Math.floor(xin + s);
        const j = Math.floor(yin + s);
        const t = (i + j) * G2;
        const X0 = i - t;
        const Y0 = j - t;
        const x0 = xin - X0;
        const y0 = yin - Y0;
        const i1 = x0 > y0 ? 1 : 0;
        const j1 = x0 > y0 ? 0 : 1;
        const x1 = x0 - i1 + G2;
        const y1 = y0 - j1 + G2;
        const x2 = x0 - 1.0 + 2.0 * G2;
        const y2 = y0 - 1.0 + 2.0 * G2;
        const ii = i & 255;
        const jj = j & 255;
        const t0 = 0.5 - x0 * x0 - y0 * y0;
        if (t0 >= 0) {
            const gi0 = permMod12[ii + perm[jj]];
            n0 = t0 * t0 * (grad3[gi0][0] * x0 + grad3[gi0][1] * y0);
        }
        const t1 = 0.5 - x1 * x1 - y1 * y1;
        if (t1 >= 0) {
            const gi1 = permMod12[ii + i1 + perm[jj + j1]];
            n1 = t1 * t1 * (grad3[gi1][0] * x1 + grad3[gi1][1] * y1);
        }
        const t2 = 0.5 - x2 * x2 - y2 * y2;
        if (t2 >= 0) {
            const gi2 = permMod12[ii + 1 + perm[jj + 1]];
            n2 = t2 * t2 * (grad3[gi2][0] * x2 + grad3[gi2][1] * y2);
        }
        return 70.0 * (n0 + n1 + n2);
    };
}