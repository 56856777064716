import { PLAYABLE_AREA } from './constants.js';
import chatIcon from './images/icons/icn_chat_512.png';


class Chat {
    constructor(bootstrapRoomState, socketClient) {
        this.socketClient = socketClient;
        this.chatBox = null;
        this.chatInput = null;
        this.chatMessages = null;
        this.chatToggleButton = null;
        this.isChatVisible = true;
        this.roomState = bootstrapRoomState;

        this.createChatUI();
        this.setupEventListeners();

        this.setupToggleButton();
        this.handleViewportChange();
        this.updateToggleButtonStyle();
    }

    createChatUI() {
        // Create chat container
        this.chatBox = document.createElement('div');
        this.chatBox.id = 'chat-box';
        this.chatBox.style.cssText = `
            position: fixed;
            right: 0px;
            top: 100px;
            font-family: 'Verdana', sans-serif;
            color: rgba(255, 255, 255,1);
            text-shadow: 0px 1px rgba(0, 0, 0,1);
            background: #2221;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            z-index:0;
            pointer-events: none;
        `;

        // tailwind styles - on mobile, the chat box will be full screen with white background. On desktop, it will be a fixed width and height with a transparent background.
        this.chatBox.classList.add('w-[98vw]', 'h-[80vh]', 'mx-4', 'md:w-[300px]', 'md:pr-4', 'md:h-1/3', 'bg-[#fff9]', 'md:bg-transparent', 'md:top-0', 'md:right-0', 'md:rounded-none', 'md:shadow-none');

        // Create chat messages area
        this.chatMessages = document.createElement('div');
        this.chatMessages.id = 'chat-messages';
        this.chatMessages.style.cssText = `
            flex-grow: 1;
            overflow-y: auto;
            padding: 10px;
        `;

        // Create chat input
        this.chatInput = document.createElement('input');
        this.chatInput.type = 'text';
        this.chatInput.placeholder = 'Type a message...';
        this.chatInput.style.cssText = `
            padding: 10px;
            border: none;
            border-radius: 5px;
            background-color: #2226;
            pointer-events: auto;
        `;

        // Append elements
        this.chatBox.appendChild(this.chatMessages);
        this.chatBox.appendChild(this.chatInput);
        document.body.appendChild(this.chatBox);
    }

    setupToggleButton() {
        this.chatToggleButton = document.createElement('button');
        this.chatToggleButton.id = 'chat-toggle-button';
        this.chatToggleButton.innerHTML = '<img class="w-9 h-9" src="' + chatIcon + '"/>';
        this.chatToggleButton.style.cssText = `
            position: fixed;
            right: 90px;
            top: 24px;
            padding: 10px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            background-color: transparent;
            transition: background-color 0.3s ease;
        `;

        this.chatToggleButton.addEventListener('mouseover', () => {
            if (!this.isChatVisible) {
                this.chatToggleButton.style.backgroundColor = '#CF0';
            }
        });

        this.chatToggleButton.addEventListener('mouseout', () => {
            if (!this.isChatVisible) {
                this.chatToggleButton.style.backgroundColor = 'transparent';
            }
        });

        this.chatToggleButton.addEventListener('click', () => {
            this.isChatVisible = !this.isChatVisible;
            this.chatBox.style.display = this.isChatVisible ? 'flex' : 'none';
            this.updateToggleButtonStyle();
        });

        document.body.appendChild(this.chatToggleButton);
    }

    updateToggleButtonStyle() {
        this.chatToggleButton.style.backgroundColor = this.isChatVisible ? '#FFF3' : 'transparent';
    }

    setupEventListeners() {
        this.chatInput.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' && this.chatInput.value.trim() !== '') {
                this.sendMessage(this.chatInput.value);
                this.chatInput.value = '';
            }
        });

        this.socketClient.onWebRTCMessage = (data) => {
            if (data.type === 'chatMessage') {
                this.displayMessage(data.data.name, data.data.message);
            }
        };

        this.socketClient.socket.on('playerJoined', (playerData) => {
            this.displaySystemMessage(`${playerData.name} joined the game.`);
        });

        this.socketClient.socket.on('playerLeft', (playerId, name) => {
            const playerName = name || 'A player';
            this.displaySystemMessage(`${playerName} left the game.`);
        });

        // roundEnded event
        this.socketClient.socket.on('roundEnded', (data) => {
            this.displaySystemMessage(`Round over! Level regenerating in ${data.timeUntilNextRound / 1000} seconds...`);
        });

        window.addEventListener('resize', this.handleViewportChange.bind(this));
    }

    sendMessage(message) {
        const chatData = {
            type: 'chatMessage',
            data: {
                name: this.socketClient.name,
                message: message
            }
        };
        this.socketClient.sendToPeers(chatData);
        // Display the message locally as well
        this.displayMessage(this.socketClient.name, message);
    }

    displayMessage(name, message) {
        const messageElement = document.createElement('p');
        messageElement.innerHTML = `<strong>${name}:</strong> ${message}`;
        this.chatMessages.appendChild(messageElement);
        this.chatMessages.scrollTop = this.chatMessages.scrollHeight;
    }

    displaySystemMessage(message) {
        const messageElement = document.createElement('p');
        messageElement.innerHTML = `<em>${message}</em>`;
        messageElement.style.color = '#fff9';
        this.chatMessages.appendChild(messageElement);
        this.chatMessages.scrollTop = this.chatMessages.scrollHeight;
    }

    toggleChatInputFocus() {
        if (!this.isChatVisible) {
            this.isChatVisible = true;
            this.chatBox.style.display = 'flex';
            this.chatInput.focus();
        } else {
            this.isChatVisible = false;
            this.chatBox.style.display = 'none';
            this.chatInput.blur();
        }
    }

    handleViewportChange() {
        if (window.innerWidth < PLAYABLE_AREA + 320) {
            this.chatBox.style.display = 'none';
            this.isChatVisible = false;
        } else {
            this.chatBox.style.display = 'flex';
            this.isChatVisible = true;
        }
        this.updateToggleButtonStyle();
    }
}

export default Chat;
