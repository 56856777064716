import { PLAYABLE_AREA, CANVAS_HEIGHT, FLOOR_HEIGHT, WALL_THICKNESS } from '../constants';
import { platformDistributor } from './platformDistribution';
import { RoomSettings } from '../../shared/sharedTypes';

interface SplineParams {
    points: [number, number][];
    thickness: number;
    divisions: number;
    closed: boolean;
    angle: number;
    height: number;
}

export function createRandomSpline(settings: { roomSettings: RoomSettings }, platformData: any[]): void {
    const { roomSettings } = settings;
    const numberOfSplines = roomSettings.platforms.Spline.density;
    const temperature = 0.6; // Adjust this value as needed
    const minDistance = 50; // Adjust this value based on typical spline height and curvature

    const splineHeights = platformDistributor.distributePlatforms(numberOfSplines, temperature, minDistance);

    splineHeights.forEach(y => {
        const splineParams = createSplineParams(y, roomSettings.platforms.Spline.snap);
        platformData.push({
            type: "splineMesh",
            params: splineParams,
        });
    });
}

function createSplineParams(y: number, snap: boolean): SplineParams {
    const numPoints = 4 + Math.floor(Math.random() * 2); // 4 to 5 points
    const points: [number, number][] = [];
    const width = PLAYABLE_AREA / 3;
    const height = 40 + Math.random() * 80;
    let centerX = Math.random() * PLAYABLE_AREA;
    const centerY = Math.max(y, FLOOR_HEIGHT + height / 2);
    const angle = Math.random() * Math.PI * 2;

    if (snap) {
        centerX = centerX < PLAYABLE_AREA / 2 ? 0 : PLAYABLE_AREA;
    }

    for (let i = 0; i < numPoints; i++) {
        const x = centerX + (i / (numPoints - 1) - 0.5) * width;
        const y = centerY + (Math.random() - 0.5) * height;
        points.push([x, y]);
    }

    return {
        points: points,
        thickness: 10 + Math.random() * 40,
        divisions: Math.min(8 + Math.floor(Math.random() * 8), 30), // Cap the maximum divisions
        closed: false,
        angle,
        height: y
    };
}

export function generateSplineMeshFromParams(roomSplineSettings: any, params: SplineParams): {
    meshType: string;
    vertices: [number, number][];
    height: number;
    thickness: number;
} {
    const vertices: [number, number][] = [];

    function catmullRom(p0: [number, number], p1: [number, number], p2: [number, number], p3: [number, number], t: number): [number, number] {
        const t2 = t * t;
        const t3 = t2 * t;
        return [
            0.5 * ((2 * p1[0]) + (-p0[0] + p2[0]) * t +
                (2 * p0[0] - 5 * p1[0] + 4 * p2[0] - p3[0]) * t2 +
                (-p0[0] + 3 * p1[0] - 3 * p2[0] + p3[0]) * t3),
            0.5 * ((2 * p1[1]) + (-p0[1] + p2[1]) * t +
                (2 * p0[1] - 5 * p1[1] + 4 * p2[1] - p3[1]) * t2 +
                (-p0[1] + 3 * p1[1] - 3 * p2[1] + p3[1]) * t3)
        ];
    }

    function normalVector(p1: [number, number], p2: [number, number]): [number, number] {
        const dx = p2[0] - p1[0];
        const dy = p2[1] - p1[1];
        const length = Math.sqrt(dx * dx + dy * dy);
        return [-dy / length, dx / length];
    }

    function rotatePoint(x: number, y: number, angle: number, centerX: number, centerY: number): [number, number] {
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        const dx = x - centerX;
        const dy = y - centerY;
        return [
            centerX + dx * cos - dy * sin,
            centerY + dx * sin + dy * cos
        ];
    }

    let points = params.closed ? [...params.points, params.points[0]] : params.points;

    // Calculate the center of rotation
    const centerX = points.reduce((sum, point) => sum + point[0], 0) / points.length;
    const centerY = points.reduce((sum, point) => sum + point[1], 0) / points.length;

    for (let i = 0; i < points.length - 1; i++) {
        const p0 = points[Math.max(i - 1, 0)];
        const p1 = points[i];
        const p2 = points[Math.min(i + 1, points.length - 1)];
        const p3 = points[Math.min(i + 2, points.length - 1)];

        for (let j = 0; j < params.divisions; j++) {
            const t = j / params.divisions;
            const point = catmullRom(p0, p1, p2, p3, t);
            const nextPoint = catmullRom(p0, p1, p2, p3, (j + 1) / params.divisions);
            const normal = normalVector(point, nextPoint);

            const v1 = rotatePoint(
                point[0] - normal[0] * params.thickness / 2,
                point[1] - normal[1] * params.thickness / 2,
                params.angle,
                centerX,
                centerY
            );
            const v2 = rotatePoint(
                point[0] + normal[0] * params.thickness / 2,
                point[1] + normal[1] * params.thickness / 2,
                params.angle,
                centerX,
                centerY
            );

            vertices.push(v1, v2);
        }
    }

    return {
        meshType: "spline",
        vertices,
        height: Math.min(...vertices.map(v => v[1])),
        thickness: params.thickness,
    };
}