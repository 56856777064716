import { CANVAS_WIDTH, CANVAS_HEIGHT } from './constants.js';
import { Container, Graphics } from 'pixi.js';
export default class ConfettiEffect {
    constructor(app) {
        this.app = app;
        this.confettiContainer = new Container();
        // set the confetti container to be above the ball
        this.confettiContainer.zIndex = 100000;
        this.app.stage.addChild(this.confettiContainer);
        this.colors = [
            0xFF0000, 0x00FF00, 0x0000FF, 0xFFFF00, 0xFF00FF, 0x00FFFF,
            0xFFA500, 0xFF69B4, 0x8A2BE2, 0x00FF7F
        ];
    }

    createConfettiPiece(color) {
        const graphics = new Graphics();
        graphics.rect(-5, -5, 10, 10);
        graphics.fill({ color });
        return graphics;
    }

    addNewConfetti() {
        const color = this.colors[Math.floor(Math.random() * this.colors.length)];
        const confetti = this.createConfettiPiece(color);
        confetti.x = Math.random() * CANVAS_WIDTH;
        confetti.y = 0;  // Start at the top of the canvas
        confetti.scale.set(0.5 + Math.random() * 0.5);
        confetti.alpha = 0.8;
        confetti.velocity = {
            y: 1 + Math.random() * 2
        };
        confetti.sineOffset = Math.random() * Math.PI * 2;
        confetti.sineSpeed = 0.05 + Math.random() * 0.05;
        confetti.amplitude = 1 + Math.random() * 2;
        this.confettiContainer.addChild(confetti);
    }

    update(delta) {
        // Add new confetti pieces
        if (this.confettiContainer.children.length < 100) {
            for (let i = 0; i < 2; i++) {
                this.addNewConfetti();
            }
        }

        // Update existing confetti
        for (let i = this.confettiContainer.children.length - 1; i >= 0; i--) {
            const confetti = this.confettiContainer.children[i];
            confetti.y += confetti.velocity.y * delta;
            confetti.x += Math.sin(confetti.sineOffset) * confetti.amplitude;
            confetti.sineOffset += confetti.sineSpeed;

            // Remove confetti if it's off-screen
            if (confetti.y > CANVAS_HEIGHT) {
                this.confettiContainer.removeChild(confetti);
            }
        }
    }

    start() {
        this.app.ticker.add(this.update, this);
    }

    stop() {
        this.app.ticker.remove(this.update, this);
        this.confettiContainer.removeChildren();
    }
}