import { CANVAS_WIDTH, CANVAS_HEIGHT, FLOOR_HEIGHT } from './constants.js';
import {
    Application,
    Assets,
    Sprite,
    Container,
    Rectangle,
    Graphics
} from "pixi.js";

class ExtendedSprite extends Sprite {
    public direction: number;
    public turningSpeed: number;
    public speed: number;
    public sinOffset: number;
    public lifeTime: number;
    public lifeTimeMax: number;
    public fadeTime: number;
    public fadeTimeMax: number;
    public velocity: number;
    public acceleration: number;
    public rotationSpeed: number;
    public rotationDirection: number;
    public scaleSpeed: number;
    public scaleDirection: number;
    public alphaSpeed: number;
    public alphaDirection: number;
}
export default class SakuraEffect {
    private app: Application;
    private gameWorld: Container;
    private sakuraBlossoms: ExtendedSprite[];
    private sprites: Container;
    private bounds: Rectangle;

    constructor(app: Application, gameWorld: Container) {
        this.app = app;
        this.gameWorld = gameWorld;
        this.sakuraBlossoms = [];
        this.sprites = new Container();
        this.gameWorld.addChild(this.sprites);

        this.initializeSakuraBlossoms();
    }

    private initializeSakuraBlossoms() {
        const sakuraPetal = new Graphics();
        sakuraPetal.circle(0, 0, 5);
        sakuraPetal.fill({ color: 0xFFFFFF, alpha: 0.3 });

        const texture = this.app.renderer.generateTexture(sakuraPetal);

        const totalBlossoms = 100;
        const boundsPadding = 10;

        this.bounds = new Rectangle(
            -boundsPadding,
            -boundsPadding,
            CANVAS_WIDTH + boundsPadding * 2,
            CANVAS_HEIGHT + boundsPadding * 2
        );

        for (let i = 0; i < totalBlossoms; i++) {
            const blossom = new ExtendedSprite(texture);
            blossom.anchor.set(0.5);
            blossom.scale.set(0.5 + Math.random() * 0.5);

            this.resetBlossomPosition(blossom);

            blossom.direction = Math.random() * Math.PI * 2;
            blossom.turningSpeed = (Math.random() - 0.5) * 0.02;
            blossom.speed = (1 + Math.random()) * 0.2;
            blossom.sinOffset = Math.random() * Math.PI * 2;

            this.sakuraBlossoms.push(blossom);
            this.sprites.addChild(blossom);
        }

        this.app.ticker.add(this.updateSakuraBlossoms.bind(this));
    }

    private resetBlossomPosition(blossom: Sprite) {
        blossom.x = Math.random() * CANVAS_WIDTH;
        blossom.y = Math.random() * CANVAS_HEIGHT;
    }

    private updateSakuraBlossoms(delta: number) {
        for (const blossom of this.sakuraBlossoms) {
            blossom.direction += blossom.turningSpeed;
            blossom.x += Math.sin(blossom.direction) * blossom.speed;
            blossom.y += Math.cos(blossom.direction) * blossom.speed * 0.5 - 0.3; // Slight upward drift
            blossom.rotation = Math.sin(blossom.sinOffset += 0.01) * 0.2; // Gentle rotation

            if (blossom.x < this.bounds.x) {
                blossom.x += this.bounds.width;
            } else if (blossom.x > this.bounds.x + this.bounds.width) {
                blossom.x -= this.bounds.width;
            }

            if (blossom.y < this.bounds.y) {
                blossom.y += this.bounds.height;
            } else if (blossom.y > this.bounds.y + this.bounds.height) {
                blossom.y -= this.bounds.height;
            }
        }
    }
}