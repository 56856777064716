import { CANVAS_HEIGHT, FLOOR_HEIGHT, CEILING_HEIGHT } from '../constants';

class SeededRandom {
    private seed: number;

    constructor(seed: number) {
        this.seed = seed;
    }

    // Simple xorshift algorithm
    next(): number {
        let x = this.seed;
        x ^= x << 13;
        x ^= x >> 17;
        x ^= x << 5;
        this.seed = x;
        return (x >>> 0) / 0xFFFFFFFF;
    }
}

interface PlacedPlatform {
    y: number;
    minDistance: number;
}

export class PlatformDistributor {
    private placedPlatforms: PlacedPlatform[] = [];
    private rng: SeededRandom;

    constructor(seed: number = 123) {
        this.rng = new SeededRandom(seed);
    }

    distributePlatforms(
        numberOfPlatforms: number,
        temperature: number,
        minDistance: number,
        minY: number = CEILING_HEIGHT,
        maxY: number = CANVAS_HEIGHT - FLOOR_HEIGHT,
        clearExistingPlacedPlatforms = false
    ): number[] {
        if (clearExistingPlacedPlatforms) this.placedPlatforms = [];

        const availableHeight = maxY - minY;
        const baseSpacing = availableHeight / (numberOfPlatforms + 1);

        const platforms: number[] = [];

        for (let i = 0; i < numberOfPlatforms; i++) {
            let attempts = 0;
            let validPosition = false;
            let y: number;

            while (!validPosition && attempts < 100) {
                const baseY = minY + baseSpacing * (i + 1);
                const randomOffset = (this.rng.next() - 0.5) * baseSpacing * temperature;
                y = Math.max(minY, Math.min(maxY, baseY + randomOffset));

                validPosition = this.isValidPosition(y, minDistance);
                attempts++;
            }

            if (validPosition) {
                platforms.push(y);
                this.placedPlatforms.push({ y, minDistance });
            } else {
                console.warn(`Could not place platform ${i + 1} after 100 attempts`);
            }
        }

        return platforms.sort((a, b) => a - b);
    }

    private isValidPosition(y: number, minDistance: number): boolean {
        return this.placedPlatforms.every(platform =>
            Math.abs(y - platform.y) >= Math.max(minDistance, platform.minDistance)
        );
    }

    reset(newSeed?: number): void {
        this.placedPlatforms = [];
        if (newSeed !== undefined) {
            this.rng = new SeededRandom(newSeed);
        }
    }
}

export const platformDistributor = new PlatformDistributor();