import { RoomState } from '../shared/sharedTypes';
import { START_POSITION } from './constants';
import { Player } from '../shared/sharedTypes';
import GameStateManager from './gameStateManager';

class PlayerList {
    private socketClient: any;
    private playerListElement: HTMLDivElement;
    private players: { [key: string]: Player };
    private round: number;
    private totalRounds: number;
    private campaignName: string;
    private title: HTMLHeadingElement;
    private gameStartTime: number;
    private gameStateManager: GameStateManager;

    constructor(gameStateManager: GameStateManager, socketClient: any) {
        this.gameStateManager = gameStateManager;
        console.log(gameStateManager.roomState)
        this.socketClient = socketClient;
        this.round = 1;
        this.totalRounds = 10;
        this.campaignName = "casual exp";
        this.playerListElement = null;
        this.title = null;
        this.gameStartTime = Date.now();
        this.createPlayerListUI();
        this.setupEventListeners();
        this.startTimer();

        this.round = this.gameStateManager.roomState.round;
        this.totalRounds = this.gameStateManager.roomState.totalRounds;
        this.gameStartTime = this.gameStateManager.roomState.gameStartTime;
        this.renderPlayerList(this.gameStateManager.roomState.players);
        setInterval(() => {
            this.renderPlayerList();
        }, 1000);
    }

    private createPlayerListUI(): void {
        this.playerListElement = document.createElement('div');
        this.playerListElement.id = 'player-list';
        this.playerListElement.style.cssText = `
            position: fixed;
            left: 15px;
            top: 100px;
            font-family: 'Verdana', sans-serif;
            color: rgba(255, 255, 255,1);
            text-shadow: 0px 1px rgba(0, 0, 0,1);
            background: #2221;
            padding: 10px;
            border-radius: 5px;
            pointer-events: none;
        `;

        const headerContainer = document.createElement('div');
        headerContainer.style.cssText = `
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            margin-bottom: 10px;
        `;

        this.title = document.createElement('h5');
        this.title.textContent = ""//`${this.campaignName} ${this.round}/${this.totalRounds}`;
        this.title.style.cssText = `
            font-size: 1.2em;
            margin: 0;
        `;

        const copyButton = document.createElement('button');
        copyButton.textContent = "Copy Invite Link";
        copyButton.style.cssText = `
            padding: 5px;
            border: none;
            border-radius: 5px;
            background-color: #2226;
            color: white;
            cursor: pointer;
            pointer-events: auto;
            transition: background-color 0.3s ease;
        `;
        copyButton.onclick = () => {
            navigator.clipboard.writeText(window.location.href).then(() => {
                const originalText = copyButton.textContent;
                const originalColor = copyButton.style.backgroundColor;

                copyButton.textContent = "Link Copied";
                copyButton.style.backgroundColor = "#ff0";
                copyButton.style.color = "#000";
                setTimeout(() => {
                    copyButton.textContent = originalText;
                    copyButton.style.backgroundColor = originalColor;
                    copyButton.style.color = "white";
                }, 1000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        };

        headerContainer.appendChild(copyButton);
        headerContainer.appendChild(this.title);

        this.playerListElement.appendChild(headerContainer);

        document.body.appendChild(this.playerListElement);
    }

    private setupEventListeners(): void {
        this.socketClient.socket.on('roomStateReceived', (state: RoomState) => {
            this.gameStateManager.roomState = state;
            this.round = state.round;
            this.totalRounds = state.totalRounds;
            this.gameStartTime = state.gameStartTime;
            this.renderPlayerList(state.players);
        });
        this.socketClient.socket.on('playerJoined', (playerData: Player) => {
            this.addPlayer(playerData.id, playerData);
        });
        this.socketClient.socket.on('playerLeft', (playerId: string) => {
            this.removePlayer(playerId);
        });
        this.socketClient.socket.on('ballUpdated', (playerData: Player) => {
            this.renderPlayerList();
        });
        this.socketClient.socket.on('regenAndSharePlatforms', (data: {
            round: number;
            totalRounds: number;
            campaignName: string;
            gameStartTime: number;
        }) => {
            const { round, totalRounds, campaignName, gameStartTime } = data;
            this.round = round;
            this.campaignName = campaignName;
            this.totalRounds = totalRounds;
            this.gameStartTime = gameStartTime;
            this.renderPlayerList();
        });
    }

    private addPlayer(id: string, playerData: Player): void {
        this.renderPlayerList();
    }

    private removePlayer(id: string): void {
        this.renderPlayerList();
    }

    public toggleVisibility(show: boolean): void {
        this.playerListElement.style.display = show ? 'block' : 'none';
    }

    private startTimer(): void {
        setInterval(() => {
            if (!this.gameStateManager.roomState) {
                return;
            }
            let elapsed = Math.floor((Date.now() - this.gameStartTime) / 1000);
            if (this.gameStateManager.roomState.roomSettings.timeRules === "countdown") {
                elapsed = 300 - elapsed;
            }
            const minutes = Math.floor(elapsed / 60);
            const seconds = elapsed % 60;
        }, 1000);
    }

    private calculatePlayerScore(player: Player): number {
        if (!this.gameStateManager.roomState || !this.gameStateManager.roomState.roomSettings) {
            return 0;
        }

        switch (this.gameStateManager.roomState.roomSettings.gameMode) {
            case 'regularGolf':
                return player.scoring.clicks.reduce((a, b) => a + b, 0);
            default:
                return player.scoring.score.reduce((a, b) => a + b, 0);
        }
    }

    private renderPlayerList(): void {
        this.title.textContent = ""//`${this.campaignName} ${this.round}/${this.totalRounds}`;

        while (this.playerListElement.childNodes.length) {
            this.playerListElement.removeChild(this.playerListElement.lastChild);
        }

        const playersLocalClone = { ...this.gameStateManager.roomState.players };
        const sortedPlayers = Object.entries(playersLocalClone).sort((a, b) => {
            const scoreA = this.calculatePlayerScore(a[1]);
            const scoreB = this.calculatePlayerScore(b[1]);

            if (this.gameStateManager.roomState.roomSettings.gameMode === 'regularGolf') {
                return scoreA - scoreB;
            } else {
                return scoreB - scoreA;
            }
        });

        const mobileIcon = `
            <svg width="12" height="18" viewBox="0 0 12 18" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="10" height="16" rx="2" ry="2" fill="none" stroke="currentColor" stroke-width="2"/>
                <circle cx="6" cy="15" r="1" fill="currentColor"/>
            </svg>
        `;
        const desktopIcon = `
            <svg width="18" height="12" viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="10" rx="2" ry="2" fill="none" stroke="currentColor" stroke-width="2"/>
                <line x1="1" y1="8" x2="17" y2="8" stroke="currentColor" stroke-width="2"/>
            </svg>
        `;

        let counter = 1;
        for (const [id, player] of sortedPlayers) {
            const playerElement = document.createElement('div');
            playerElement.style.cssText = `
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            `;

            const leftGroup = document.createElement('div');
            leftGroup.style.cssText = `
                display: flex;
                align-items: center;
                gap: 5px;
            `;

            const colorDot = document.createElement('span');
            colorDot.style.cssText = `
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: ${player.ballColor};
                display: inline-block;
            `;

            if (id === this.socketClient.socket.id) {
                colorDot.classList.add('my-ball');
            }

            const nameSpan = document.createElement('span');
            nameSpan.style.cssText = `
                margin-right: 5px;
            `;
            nameSpan.textContent = `${counter}. ${player.name}`;

            const deviceIcon = document.createElement('span');
            if (player.device) {
                deviceIcon.innerHTML = player.device === 'mobile' ? mobileIcon : desktopIcon;
                deviceIcon.style.fontSize = '0.8em';
            }

            leftGroup.appendChild(colorDot);
            leftGroup.appendChild(deviceIcon);
            leftGroup.appendChild(nameSpan);

            const statsSpan = document.createElement('span');
            statsSpan.style.cssText = `
                font-size: 0.8em;
                text-align: right;
            `;
            let ch = 0;
            if (player.position) {
                ch = Math.max(Math.floor((START_POSITION.y) - player.position.y), 0) || 0;
            }
            const score = this.calculatePlayerScore(player);
            const clicks = player.scoring.clicks ? player.scoring.clicks[this.round] : 0;
            statsSpan.textContent = `${score} (${ch}) [${clicks}]`;

            playerElement.appendChild(leftGroup);
            playerElement.appendChild(statsSpan);
            this.playerListElement.appendChild(playerElement);

            counter++;
        }
    }
}

export default PlayerList;