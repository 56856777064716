if (typeof window === 'undefined') {
    global.window = {}
}
// Canvas dimensions
export const CANVAS_WIDTH = window?.innerWidth <= 800 ? 1200 : window?.innerWidth;
export let CANVAS_HEIGHT = 2700;
export const PLAYABLE_AREA = 700;
export const WALL_THICKNESS = (CANVAS_WIDTH - PLAYABLE_AREA) / 2;
export const CEILING_HEIGHT = 120;
// Game physics
export const GRAVITY = 100;
export const BOUNCE_FACTOR = 1;
export const CYCLE_BACKGROUNDS = true; // Set this to false if you don't want to cycle backgrounds

export const DRAG_CIRCLE_DIAMETER = Math.min(PLAYABLE_AREA, window?.innerWidth) * 0.75;

// Platform properties
export const PLATFORM_SPACING = 100;
export const PLATFORM_WIDTH = 0;
export const PLATFORM_HEIGHT = 10;
export const PLATFORM_COLOR = 0x8B4513;  // Saddle Brown

// Ball properties
export const BALL_RADIUS = 10;
export const BALL_COLOR = 0xFFFFFF;  // White
// Wall properties
// WALL_THICKNESS is defined as whatever the different between the canvas width and 800 is
export const WALL_COLOR = 0x808080;  // Gray


// Floor properties
export const FLOOR_HEIGHT = 250;
export const FLOOR_COLOR = 0x228B22;  // Forest Green

export const START_POSITION = {
    x: PLAYABLE_AREA / 2 + WALL_THICKNESS,
    y: CANVAS_HEIGHT - FLOOR_HEIGHT - BALL_RADIUS - 400
};

// Game settings
export const MAX_STRIKE_FORCE = 20;
export const MIN_STRIKE_FORCE = 1;

// UI settings
export const FONT_FAMILY = 'Arial';
export const FONT_SIZE = {
    SMALL: 16,
    MEDIUM: 24,
    LARGE: 36,
    EXTRA_LARGE: 48
};
export const TEXT_COLOR = 0xFFFFFF;  // White

// Gameplay constants
export const MAX_PLATFORMS = 30;
export const WIN_HEIGHT = CANVAS_HEIGHT - FLOOR_HEIGHT;

// Animation constants
export const CAMERA_LERP = 0.1;
export const TRAIL_LENGTH = 20;

// Sound settings
export const SOUND_VOLUME = 0.5;

// Multiplayer settings
export const MAX_PLAYERS = 4;
export const SYNC_INTERVAL = 100;  // ms

// Debug settings
export const DEBUG_MODE = false;



// Game states
export const GAME_STATES = {
    MENU: 'menu',
    PLAYING: 'playing',
    PAUSED: 'paused',
    GAME_OVER: 'gameOver',
    WIN: 'win'
};

// export const DIFFICULTY = {
//     EASY: 'easy',
//     MEDIUM: 'medium',
//     HARD: 'hard'
// };

// // Difficulty settings
// export const DIFFICULTY_SETTINGS = {
//     [DIFFICULTY.EASY]: {
//         PLATFORM_WIDTH: 250,
//         PLATFORM_SPACING: 80,
//         GRAVITY: 0.4
//     },
//     [DIFFICULTY.MEDIUM]: {
//         PLATFORM_WIDTH: 200,
//         PLATFORM_SPACING: 100,
//         GRAVITY: 250
//     },
//     [DIFFICULTY.HARD]: {
//         PLATFORM_WIDTH: 150,
//         PLATFORM_SPACING: 120,
//         GRAVITY: 0.6
//     }
// };


// Network message types
export const MESSAGE_TYPES = {
    PLAYER_JOINED: 'playerJoined',
    PLAYER_LEFT: 'playerLeft',
    BALL_UPDATE: 'ballUpdate',
    GAME_STATE: 'gameStateManager',
    CHAT_MESSAGE: 'chatMessage',
};

export const UPDATE_INTERVAL = 60; // how many times to push ball updates to server per second
export const UPDATE_ONLY_IF_MOVED = true; // only push updates if the ball has moved

export const SWING_PROFILE = {
    DEFAULT: "DEFAULT", // hit ball again 2 seconds after hitting a surface
    REBOUND: "REBOUND", // hit ball again after hitting a surface
    GENTLEMAN: "GENTLEMAN", // only hit when ball stops
    GOD_MODE: "GOD_MODE", // hit ball as much as you want 
}

export const BOUNCE_SOUND_RATE_LIMIT = 100; // ms

export const SPECIAL_PLATFORM_IDS = {
    LEFT_WALL: 'leftWall',
    RIGHT_WALL: 'rightWall',
    FINISH_LINE: 'finishLine',
    CEILING: 'ceiling',
    FLOOR: 'floor',
    PLATFORM: 'platform',
    COIN: 'coin',
};

// POPUP
export const POPUP_WIDTH = PLAYABLE_AREA - 20;
export const POPUP_HEIGHT = 600;