import { RoomState } from "../shared/sharedTypes";

interface Level {
    campaignName: string;
    roomSettings: {
        levelId: string;
        [key: string]: any;
    };
    platforms: any[];
}

class LevelsList {
    private socketClient: any; // Replace 'any' with the actual type of your socket client
    private levelsListElement: HTMLDivElement | null;
    private currentTab: string;
    private recentLevels: Level[];
    private likedLevels: Level[];
    private importedLevels: Level[];
    private popularLevels: Level[];
    private currentLevel: RoomState | null;
    private importButton: HTMLButtonElement | null;

    constructor(bootstrapRoomState: RoomState, socketClient: any) {
        this.socketClient = socketClient;
        this.levelsListElement = null;
        this.currentTab = 'recent';
        this.recentLevels = [];
        this.likedLevels = [];
        this.importedLevels = [];
        this.popularLevels = [];
        this.currentLevel = null;
        this.importButton = null;
        this.createLevelsListUI();
        this.loadRecentLevels();
        this.loadLikedLevels();
        this.loadImportedLevels();
        this.renderLevelsList();
        this.setupSocketListeners();
    }

    private createLevelsListUI(): void {
        this.levelsListElement = document.createElement('div');
        this.levelsListElement.id = 'levels-list';
        this.levelsListElement.style.cssText = `
            position: fixed;
            left: 10px;
            top: 90px;
            font-family: 'Verdana', sans-serif;
            color: rgba(255, 255, 255,1);
            text-shadow: 0px 1px rgba(0, 0, 0,1);
            background: #2221;
            padding: 10px;
            border-radius: 5px;
            height: 300px;
            overflow-y: auto;
            width: 250px;
        `;

        const tabsContainer = document.createElement('div');
        tabsContainer.style.cssText = `
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        `;

        const tabs = ['⌚', '❤️', '⬇️', '✨'];
        tabs.forEach((tabName, index) => {
            const tab = document.createElement('button');
            tab.textContent = tabName;
            tab.style.cssText = `
                padding: 5px;
                border: none;
                background-color: ${index === 0 ? '#444' : '#222'};
                color: white;
                cursor: ${index === 3 ? 'not-allowed' : 'pointer'};
                flex-grow: 1;
                margin: 0 2px;
            `;
            if (index !== 3) {
                tab.onclick = () => this.switchTab(['recent', 'liked', 'imported'][index]);
            }
            tabsContainer.appendChild(tab);
        });

        this.levelsListElement.appendChild(tabsContainer);

        const likeButton = document.createElement('button');
        likeButton.textContent = '❤ Like Current Level';
        likeButton.style.cssText = `
            margin-top: 10px;
            padding: 5px;
            width: 100%;
            background-color: #4CAF50;
            color: white;
            border: none;
            cursor: pointer;
        `;
        likeButton.onclick = () => this.likeCurrentLevel();
        this.levelsListElement.appendChild(likeButton);

        this.importButton = document.createElement('button');
        this.importButton.textContent = '📋 Import Level from Paste';
        this.importButton.style.cssText = `
            margin-top: 10px;
            padding: 5px;
            width: 100%;
            background-color: #4A90E2;
            color: white;
            border: none;
            cursor: pointer;
            display: none;
        `;
        this.importButton.onclick = () => this.importLevelFromPaste();
        this.levelsListElement.appendChild(this.importButton);

        document.body.appendChild(this.levelsListElement);
    }

    private setupSocketListeners(): void {
        this.socketClient.socket.on('platformsUpdated', (data: RoomState) => {
            this.currentLevel = data;
        });

        this.socketClient.socket.on('roomStateReceived', (data: RoomState) => {
            this.currentLevel = data;
        });
    }

    private loadRecentLevels(): void {
        const recentLevelsJSON = localStorage.getItem('golfup-recentLevels');
        this.recentLevels = recentLevelsJSON ? JSON.parse(recentLevelsJSON) : [];
    }

    private loadLikedLevels(): void {
        const likedLevelsJSON = localStorage.getItem('golfup-likedLevels');
        this.likedLevels = likedLevelsJSON ? JSON.parse(likedLevelsJSON) : [];
    }

    private loadImportedLevels(): void {
        const importedLevelsJSON = localStorage.getItem('golfup-importedLevels');
        this.importedLevels = importedLevelsJSON ? JSON.parse(importedLevelsJSON) : [];
    }

    private renderLevelsList(): void {
        if (!this.levelsListElement) return;

        while (this.levelsListElement.childNodes.length > 3) {
            this.levelsListElement.removeChild(this.levelsListElement.lastChild!);
        }

        let levels: Level[];
        switch (this.currentTab) {
            case 'recent':
                levels = this.recentLevels;
                break;
            case 'liked':
                levels = this.likedLevels;
                break;
            case 'imported':
                levels = this.importedLevels;
                break;
            default:
                levels = [];
        }

        levels.forEach((level, index) => {
            const levelElement = document.createElement('div');
            levelElement.style.cssText = `
                margin-bottom: 5px;
                padding: 5px;
                background-color: #3334;
                border-radius: 3px;
                position: relative;
            `;

            const levelName = document.createElement('span');
            levelName.textContent = level.campaignName || `Level ${index + 1}`;
            levelElement.appendChild(levelName);

            const actionIcons = document.createElement('div');
            actionIcons.style.cssText = `
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                display: none;
            `;

            const icons = [
                { name: '▶️', onClick: () => this.playLevel(level) },
                { name: '📄', onClick: () => this.copyLevel(level) },
                { name: '🗑️', onClick: () => this.deleteLevel(index) }
            ];

            icons.forEach(icon => {
                const iconElement = document.createElement('span');
                iconElement.textContent = icon.name;
                iconElement.style.cssText = `
                    margin-left: 5px;
                    cursor: pointer;
                `;
                iconElement.onclick = icon.onClick;
                actionIcons.appendChild(iconElement);
            });

            levelElement.appendChild(actionIcons);

            levelElement.onmouseenter = () => actionIcons.style.display = 'block';
            levelElement.onmouseleave = () => actionIcons.style.display = 'none';

            this.levelsListElement.appendChild(levelElement);
        });
    }

    private switchTab(tabName: string): void {
        this.currentTab = tabName;
        this.renderLevelsList();
        if (this.importButton) {
            this.importButton.style.display = tabName === 'imported' ? 'block' : 'none';
        }
    }

    private likeCurrentLevel(): void {
        if (this.currentLevel) {
            const levelToLike: Level = {
                campaignName: this.currentLevel.campaignName,
                roomSettings: this.currentLevel.roomSettings,
                platforms: this.currentLevel.platforms
            };

            const existingIndex = this.likedLevels.findIndex(level =>
                level.roomSettings.levelId === levelToLike.roomSettings.levelId
            );

            if (existingIndex === -1) {
                this.likedLevels.unshift(levelToLike);
                if (this.likedLevels.length > 10) {
                    this.likedLevels.pop();
                }
                localStorage.setItem('golfup-likedLevels', JSON.stringify(this.likedLevels));
                alert('Level liked!');
            } else {
                alert('This level is already liked!');
            }

            if (this.currentTab === 'liked') {
                this.renderLevelsList();
            }
        } else {
            alert('No level currently loaded to like!');
        }
    }

    private importLevelFromPaste(): void {
        navigator.clipboard.readText().then(text => {
            try {
                const levelData: Level = JSON.parse(text);
                if (this.isValidLevelData(levelData)) {
                    this.importedLevels.unshift(levelData);
                    if (this.importedLevels.length > 10) {
                        this.importedLevels.pop();
                    }
                    localStorage.setItem('golfup-importedLevels', JSON.stringify(this.importedLevels));
                    alert('Level imported successfully!');
                    if (this.currentTab === 'imported') {
                        this.renderLevelsList();
                    }
                    this.playLevel(levelData);
                } else {
                    alert('Invalid level data format.');
                }
            } catch (error) {
                console.error('Failed to parse level data:', error);
                alert('Failed to parse level data. Make sure you have copied a valid level JSON.');
            }
        }).catch(err => {
            console.error('Failed to read clipboard contents: ', err);
            alert('Failed to read clipboard contents. Please make sure you have copied the level data.');
        });
    }

    private isValidLevelData(data: any): data is Level {
        return data && data.campaignName && data.roomSettings && data.platforms;
    }

    private playLevel(level: Level): void {
        this.socketClient.loadSavedLevel(level);
    }

    private copyLevel(level: Level): void {
        navigator.clipboard.writeText(JSON.stringify(level))
            .then(() => alert('Level copied to clipboard!'))
            .catch(err => {
                console.error('Failed to copy level:', err);
                alert('Failed to copy level to clipboard.');
            });
    }

    private deleteLevel(index: number): void {
        switch (this.currentTab) {
            case 'recent':
                this.recentLevels.splice(index, 1);
                localStorage.setItem('golfup-recentLevels', JSON.stringify(this.recentLevels));
                break;
            case 'liked':
                this.likedLevels.splice(index, 1);
                localStorage.setItem('golfup-likedLevels', JSON.stringify(this.likedLevels));
                break;
            case 'imported':
                this.importedLevels.splice(index, 1);
                localStorage.setItem('golfup-importedLevels', JSON.stringify(this.importedLevels));
                break;
        }
        this.renderLevelsList();
    }
}

export default LevelsList;