import { CameraMode, CameraMotionMode } from '../shared/sharedTypes';
import { CANVAS_WIDTH } from './constants';

const defaultSettings = {
    player: {
        name: 'Player ' + generateHexId(4),
        ballColor: '#FFFFFF',
        controlType: window.innerWidth < CANVAS_WIDTH ? 'click-drag' : '1-click',
        specialMove: 'none',
        forceOffset: 60,
    },
    sound: {
        musicMuted: false,
        sfxMuted: false,
        ambientMuted: false,
    },
    roomSettings: {
        height: 2645,
        gameType: 'competitive',
        gameMode: 'golfUP',
        strikeType: 'ricochet',
        goalType: 'finish-line',
        platforms: {
            "Rectangle": {
                "active": true,
                "density": 10,
                "snap": true
            },
            "Arc": {
                "active": true,
                "density": 4,
                "snap": false
            },
            "Spline": {
                "active": true,
                "density": 4,
                "snap": false
            },
            "BinaryCluster": {
                "active": true,
                "density": 1,
                "snap": false
            }
        }
    },
    gameSettings: {
        backgroundIndex: 23,
        language: "en",
    },
    camera: {
        mode: CameraMode.CENTER_BALL,
        motionMode: CameraMotionMode.LERP,
        spectateAfterFinished: true
    },
};

export default defaultSettings;

function generateHexId(length) {
    let result = '';
    const characters = 'ABCDEF0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function validateAndUpdatePersistedStructure() {
    const savedVersion = localStorage.getItem('golfup-version');
    const currentVersion = __APP_VERSION__;

    let savedSettings = JSON.parse(localStorage.getItem('golfUpSettings') || '{}');
    let needsUpdate = false;

    if (savedVersion !== currentVersion) {
        // console.log(`Version mismatch: saved ${savedVersion}, current ${currentVersion}`);
        needsUpdate = true;

        // Update platforms settings
        if (savedSettings.roomSettings && savedSettings.roomSettings.platforms) {
            // console.log("Updating level-gen algorithm to version " + currentVersion);
            savedSettings.roomSettings.platforms = {...defaultSettings.roomSettings.platforms};
        }

        // Update version
        localStorage.setItem('golfup-version', currentVersion);
    }

    if (!isStructureValid(savedSettings)) {
        // console.log("Resetting state due to new version incompatibility");
        savedSettings = {...defaultSettings};
        needsUpdate = true;
    }

    if (needsUpdate) {
        localStorage.setItem('golfUpSettings', JSON.stringify(savedSettings));
        // console.log("Settings updated to match new version");
    } else {
        // console.log("Settings are up to date");
    }

    return savedSettings;
}

function isStructureValid(settings) {
    return compareObjects(settings, defaultSettings);
}

function compareObjects(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (typeof obj1[key] !== typeof obj2[key]) {
            return false;
        }
        if (typeof obj1[key] === 'object' && obj1[key] !== null) {
            if (!compareObjects(obj1[key], obj2[key])) {
                return false;
            }
        }
    }

    return true;
}