import { Application, Container, Text, TextStyle } from 'pixi.js';
import { CANVAS_WIDTH } from './constants';
import { validateAndUpdatePersistedStructure } from './defaultSettings';
import { toggleFullscreen } from './utils';
import './styles.css';



import SocketClient from './socket-client';
import GameStateManager from './gameStateManager';
import ViewportManager from './viewportManager';
import RenderManager from './renderManager';
import PlatformManager from './platformManager';
import ParticleEffect from './particleEffect';
import BallManager from './ballManager';
import InputManager from './inputManager';
import UIManager from './uiManager';
import PlayerList from './playerList';
import LevelsList from './levelsList';
import WindowsManager from './windowsManager';
import Chat from './chat';
import { initializeLoadingScreen } from './LoadingScreen';
import SoundManager from './sound';
import { GameMode, RoomState } from '../shared/sharedTypes';

const show_fps = false;

class Game {
    private app: Application;
    private gameWorld: Container;
    private matterWorker: Worker;

    public socketClient: SocketClient;
    private gameStateManager: GameStateManager;
    private soundManager: SoundManager;
    private viewportManager: ViewportManager;
    private renderManager: RenderManager;
    private platformManager: PlatformManager;
    private ballManager: BallManager;
    private inputManager: InputManager;
    private uiManager: UIManager;
    private playerList: PlayerList;
    private chat: Chat;

    private fpsText: Text;
    private frameCount: number = 0;
    private lastTime: number = 0;
    private fps: number = 0;
    private physicsFrameCount: number = 0;
    private lastPhysicsTime: number = 0;
    private physicsFps: number = 0;
    bootstrapRoomState: RoomState;

    constructor() { }

    public async init() {
        this.app = new Application();
        this.gameWorld = new Container();

        // Create and set up the Web Worker
        this.matterWorker = new Worker('/matterWorker.js');
        this.matterWorker.onmessage = this.handleWorkerMessage.bind(this);
        this.matterWorker.postMessage({ type: 'init' });

        await this.app.init({
            width: CANVAS_WIDTH,
            height: window.innerHeight,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
            backgroundColor: 0x1099bb,
        });
        document.getElementById('game-container')!.appendChild(this.app.canvas);
        document.getElementById('game-container')!.classList.remove('hidden');
        document.getElementById('settings-toggle')!.classList.remove('hidden');
        this.app.stage.addChild(this.gameWorld);

        // Initialize the appropriate client based on the environment variable
        this.socketClient = new SocketClient(this.bootStrapCallback.bind(this));
    }

    private handleWorkerMessage(event: MessageEvent) {
        if (event.data.type === 'engineUpdated') {
            this.updatePhysicsFPS();
        }
    }

    async bootStrapCallback(initialState: RoomState) {
        this.bootstrapRoomState = initialState;
        await this.initializeGameComponents();
        show_fps && this.setupFPSDisplay();
        this.setupFullscreenHandling();
    }

    private async initializeGameComponents() {
        this.soundManager = new SoundManager();
        this.gameStateManager = new GameStateManager(this.bootstrapRoomState, this.socketClient, this.soundManager);
        this.socketClient.gameStateManager = this.gameStateManager;
        this.viewportManager = new ViewportManager(this.app, this.gameWorld);
        this.renderManager = new RenderManager(this.app, this.gameWorld, this.viewportManager);
        this.platformManager = new PlatformManager(this.gameStateManager, this.matterWorker, this.gameWorld, this.socketClient, this.viewportManager, this.app);
        this.chat = new Chat(this.gameStateManager.roomState, this.socketClient);
        this.ballManager = new BallManager(this.gameStateManager.roomState, this.matterWorker, this.gameWorld, this.socketClient, this.platformManager, this.app, this.gameStateManager, this.chat, this.viewportManager, this.soundManager);
        this.uiManager = new UIManager(this.gameStateManager.roomState, this.app, this.gameStateManager, this.socketClient, this.viewportManager, this.gameWorld, this.soundManager, this.renderManager, this.platformManager);
        this.inputManager = new InputManager(this.app, this.ballManager, this.gameStateManager, this.renderManager, this.gameWorld, this.viewportManager, this.uiManager, this.playerList, this.chat);
        this.playerList = new PlayerList(this.gameStateManager, this.socketClient);

        this.renderManager.signalReady = () => window.loadingScreen.setRenderReady();
        this.platformManager.signalReady = () => window.loadingScreen.setPlatformReady();

        new LevelsList(this.gameStateManager.roomState, this.socketClient);
        new WindowsManager();

        new ParticleEffect(this.app, this.gameWorld);

        this.app.ticker.add(this.update, this);
        this.app.canvas.style.cursor = 'none';

        this.viewportManager.ballManager = this.ballManager;
        this.renderManager.ballManager = this.ballManager;
        this.uiManager.popupManager.inputManager = this.inputManager;
        this.viewportManager.inputManager = this.inputManager;
        this.platformManager.inputManager = this.inputManager;
        this.platformManager.ballManager = this.ballManager;
        this.gameStateManager.platformManager = this.platformManager;
    }



    private setupFPSDisplay() {
        const fpsStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 14,
            fill: 0xFFFFFF,
        });

        this.fpsText = new Text({
            text: 'FPS: 0',
            style: fpsStyle,
        });
        this.fpsText.position.set(window.innerWidth - 100, window.innerHeight - 30);
        this.app.stage.addChild(this.fpsText);
    }

    private setupFullscreenHandling() {
        document.addEventListener('keydown', (e) => {
            if (document.activeElement instanceof HTMLInputElement) {
                return;
            }
            if (e.key === 'f' || e.key === 'F' || e.key === 'Escape') {
                toggleFullscreen();
            }
            if (e.key === 'm' || e.key === 'M') {
                this.soundManager.toggleMute();
            }
        });
    }

    private update = (ticker: any) => {
        this.ballManager.update(ticker.deltaTime);
        this.inputManager.update(ticker.deltaTime);
        this.uiManager.update();
        this.renderManager.update(ticker.deltaTime);
        this.platformManager.update();
        this.viewportManager.update();

        show_fps && this.updateFPS();
    }

    private updateFPS() {
        this.frameCount++;
        const currentTime = performance.now();
        const elapsedTime = currentTime - this.lastTime;

        if (elapsedTime >= 1000) {
            this.fps = Math.round((this.frameCount * 1000) / elapsedTime);
            this.fpsText.text = `Render FPS: ${this.fps} | Physics FPS: ${this.physicsFps}`;
            this.frameCount = 0;
            this.lastTime = currentTime;
        }

        this.fpsText.position.set(window.innerWidth - 250, window.innerHeight - 30);
    }

    private updatePhysicsFPS() {
        this.physicsFrameCount++;
        const currentTime = performance.now();
        const elapsedTime = currentTime - this.lastPhysicsTime;

        if (elapsedTime >= 1000) {
            this.physicsFps = Math.round((this.physicsFrameCount * 1000) / elapsedTime);
            this.physicsFrameCount = 0;
            this.lastPhysicsTime = currentTime;
        }
    }
}

// Main execution
async function main() {
    const game = new Game();
    await game.init();

    window.addEventListener('beforeunload', () => {
        game.socketClient.leaveRoom();
    });
}

validateAndUpdatePersistedStructure();

const playNow = document.getElementById('play-now');
const regularGolf = document.getElementById('regular-golf');
const coinDash = document.getElementById('coindash');

playNow && playNow.addEventListener('click', startMode.bind(null, GameMode.GolfUP));
regularGolf && regularGolf.addEventListener('click', startMode.bind(null, GameMode.RegularGolf));
coinDash && coinDash.addEventListener('click', startMode.bind(null, GameMode.CoinDash));

function startMode(mode: GameMode) {
    window.loadingScreen = initializeLoadingScreen();

    setTimeout(() => {
        document.getElementById('landing-page')!.style.display = 'none';
        const settings = JSON.parse(localStorage.getItem('golfUpSettings')!);
        settings.roomSettings.gameMode = mode;
        localStorage.setItem('golfUpSettings', JSON.stringify(settings));
        window.history.pushState({}, '', `?room=${mode}`);
        main();
    }, 5);
}

// Unregister service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
            registration.unregister();
        }
    });
}