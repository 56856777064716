import { lerp } from './utils';

interface InterpolationData {
    previousPosition: { x: number; y: number };
    targetPosition: { x: number; y: number };
    startTime: number;
    endTime: number;
    velocity: { x: number; y: number };
    isSlowingDown?: boolean;
}

export class AdvancedInterpolation {
    private interpolationData: Map<string, InterpolationData> = new Map();
    private readonly updateInterval: number = 100; // ms between server updates
    private readonly maxExtrapolationTime: number = 100; // ms
    private smoothingFactor: number = 0.3; // Adjust between 0 and 1
    private readonly velocityThreshold: number = 0.1; // Threshold to detect slowing down
    private useRefinedMethod: boolean;

    constructor(useRefinedMethod: boolean = false) {
        this.useRefinedMethod = useRefinedMethod;
    }

    updateBallPosition(ballId: string, newPosition: { x: number; y: number }, timestamp: number): void {
        const currentData = this.interpolationData.get(ballId);
        const now = Date.now();

        if (currentData) {
            const timeDelta = (now - currentData.startTime) / 1000; // Convert to seconds
            const newVelocity = {
                x: (newPosition.x - currentData.previousPosition.x) / timeDelta,
                y: (newPosition.y - currentData.previousPosition.y) / timeDelta
            };

            const speed = Math.sqrt(newVelocity.x ** 2 + newVelocity.y ** 2);
            const isSlowingDown = speed < this.velocityThreshold;

            this.interpolationData.set(ballId, {
                previousPosition: currentData.targetPosition,
                targetPosition: newPosition,
                startTime: now,
                endTime: now + this.updateInterval,
                velocity: newVelocity,
                isSlowingDown
            });
        } else {
            this.interpolationData.set(ballId, {
                previousPosition: newPosition,
                targetPosition: newPosition,
                startTime: now,
                endTime: now + this.updateInterval,
                velocity: { x: 0, y: 0 },
                isSlowingDown: false
            });
        }
    }

    getInterpolatedPosition(ballId: string, renderTimestamp: number): { x: number; y: number } | null {
        const data = this.interpolationData.get(ballId);
        if (!data) return null;

        return this.useRefinedMethod ? 
            this.getRefinedInterpolatedPosition(data, renderTimestamp) :
            this.getOriginalInterpolatedPosition(data, renderTimestamp);
    }

    private getOriginalInterpolatedPosition(data: InterpolationData, renderTimestamp: number): { x: number; y: number } {
        const { previousPosition, targetPosition, startTime, endTime, velocity } = data;
        const now = Date.now();

        // If we're past the end time, extrapolate
        if (now > endTime) {
            const extrapolationTime = Math.min(now - endTime, this.maxExtrapolationTime) / 1000; // Convert to seconds
            return {
                x: targetPosition.x + velocity.x * extrapolationTime,
                y: targetPosition.y + velocity.y * extrapolationTime
            };
        }

        // Otherwise, interpolate
        const t = Math.min((now - startTime) / (endTime - startTime), 1);
        const smoothT = this.smoothstep(t);

        return {
            x: lerp(previousPosition.x, targetPosition.x, smoothT * this.smoothingFactor),
            y: lerp(previousPosition.y, targetPosition.y, smoothT * this.smoothingFactor)
        };
    }

    private getRefinedInterpolatedPosition(data: InterpolationData, renderTimestamp: number): { x: number; y: number } {
        const { previousPosition, targetPosition, startTime, endTime, velocity, isSlowingDown } = data;
        const now = Date.now();

        // If we're past the end time and not slowing down, extrapolate
        if (now > endTime && !isSlowingDown) {
            const extrapolationTime = Math.min(now - endTime, this.maxExtrapolationTime) / 1000;
            return {
                x: targetPosition.x + velocity.x * extrapolationTime,
                y: targetPosition.y + velocity.y * extrapolationTime
            };
        }

        // If we're slowing down or within the update interval, use a more conservative interpolation
        const t = Math.min((now - startTime) / (endTime - startTime), 1);
        const smoothT = this.refinedSmoothstep(t);

        let interpolatedX, interpolatedY;

        if (isSlowingDown) {
            // Use a more dampened approach when slowing down
            interpolatedX = this.dampedInterpolation(previousPosition.x, targetPosition.x, smoothT * this.smoothingFactor);
            interpolatedY = this.dampedInterpolation(previousPosition.y, targetPosition.y, smoothT * this.smoothingFactor);
        } else {
            interpolatedX = lerp(previousPosition.x, targetPosition.x, smoothT * this.smoothingFactor);
            interpolatedY = lerp(previousPosition.y, targetPosition.y, smoothT * this.smoothingFactor);
        }

        return { x: interpolatedX, y: interpolatedY };
    }

    private smoothstep(t: number): number {
        // Smoothstep function for smoother acceleration and deceleration
        return t * t * (3 - 2 * t);
    }

    private refinedSmoothstep(t: number): number {
        // Improved smoothstep function for even smoother acceleration and deceleration
        return t * t * t * (t * (t * 6 - 15) + 10);
    }

    private dampedInterpolation(start: number, end: number, t: number): number {
        // This function provides a more gradual approach to the target when slowing down
        const distance = end - start;
        return start + distance * (1 - Math.exp(-4 * t));
    }

    setInterpolationMethod(useRefinedMethod: boolean): void {
        this.useRefinedMethod = useRefinedMethod;
    }

    setSmoothingFactor(factor: number): void {
        this.smoothingFactor = Math.max(0, Math.min(1, factor)); // Clamp between 0 and 1
    }
}

export const advancedInterpolation = new AdvancedInterpolation();