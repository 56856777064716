import * as PIXI from 'pixi.js';
export const BALL_UPDATE_RATE = 10; // 100ms
export type timestamp = number;


export type BallId = string;

export interface BallData {
    id: BallId;
    position: { x: number; y: number };
    velocity: { x: number; y: number };
    angle: number;
    graphics: PIXI.Container;
    inMotion: boolean;
    highestHeight: number;
}


export enum GameMode {
    GolfUP = "golfUP", // unlimited shots, first to finish wins
    RegularGolf = 'regularGolf', // 0-14 shots, 4 minute rounds, 8 rounds
    CoinDash = "coinDash", // (no goal) pick up as many coins before time is over, get high as possible for a score multiplier!
    BattleGolf = "battleGolf", // 0-14 shots, 4 minute rounds, 8 rounds, powerups
    HorseBall = "horseBall", // one person sets par, others try to match it or are eliminated
    Knockout = "knockout", // last person to finish each round is eliminated
    LuckyShot = "luckyShot", // 1 shot per round, closest to the hole wins
    TrickShot = "trickShot", // 1 shot per round, whoever hits the most highlighted targets wins
    HoleInOne = "holeInOne", // really quick rounds, first to get a hole in one wins
    EscapeBall = "escapeBall", // (no goal) find every switch with your friends before you can escape!
    Upswing = "upswing", // climbing via freeze repel powers together
    Chained = "chained", // they say you shouldn't climb without a harness, but they didn't mean this!
    Tower = "tower", // climb the tower as high as you can before time runs out
}

export interface GameModeConfig {
    timePerRound: number;
    timeBetweenRounds: number;
    timeAtEndOfGame: number;
    rounds: number;
}

export enum GameType {
    Competitive = 'competitive',
    Campaign = 'campaign'
}

export enum StrikeType {
    Ricochet = 'ricochet',
    Golf = 'golf',
    RicochetLimitless = 'ricochet-limitless',
    DoubleJump = 'double-jump',
    TickTock = 'tick-tock',
    GodMode = 'god-mode'
}

export enum GoalType {
    FinishLine = 'finishLine',
    Bucket = 'bucket',
}

export interface RoomState {
    players: { [key: string]: Player };
    roomSettings: RoomSettings;
    round: number;
    totalRounds: number;
    roundDuration: number;
    timeUntilNextRound: number;
    gameStartTime: number;
    roundStartTime: number;
}
export interface RoomSettings {
    levelId: string;
    height: number;
    gameType: GameType;
    gameMode: GameMode;
    strikeType: StrikeType;
    goalType: GoalType;
    timeRules: 'countdown' | 'countup';
}

export enum DeviceType {
    Mobile = 'mobile',
    Desktop = 'desktop',
}
export interface Player {
    id: string;
    name: string;
    ballColor: string;
    device?: DeviceType;
    position?: { x: number; y: number };
    angle?: number;
    inMotion: boolean;
    highestHeight: number;
    hasHitTheGoal: boolean;
    joinedAt: timestamp;
    scoring: ScoreData;
    initialPosition?: { x: number; y: number };
    strikePosition?: { x: number; y: number };
    strikePower?: number;
}

export interface BallUpdate {
    roomId: RoomId;
    position: { x: number; y: number };
    velocity: { x: number; y: number };
    inMotion: boolean;
    initialPosition: { x: number; y: number };
    highestHeight: number;
    strikePosition?: { x: number; y: number };
    strikePower?: number;
    strike?: boolean;
    angle: number;
    clicks?: number;
}

export interface ScoreData {
    score: number[];
    coins: number[];
    clicks: number[];
    hx: number[];
    ttg: number[];
}

export type RoomId = string;
export type PlayerId = string;
export type LevelId = string;
export type Time = number;

export enum CameraMode {
    CENTER_BALL = 'CENTER_BALL',
    KEEP_BALL_IN_VIEW = 'KEEP_BALL_IN_VIEW',
    FREE = 'FREE',
    SPECTATOR = 'SPECTATOR',
}

export enum CameraMotionMode {
    INSTANT = 'INSTANT',
    LERP = 'LERP',
}
