import defaultSettings from './defaultSettings';
import { toggleFullscreen } from './utils';
import { CameraMode, CameraMotionMode } from '../shared/sharedTypes';

interface GameMode {
    name: string;
    description: string;
    type: 'competitive' | 'cooperative';
}

const gameModes: Record<string, GameMode> = {
    golfUP: {
        name: 'golfUP',
        description: 'Unlimited shots, first to finish wins',
        type: 'competitive',
    },
    regularGolf: {
        name: 'Regular Golf',
        description: '0-14 shots, 4 minute rounds, 8 rounds',
        type: 'competitive',
    },
    coinDash: {
        name: 'Coin Dash',
        description: 'Pick up as many coins before time is over, get high as possible for a score multiplier!',
        type: 'competitive',
    },
    keysPlease: {
        name: 'Escape Ball',
        description: 'Find every switch with your friends before you can escape!',
        type: 'cooperative',
    },
};

export default class PopupManager {
    private game: any;
    private socketClient: any;
    private renderManager: any;
    private soundManager: any;
    private popup: HTMLElement;
    private toggleButton: HTMLElement;
    private platformManager: any;
    private open: boolean;
    private viewportManager: any;
    private settings: any;
    private fullscreenButton: HTMLButtonElement | null;
    private isFullscreen: boolean;
    private inputManager: any;

    constructor(game: any, socketClient: any, renderManager: any, soundManager: any, platformManager: any, viewportManager: any) {
        this.game = game;
        this.socketClient = socketClient;
        this.renderManager = renderManager;
        this.soundManager = soundManager;
        this.popup = document.getElementById('settings-popup')!;
        this.toggleButton = document.getElementById('settings-toggle')!;
        this.platformManager = platformManager;
        this.open = false;
        this.viewportManager = viewportManager;
        this.settings = this.loadSettings();

        this.initializeUI();
        this.addEventListeners();

        this.fullscreenButton = null;
        this.isFullscreen = false;
        this.inputManager = undefined;
    }

    private loadSettings(): any {
        const savedSettings = localStorage.getItem('golfUpSettings');
        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings);
            if (parsedSettings) {
                return parsedSettings;
            }
        }

        localStorage.setItem('golfUpSettings', JSON.stringify(defaultSettings));
        return defaultSettings;
    }

    private saveSettings(): void {
        localStorage.setItem('golfUpSettings', JSON.stringify(this.settings));
    }

    private initializeUI(): void {
        // Player settings
        (document.getElementById('player-name') as HTMLInputElement).value = this.settings.player.name;
        (document.getElementById('ball-color') as HTMLInputElement).value = this.settings.player.ballColor;
        (document.getElementById('control-type') as HTMLSelectElement).value = this.settings.player.controlType;
        // (document.getElementById('special-move') as HTMLSelectElement).value = this.settings.player.specialMove;

        // Sound settings
        this.updateSoundToggle('music', !this.settings.sound.musicMuted);
        this.updateSoundToggle('ambient', !this.settings.sound.ambientMuted);
        this.updateSoundToggle('sfx', !this.settings.sound.sfxMuted);

        // Room settings
        // (document.getElementById('room-height') as HTMLInputElement).value = this.settings.roomSettings.height.toString();
        // (document.getElementById('strike-type') as HTMLSelectElement).value = this.settings.roomSettings.strikeType;
        (document.getElementById('goal-type') as HTMLSelectElement).value = this.settings.roomSettings.goalType;

        // Camera settings
        (document.getElementById('camera-mode') as HTMLSelectElement).value = this.settings.camera.mode;
        (document.getElementById('camera-motion-mode') as HTMLSelectElement).value = this.settings.camera.motionMode;
        (document.getElementById('spectate-after-finished') as HTMLInputElement).checked = this.settings.camera.spectateAfterFinished;

        const currentCategory = this.settings.roomSettings.gameType;
        if (currentCategory === 'competitive') {
            this.showCompetitiveModes();
        } else {
            this.showCooperativeModes();
        }

        // Platform settings
        this.initializePlatformSettings();

        this.createFullscreenButton();
    }

    private createFullscreenButton(): void {
        this.fullscreenButton = document.querySelector("#fullscreen-button") as HTMLButtonElement;
        this.fullscreenButton.addEventListener('click', () => {
            toggleFullscreen();
        });

    }


    private updateControlType(newControlType: string): void {
        this.settings.player.controlType = newControlType;
        this.saveSettings();

        this.inputManager.setupControlScheme(newControlType);
        this.renderManager.setupControlScheme(newControlType);
    }

    private initializePlatformSettings(): void {
        const platformSettingsContainer = document.getElementById('platform-settings');
        if (!platformSettingsContainer) return;

        platformSettingsContainer.innerHTML = '';

        for (const [type, settings] of Object.entries(this.settings.roomSettings.platforms)) {
            const amount = this.settings.roomSettings.platforms[type].amount ? ` (amount: ${this.settings.roomSettings.platforms[type].amount})` : '';
            const platformHtml = `
                <div>
                    <h4 class="text-lg font-medium mb-2">${type} Platforms</h4>
                    <div class="space-y-2">
                        <label class="inline-flex items-center">
                            <input type="checkbox" id="${type.toLowerCase()}-active" class="platform-active form-checkbox h-5 w-5 text-blue-600" ${(settings as any).active ? 'checked' : ''}>
                            <span class="ml-2 text-gray-700">Active</span>
                        </label>
                        <div>
                            <label for="${type.toLowerCase()}-density" class="block text-sm font-medium text-gray-700" >Density (amount 1-10)<span id="${type.toLowerCase()}-amount">${amount}</span></label>
                            <input type="range" min="1" max="10" id="${type.toLowerCase()}-density" class="platform-density w-full" value="${(settings as any).density}">
                        </div>
                        <label class="inline-flex items-center">
                            <input type="checkbox" id="${type.toLowerCase()}-snap" class="platform-snap form-checkbox h-5 w-5 text-blue-600" ${(settings as any).snap ? 'checked' : ''}>
                            <span class="ml-2 text-gray-700">Snap to Sides</span>
                        </label>
                    </div>
                </div>
            `;
            platformSettingsContainer.insertAdjacentHTML('beforeend', platformHtml);
        }
    }

    private updateSoundToggle(type: string, isOn: boolean): void {
        const button = document.getElementById(`toggle-${type}`);
        button?.classList.toggle('bg-blue-500', isOn);
        button?.classList.toggle('text-white', isOn);
    }

    private addEventListeners(): void {
        this.toggleButton.addEventListener('click', () => this.togglePopup());
        document.getElementById('close-popup')?.addEventListener('click', () => this.togglePopup());

        window.addEventListener('click', (e) => {
            if (e.target === this.popup) this.togglePopup();
        });
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') this.togglePopup();
        });

        document.getElementById('create-public-game')?.addEventListener('click', () => this.createPublicGame());
        document.getElementById('vote-restart')?.addEventListener('click', () => this.voteRestartGame());
        document.getElementById('cycle-background')?.addEventListener('click', () => this.cycleBackground());

        document.getElementById('toggle-music')?.addEventListener('click', () => this.toggleMusic());
        document.getElementById('toggle-ambient')?.addEventListener('click', () => this.toggleAmbient());
        document.getElementById('toggle-sfx')?.addEventListener('click', () => this.toggleSFX());
        document.getElementById('skip-track')?.addEventListener('click', () => this.skipTrack());

        document.getElementById('player-name')?.addEventListener('input', (e) => this.updateSetting('player', 'name', (e.target as HTMLInputElement).value));
        document.getElementById('ball-color')?.addEventListener('input', (e) => this.updateSetting('player', 'ballColor', (e.target as HTMLInputElement).value));
        document.getElementById('control-type')?.addEventListener('change', (e) => {
            this.updateControlType((e.target as HTMLSelectElement).value);
        });

        document.getElementById('camera-mode')?.addEventListener('change', (e) =>
            this.updateCameraSetting('mode', (e.target as HTMLSelectElement).value as CameraMode));
        document.getElementById('camera-motion-mode')?.addEventListener('change', (e) =>
            this.updateCameraSetting('motionMode', (e.target as HTMLSelectElement).value as CameraMotionMode));
        document.getElementById('spectate-after-finished')?.addEventListener('change', (e) =>
            this.updateSetting('camera', 'spectateAfterFinished', (e.target as HTMLInputElement).checked));

        document.getElementById('special-move')?.addEventListener('change', (e) => this.updateSetting('player', 'specialMove', (e.target as HTMLSelectElement).value));

        document.getElementById('room-height')?.addEventListener('input', (e) => this.updateSetting('roomSettings', 'height', parseInt((e.target as HTMLInputElement).value)));
        document.getElementById('strike-type')?.addEventListener('change', (e) => this.updateSetting('roomSettings', 'strikeType', (e.target as HTMLSelectElement).value));
        document.getElementById('goal-type')?.addEventListener('change', (e) => this.updateSetting('roomSettings', 'goalType', (e.target as HTMLSelectElement).value));

        for (const type of Object.keys(this.settings.roomSettings.platforms)) {
            document.getElementById(`${type.toLowerCase()}-active`)?.addEventListener('change', (e) => this.updatePlatformSetting(type, 'active', (e.target as HTMLInputElement).checked));
            document.getElementById(`${type.toLowerCase()}-density`)?.addEventListener('input', (e) => {
                const el = document.getElementById(`${type.toLowerCase()}-amount`)
                el.textContent = e.target.value;
                this.updatePlatformSetting(type, 'density', parseInt((e.target as HTMLInputElement).value))
            });
            document.getElementById(`${type.toLowerCase()}-snap`)?.addEventListener('change', (e) => this.updatePlatformSetting(type, 'snap', (e.target as HTMLInputElement).checked));
        }

        document.getElementById('regenerate-level')?.addEventListener('click', () => this.regenerateLevel());
        document.getElementById('reset-defaults')?.addEventListener('click', () => {
            this.settings.roomSettings = defaultSettings.roomSettings;
            this.saveSettings();
            this.initializeUI();
        });

        document.getElementById('show-compete')?.addEventListener('click', () => this.showCompetitiveModes());
        document.getElementById('show-coop')?.addEventListener('click', () => this.showCooperativeModes());
        document.getElementById('game-mode')?.addEventListener('change', (e) => this.updateGameMode((e.target as HTMLSelectElement).value));
    }

    private togglePopup(): void {
        this.popup.classList.toggle('hidden');
        this.open = !this.open;
    }

    private updateSetting(category: string, key: string, value: any): void {
        this.settings[category][key] = value;
        this.saveSettings();
    }

    private updatePlatformSetting(type: string, key: string, value: any): void {
        this.settings.roomSettings.platforms[type][key] = value;
        this.saveSettings();
    }

    private toggleMusic(): void {
        this.settings.sound.musicMuted = !this.settings.sound.musicMuted;
        this.soundManager.setMusicMuted(this.settings.sound.musicMuted);
        this.updateSoundToggle('music', !this.settings.sound.musicMuted);
        this.saveSettings();
    }

    private toggleAmbient(): void {
        this.settings.sound.ambientMuted = !this.settings.sound.ambientMuted;
        this.soundManager.setAmbientMuted(this.settings.sound.ambientMuted);
        this.updateSoundToggle('ambient', !this.settings.sound.ambientMuted);
        this.saveSettings();
    }

    private toggleSFX(): void {
        this.settings.sound.sfxMuted = !this.settings.sound.sfxMuted;
        this.soundManager.setSFXMuted(this.settings.sound.sfxMuted);
        this.updateSoundToggle('sfx', !this.settings.sound.sfxMuted);
        this.saveSettings();
        if (!this.settings.sound.sfxMuted) {
            this.soundManager.playPutSound(3);
        }
    }

    private skipTrack(): void {
        this.soundManager.skipTrack();
    }

    private createPublicGame(): void {
        // Implement public game creation logic
    }

    private voteRestartGame(): void {
        this.socketClient.socket.emit('voteRestartGame', this.socketClient.roomId);
    }

    private cycleBackground(): void {
        this.renderManager.loadNextBackground();
    }

    private regenerateLevel(): void {
        this.platformManager.regenAndSharePlatforms();
    }

    private showCompetitiveModes(): void {
        const selectElement = document.getElementById('game-mode') as HTMLSelectElement;
        selectElement.innerHTML = '';
        Object.entries(gameModes)
            .filter(([_, mode]) => mode.type === 'competitive')
            .forEach(([key, mode]) => {
                const option = document.createElement('option');
                option.value = key;
                option.textContent = `${mode.name} - ${mode.description}`;
                selectElement.appendChild(option);
            });
        selectElement.value = this.settings.roomSettings.gameMode;
    }

    private showCooperativeModes(): void {
        const selectElement = document.getElementById('game-mode') as HTMLSelectElement;
        selectElement.innerHTML = '';
        Object.entries(gameModes)
            .filter(([_, mode]) => mode.type === 'cooperative')
            .forEach(([key, mode]) => {
                const option = document.createElement('option');
                option.value = key;
                option.textContent = `${mode.name} - ${mode.description}`;
                selectElement.appendChild(option);
            });
        selectElement.value = this.settings.roomSettings.gameMode;
    }

    private updateGameMode(mode: string): void {
        this.settings.roomSettings.gameMode = mode;
        this.saveSettings();
    }

    private updateCameraSetting(setting: 'mode' | 'motionMode', value: CameraMode | CameraMotionMode): void {
        if (setting === 'mode') {
            this.viewportManager.setCameraMode(value as CameraMode);
        } else if (setting === 'motionMode') {
            this.viewportManager.setCameraMotionMode(value as CameraMotionMode);
        }
        this.settings.camera[setting] = value;
        this.saveSettings();
    }

    private updateSpectatorSetting(value: boolean): void {
        this.viewportManager.setSpectateAfterFinished(value);
        this.settings.camera.spectateAfterFinished = value;
        this.saveSettings();
    }

    public update(): void {
        // This method is left empty as per the original JavaScript code
    }

    public setInputManager(inputManager: any): void {
        this.inputManager = inputManager;
    }

    // You might want to add more public methods here if needed to interact with PopupManager from outside

}
