import { Container } from 'pixi.js';

/**
 * Calculate the force of a strike based on the distance between two points
 */
export function calculateForce(x1: number, y1: number, x2: number, y2: number, flip: boolean = false, forceOffset: number = 10): number {
    const dx = x2 - x1;
    const dy = y2 - y1;
    let distance = Math.sqrt(dx * dx + dy * dy) - forceOffset;

    // Ensure the distance is not negative
    distance = Math.max(0, distance);

    const maxDistance = 165;
    const clampedDistance = Math.min(distance, maxDistance);

    const normalizedDistance = clampedDistance / maxDistance;

    const minForce = 0.002;
    const maxForce = 0.04;

    if (flip) {
        return maxForce - (maxForce - minForce) * normalizedDistance;
    }
    return minForce + (maxForce - minForce) * normalizedDistance;
}

/**
 * Check if a ball is stationary
 */
export function isBallStationary(ball: Matter.Body): boolean {
    const velocityThreshold = 0.1;
    return Math.abs(ball.velocity.x) < velocityThreshold && Math.abs(ball.velocity.y) < velocityThreshold;
}

/**
 * Check if a point is inside a circle
 */
export function isPointInCircle(x: number, y: number, circleX: number, circleY: number, radius: number): boolean {
    const dx = x - circleX;
    const dy = y - circleY;
    return dx * dx + dy * dy <= radius * radius;
}

/**
 * Linear interpolation between two values
 */
export function lerp(start: number, end: number, t: number): number {
    return start + (end - start) * t;
}

/**
 * Clamp a value between a minimum and maximum
 */
export function clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(value, max));
}

/**
 * Generate a random integer between min and max (inclusive)
 */
export function randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Convert degrees to radians
 */
export function degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
}

/**
 * Convert radians to degrees
 */
export function radiansToDegrees(radians: number): number {
    return radians * (180 / Math.PI);
}

/**
 * Calculate the distance between two points
 */
export function distance(x1: number, y1: number, x2: number, y2: number): number {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
}

/**
 * Check if two circles are colliding
 */
export function circleCollision(x1: number, y1: number, r1: number, x2: number, y2: number, r2: number): boolean {
    return distance(x1, y1, x2, y2) <= r1 + r2;
}

/**
 * Format a number as a time string (MM:SS)
 */
export function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

/**
 * Shuffle an array in place using the Fisher-Yates algorithm
 */
export function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

/**
 * Deep clone an object
 */
export function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

/**
 * Generate a unique ID
 */
export function generateUniqueId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * Ease in out quad function
 */
export function easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
}

export function slerp(start: number, end: number, t: number): number {
    return start + t * (end - start);
}

/**
 * Shake the camera by moving the stage around
 */
export function cameraShake(stage: Container, intensity: number = 4, duration: number = 120): void {
    const startTime = Date.now();

    function shake() {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < duration) {
            stage.x = (Math.random() - 0.5) * intensity;
            stage.y = (Math.random() - 0.5) * intensity;
            requestAnimationFrame(shake);
        } else {
            stage.x = 0;
            stage.y = 0;
        }
    }

    shake();
}

export function toggleFullscreen(): void {
    if (!document.fullscreenElement) {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if ((document.documentElement as any).mozRequestFullScreen) { // Firefox
            (document.documentElement as any).mozRequestFullScreen();
        } else if ((document.documentElement as any).webkitRequestFullscreen) { // Chrome, Safari and Opera
            (document.documentElement as any).webkitRequestFullscreen();
        } else if ((document.documentElement as any).msRequestFullscreen) { // IE/Edge
            (document.documentElement as any).msRequestFullscreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) { // Firefox
            (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) { // Chrome, Safari and Opera
            (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) { // IE/Edge
            (document as any).msExitFullscreen();
        }
    }
}

export function isFullscreen(): boolean {
    return !!(document.fullscreenElement || (document as any).mozFullScreen || (document as any).webkitIsFullScreen || (document as any).msFullscreenElement);
}

export function detectDevice(): 'mobile' | 'desktop' {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
        return 'mobile';
    }
    // kaiOS, chromebook check: 
    if (/CrOS/i.test(ua) || /KaiOS/i.test(ua)) {
        return 'mobile';
    }

    return 'desktop';
}


export function checkIfMobile(): boolean {
    const mobileKeywords = [
        'Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone',
        'Opera Mini', 'IEMobile', 'Mobile', 'Tablet', 'Silk', 'Kindle', 'Playbook',
        'BB10', 'Nokia', 'Samsung', 'LG', 'Huawei', 'Xiaomi', 'Oppo', 'Vivo', 'Sony',
        'HTC', 'Motorola', 'Lenovo', 'ZTE', 'Alcatel', 'Asus', 'OnePlus', 'Realme'
    ];
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return mobileKeywords.some(keyword => userAgent.indexOf(keyword) > -1);
}