import GameStateManager from '../gameStateManager';
import { RoomState, GameMode, Player } from '../../shared/sharedTypes';
import { calculateCoinDashScore } from '../../shared/utils';
import { checkIfMobile } from '../utils';

export default class GameModeUI {
    private socketClient: any;
    private gameStateManager: GameStateManager;
    private roomState: RoomState;
    private container: HTMLDivElement;
    private updateInterval: number;
    private isMobile: boolean;

    private uiConfig = {
        gameMode: { y: 0, labelX: -50, valueX: 50 },
        time: { y: 20, labelX: -50, valueX: 50 },
        par: { y: 50, labelX: -50, valueX: 50 },
        coins: { y: 50, labelX: -50, valueX: 50 },
        hx: { y: 70, labelX: -50, valueX: 50 },
        score: { y: 90, labelX: -50, valueX: 50 },
    };

    constructor(socketClient: any, gameStateManager: GameStateManager, initialRoomState: RoomState) {
        this.socketClient = socketClient;
        this.gameStateManager = gameStateManager;
        this.roomState = initialRoomState;
        this.container = document.createElement('div');
        this.container.className = 'game-mode-ui';
        this.isMobile = checkIfMobile();
        this.render();
        this.updateInterval = window.setInterval(() => this.update(), 1000 / 60); // 60 FPS update
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    private handleResize(): void {
        const wasMobile = this.isMobile;
        this.isMobile = checkIfMobile();
        if (wasMobile !== this.isMobile) {
            this.render(); // Re-render if the layout needs to change
        }
    }

    private render(): void {
        const style = document.createElement('style');
        style.textContent = `
            .game-mode-ui {
                font-size: 1.2em;
                position: fixed;
                background-color: ${this.isMobile ? "#FFF7":""};
                color: white;
                user-select: none;
                width: 100%;
                ${this.isMobile 
                    ? 'bottom: 0px; left: 0; display: flex; justify-content: space-around; align-items: center;' 
                    : 'top: 5px; left: 50%; width: 300px; height: 300px;'}
            }
            .game-mode-ui > div {
                ${this.isMobile ? '' : 'position: absolute;'}
            }
            .label, .value {
                ${this.isMobile 
                    ? 'display: block; text-align: center;' 
                    : 'position: absolute; transform: translateX(-50%); white-space: nowrap;'}
            }
            .label {
                text-align: ${this.isMobile ? "center" : "right"};
                ${this.isMobile ? 'font-size: 0.8em; margin-bottom: 2px;' : ''}
            }
            .value {
                text-align: ${this.isMobile ? "center" : "left"};
                ${this.isMobile ? 'font-size: 1em;' : ''}
            }
            ${this.isMobile ? '.mode-info { display: none; }' : ''}
        `;
        document.head.appendChild(style);

        this.container.innerHTML = `
            <div class="mode-info">
                <span class="label mode-text text-2xl font-extrabold text-white stroke-1"></span>
            </div>
            <div class="time-info">
                <span class="label">TIME</span>
                <span class="value time-value"></span>
            </div>
            <div class="par-info">
                <span class="label">PAR</span>
                <span class="value par-value"></span>
            </div>
            <div class="coins-info coins">
                <span class="label">COINS</span>
                <span class="value coins-value"></span>
            </div>
            <div class="hx-info hx">
                <span class="label">HX</span>
                <span class="value hx-value"></span>
            </div>
            <div class="score-info score">
                <span class="label">SCORE</span>
                <span class="value score-value"></span>
            </div>
        `;

        if (!this.isMobile) {
            Object.entries(this.uiConfig).forEach(([key, config]) => {
                const element = this.container.querySelector(`.${key}-info`);
                if (element) {
                    element.style.top = `${config.y}px`;
                    const label = element.querySelector('.label');
                    const value = element.querySelector('.value');
                    if (label) label.style.left = `${config.labelX}px`;
                    if (value) value.style.left = `${config.valueX}px`;
                }
            });
        }

        document.body.appendChild(this.container);
    }

    private update(): void {
        if (this.gameStateManager.isroundEnded || this.gameStateManager.isGameOver) return;

        const currentRoomState = this.gameStateManager.roomState;
        this.roomState = currentRoomState;
        const timeRemaining = Math.floor(this.gameStateManager?.getRoundTimeRemaining() / 1000);
        const currentPlayer = this.gameStateManager.getCurrentPlayer();
        const round = currentRoomState.round;
        const gameMode = currentRoomState.roomSettings.gameMode;

        this.updateModeInfo();
        this.updateTimeInfo(timeRemaining);
        this.updateAdditionalInfo(gameMode, currentPlayer, round);
    }

    private updateModeInfo(): void {
        if (this.isMobile) return; // Don't update mode info on mobile
        const modeText = this.container.querySelector('.mode-text');
        if (modeText) {
            modeText.textContent = `${this.roomState.roomSettings.gameMode.toUpperCase()} (${this.roomState.round + 1}/${this.roomState.totalRounds})`;
        }
    }

    private updateTimeInfo(timeRemaining: number): void {
        const timeValue = this.container.querySelector('.time-value');
        if (timeValue) {
            timeValue.textContent = timeRemaining.toString();
            timeValue.className = `value time-value ${timeRemaining <= 20 ? 'highlight' : ''}`;
        }
    }

    private updateAdditionalInfo(gameMode: GameMode, currentPlayer: Player | null, round: number): void {
        const parValue = this.container.querySelector('.par-value');
        const coinsValue = this.container.querySelector('.coins-value');
        const hxValue = this.container.querySelector('.hx-value');
        const scoreValue = this.container.querySelector('.score-value');

        const parInfo = this.container.querySelector('.par-info');
        const coinsInfo = this.container.querySelector('.coins-info');
        const hxInfo = this.container.querySelector('.hx-info');
        const scoreInfo = this.container.querySelector('.score-info');

        switch (gameMode) {
            case 'regularGolf':
                const par = currentPlayer?.scoring.clicks[round] || 0;
                if (parValue) parValue.textContent = par.toString();
                if (parInfo) parInfo.style.display = 'block';
                if (coinsInfo) coinsInfo.style.display = 'none';
                if (hxInfo) hxInfo.style.display = 'none';
                if (scoreInfo) scoreInfo.style.display = 'none';
                break;
            case 'coinDash':
                const coins = currentPlayer?.scoring?.coins[round] || 0;
                const height = currentPlayer?.highestHeight || 0;
                const score = calculateCoinDashScore(coins, height);
                if (coinsValue) coinsValue.textContent = coins.toString();
                if (hxValue) hxValue.textContent = height.toFixed(0);
                if (scoreValue) scoreValue.textContent = score.toString();
                if (parInfo) parInfo.style.display = 'none';
                if (coinsInfo) coinsInfo.style.display = 'block';
                if (hxInfo) hxInfo.style.display = 'block';
                if (scoreInfo) scoreInfo.style.display = 'block';
                break;
            case 'golfUP':
                if (coinsInfo) coinsInfo.style.display = 'none';
                if (hxInfo) hxInfo.style.display = 'none';
                if (scoreInfo) scoreInfo.style.display = 'none';
                if (parInfo) parInfo.style.display = 'none';
                break;
        }
    }

    public destroy(): void {
        clearInterval(this.updateInterval);
        window.removeEventListener('resize', this.handleResize.bind(this));
        if (this.container && this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
        }
    }
}