export class LoadingScreen {
    private loadingElement: HTMLElement;
    private renderReady: boolean = false;
    private platformReady: boolean = false;

    constructor() {
        this.createLoadingElement();
        this.showLoadingScreen();
    }

    private createLoadingElement(): void {
        document.querySelector('body').style.overflow = 'hidden';
        this.loadingElement = document.createElement('div');
        this.loadingElement.id = 'loading-screen';
        this.loadingElement.innerHTML = `
            <div class="loading-content">
                <img src="/images/logo.png" alt="Logo" class="logo-img">
                <div class="loading-text">Loading...</div>
            </div>
        `;

        const style = document.createElement('style');
        style.textContent = `
            #loading-screen {
                position: fixed;
                left: 0;
                right: 0;
                bottom: calc(-100vh - 400px);
                height: calc(100vh + 400px);
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s ease-in-out;
                z-index: 9999;
                clip-path: polygon(0 100px, 100% 0, 100% calc(100% - 100px), 0 100%);
            }
            #loading-screen.show {
                bottom: -100px;
            }
            #loading-screen.hide {
                bottom: calc(100vh + 200px);
            }
            .loading-content {
                position: relative;
                width: 200px;
                height: 200px;
                transform: translateY(-50px);
            }
            .loading-text {
                font-family: 'Poppins', sans-serif;
                font-size: 34px;
                text-align: center;
                margin-top: 250px;
                color: #333;
            }
            .logo-bg-img, .logo-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation: pulse 2s infinite ease-in-out;
            }
            @keyframes pulse {
                0% { transform: translate(-50%, -50%) scale(1); }
                50% { transform: translate(-50%, -50%) scale(1.1); }
                100% { transform: translate(-50%, -50%) scale(1); }
            }
        `;
        document.head.appendChild(style);
        document.body.appendChild(this.loadingElement);
    }
    private showLoadingScreen(): void {
        setTimeout(() => {
            this.loadingElement.classList.add('show');
        }, 0);
    }

    public setRenderReady(): void {
        this.renderReady = true;
        this.checkAllReady();
    }

    public setPlatformReady(): void {
        this.platformReady = true;
        this.checkAllReady();
    }

    private checkAllReady(): void {
        if (this.renderReady && this.platformReady) {
            this.hideLoadingScreen();
        }
    }

    private hideLoadingScreen(): void {
        this.animateUpThenRemove();
    }

    private animateUpThenRemove(): void {
        // Add the 'hide' class to trigger the upward animation
        this.loadingElement.classList.add('hide');
    }
}

export function initializeLoadingScreen(): LoadingScreen {
    return new LoadingScreen();
}