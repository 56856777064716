import { Application, Container, Text, TextStyle, Color, Graphics } from 'pixi.js';
import { CANVAS_WIDTH, CANVAS_HEIGHT, FLOOR_HEIGHT } from './constants';
import PopupManager from './popupManager';
import { toggleFullscreen } from './utils';
import { GameMode, Player, RoomState } from '../shared/sharedTypes';
import GameStateManager from './gameStateManager.ts';
import GameModeUI from './ui/GameModeUI';

export default class UIManager {
    private app: Application;
    private gameStateManager: GameStateManager;
    private container: Container;
    private socketClient: any;
    private viewportManager: any;
    private gameWorld: Container;
    public popupManager: PopupManager;
    private podiumContainer: Container | null;
    private tabDown: boolean;
    private roomState: RoomState;
    private gameModeUI: GameModeUI | null;
    private scoreCardContainer: HTMLDivElement | null;
    private scoreCardUpdateInterval: number | null;
    private podiumUpdateInterval: number | null;

    constructor(bootstrapRoomState: RoomState, app: Application, gameStateManager: any, socketClient: any, viewportManager: any, gameWorld: Container, soundManager: any, renderManager: any, platformManager: any) {
        this.app = app;
        this.gameStateManager = gameStateManager;
        this.container = new Container();
        this.app.stage.addChild(this.container);
        this.socketClient = socketClient;
        this.viewportManager = viewportManager;
        this.gameWorld = gameWorld;
        this.popupManager = new PopupManager(app, socketClient, renderManager, soundManager, platformManager, viewportManager);
        this.podiumContainer = null;
        this.roomState = bootstrapRoomState;
        this.tabDown = false;
        this.gameModeUI = null;
        this.scoreCardContainer = null;
        this.scoreCardUpdateInterval = null;
        this.podiumUpdateInterval = null;

        this.setupUIforRoomGameMode(bootstrapRoomState);
        this.setEventListeners();
        this.showWelcomePopupIfFirstVisit();

        if (this.gameStateManager.isroundEnded) {
            if (this.roomState.round === this.roomState.totalRounds - 1) {
                this.showPodium();
            } else {
                this.showScoreCard();
            }
        }
    }

    private showWelcomePopupIfFirstVisit(): void {
        if (!localStorage.getItem("golfup-firstVisited")) {
            const welcomePopup = document.getElementById('welcome-popup') as HTMLElement;
            const closeButton = document.getElementById('close-welcome-popup') as HTMLElement;
            const usernameInput = document.getElementById('username') as HTMLInputElement;
            const submitButton = document.getElementById('submit-username') as HTMLElement;
            const submitFSButton = document.getElementById('submit-username-fullscreen') as HTMLElement;

            welcomePopup.classList.remove('hidden');

            const saveUsername = () => {
                const username = usernameInput.value.trim();
                if (username.length >= 2) {
                    const settings = JSON.parse(localStorage.getItem('golfUpSettings') || '{}');
                    settings.player = settings.player || {};
                    settings.player.name = username;
                    localStorage.setItem('golfUpSettings', JSON.stringify(settings));
                    welcomePopup.classList.add('hidden');
                    localStorage.setItem("golfup-firstVisited", new Date().toISOString());
                } else {
                    alert('Please enter a username with at least 2 characters.');
                }
            };

            closeButton.addEventListener('click', () => {
                if (usernameInput.value.trim().length >= 2) {
                    saveUsername();
                } else {
                    alert('Please enter a username with at least 2 characters before closing.');
                }
            });

            submitButton.addEventListener('click', saveUsername);
            submitFSButton.addEventListener('click', () => {
                saveUsername();
                toggleFullscreen();
            });

            usernameInput.addEventListener('keypress', (e) => {
                if (e.key === 'Enter') {
                    saveUsername();
                }
            });

            const settings = JSON.parse(localStorage.getItem('golfUpSettings') || '{}');
            if (settings.player && settings.player.name) {
                usernameInput.value = settings.player.name;
            }
        }
    }

    private setEventListeners(): void {
        this.socketClient.socket.on('roomStateReceived', this.setupUIforRoomGameMode.bind(this));

        this.socketClient.socket.on('roundStarted', (data: Partial<RoomState>) => {
            this.setupUIforRoomGameMode(this.roomState);
        });

        this.socketClient.socket.on('roundEnded', (data: Partial<RoomState>) => {
            if (this.roomState.round === this.roomState.totalRounds - 1) {
                this.showPodium();
            } else {
                this.showScoreCard(true);
            }
        });

        window.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Shift' && !this.tabDown) {
                this.tabDown = true;
                e.preventDefault();
                this.showScoreCard(false);
            }
        });

        window.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Shift') {
                this.tabDown = false;
                this.hideScoreCard();
            }
        });
    }

    private setupUIforRoomGameMode(state: RoomState): void {
        if (this.gameModeUI) {
            this.gameModeUI.destroy();
        }
        this.gameModeUI = new GameModeUI(this.socketClient, this.gameStateManager, state);
    }

    public destroy(): void {
        if (this.gameModeUI) {
            this.gameModeUI.destroy();
        }
        this.popupManager.destroy();
        this.app.stage.removeChild(this.container);
        if (this.scoreCardUpdateInterval) {
            clearInterval(this.scoreCardUpdateInterval);
        }
        if (this.podiumUpdateInterval) {
            clearInterval(this.podiumUpdateInterval);
        }
        this.hidePodium();
    }

    public update(): void {
        this.popupManager.update();
        this.gameModeUI?.update();
    }

    private showScoreCard(roundEnd: boolean = true): void {
        if (this.scoreCardContainer && this.scoreCardContainer.parentElement) {
            document.body.removeChild(this.scoreCardContainer);
        }
        this.scoreCardContainer = document.createElement('div');
        this.scoreCardContainer.id = 'score-card';
        this.scoreCardContainer.className = 'm-5 fixed inset-0 flex items-center justify-center z-50 pointer-events-none';
        document.body.appendChild(this.scoreCardContainer);

        const updateScoreCard = () => {
            if (this.scoreCardContainer) {
                this.scoreCardContainer.innerHTML = this.createScoreCardHTML();
            }
        };

        updateScoreCard(); // Initial update

        // Set up interval for continuous updates
        if (this.scoreCardUpdateInterval) {
            clearInterval(this.scoreCardUpdateInterval);
        }
        this.scoreCardUpdateInterval = setInterval(updateScoreCard, 100) as unknown as number; // Update every 100ms

        if (roundEnd) {
            setTimeout(() => {
                this.hideScoreCard();
            }, this.gameStateManager.timeUntilNextRound);
        }
    }

    private createScoreCardHTML(): string {
        const players = Object.values(this.gameStateManager.roomState.players);
        const totalRounds = this.roomState.totalRounds || 10;
        const currentRound = this.roomState.round;

        let html = `
        <div class="p-4 max-w-[790px] w-full">
            <h2 class="text-6xl font-extrabold text-white text-stroke text-center mb-4">ROUND ${currentRound + 1}</h2>
            <div class="bg-[white] shadow-xl">
                <table class="w-full table-auto">
                    <thead>
                        <tr class="bg-gray-200 ">
                            <th class="px-2 py-2">Player</th>
                            ${Array.from({ length: totalRounds }, (_, i) => `<th class="px-2 py-2">${i + 1}</th>`).join('')}
                            <th class="px-2 py-2">Total</th>
                        </tr>
                    </thead>
                    <tbody>
        `;

        players.forEach(player => {
            let total = player.scoring.score.reduce((a, b) => a + b, 0);
            html += `
                <tr class="text-lg">
                    <td class="border px-2 py-2" style="background:${player.ballColor + "44"}" >
                        <div style="width:10px;height:10px;border-radius:50%;background-color:${player.ballColor};display:inline-block;"></div>
                        ${player.name} 
                    </td>
                    ${player.scoring.score.map((score, i) => `<td class="border px-2 py-2 text-center">${score}</td>`).join('')}
                    <td class="border px-2 py-2 font-bold text-center">${total}</td>
                </tr>
            `;
        });

        html += `
                    </tbody>
                </table>
            </div>
        </div>
        `;

        return html;
    }

    private hideScoreCard(): void {
        if (this.gameStateManager.isroundEnded) return;
        if (this.scoreCardContainer && this.scoreCardContainer.parentElement) {
            document.body.removeChild(this.scoreCardContainer);
        }
        if (this.scoreCardUpdateInterval) {
            clearInterval(this.scoreCardUpdateInterval);
            this.scoreCardUpdateInterval = null;
        }
    }

    private showPodium(): void {
        if (this.podiumContainer) {
            this.app.stage.removeChild(this.podiumContainer);
        }

        this.podiumContainer = new Container();

        const background = new Graphics();
        background.rect(0, 0, this.app.screen.width, this.app.screen.height);
        background.fill({ color: 0x000000, alpha: 0.8 });
        this.podiumContainer.addChild(background);

        const titleStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 48,
            fill: '#ffffff',
            align: 'center'
        });
        const title = new Text({ text: 'Final Results', style: titleStyle });
        title.x = this.app.screen.width / 2;
        title.y = 50;
        title.anchor.set(0.5, 0);
        this.podiumContainer.addChild(title);

        this.app.stage.addChild(this.podiumContainer);

        const closeButton = new Text({
            text: 'Close',
            style: new TextStyle({
                fontFamily: 'Arial',
                fontSize: 24,
                fill: 0xffffff,
                align: 'center'
            })
        });
        closeButton.x = this.app.screen.width - 100;
        closeButton.y = 50;
        closeButton.eventMode = 'static';
        closeButton.cursor = 'pointer';
        closeButton.on('pointerdown', () => {
            this.hidePodium();
        });
        this.podiumContainer.addChild(closeButton);

        const updatePodium = () => {
            this.updatePodiumContent();
        };

        updatePodium(); // Initial update

        // Set up interval for continuous updates
        if (this.podiumUpdateInterval) {
            clearInterval(this.podiumUpdateInterval);
        }
        this.podiumUpdateInterval = setInterval(updatePodium, 100) as unknown as number; // Update every 100ms

        const adjustedTimeUntilNextRound = this.gameStateManager.timeUntilNextRound - this.gameStateManager.getRoundTimeRemaining();
        setTimeout(() => {
            this.hidePodium();
        }, adjustedTimeUntilNextRound);
    }

    private updatePodiumContent(): void {
        if (!this.podiumContainer) return;

        // Remove old player spots and special mentions
        this.podiumContainer.removeChild(...this.podiumContainer.children.filter(child =>
            child instanceof Container && (child.name === 'playerSpot' || child.name === 'specialMention')
        ));

        const players = Object.values(this.roomState.players);

        if (this.roomState.roomSettings.gameMode === 'regularGolf') {
            players.sort((a, b) => a.scoring.score.reduce((sum, score) => sum + score, 0) - b.scoring.score.reduce((sum, score) => sum + score, 0));
        } else {
            players.sort((a, b) => b.scoring.score.reduce((sum, score) => sum + score, 0) - a.scoring.score.reduce((sum, score) => sum + score, 0));
        }
        const uppestPlayer = players.reduce((a, b) => a.highestHeight > b.highestHeight ? a : b);
        const golfestPlayer = players.reduce((a, b) =>
            a.scoring.clicks.reduce((sum, clicks) => sum + clicks, 0) >
                b.scoring.clicks.reduce((sum, clicks) => sum + clicks, 0) ? a : b
        );

        const podiumPositions = [
            { x: this.app.screen.width / 2, y: this.app.screen.height / 2, scale: 1.2 },
            { x: this.app.screen.width / 2 - 120, y: this.app.screen.height - 160, scale: 0.8 },
            { x: this.app.screen.width / 2 + 120, y: this.app.screen.height - 160, scale: 0.8 }
        ];

        for (let i = 0; i < Math.min(3, players.length); i++) {
            this.createPlayerPodiumSpot(players[i], podiumPositions[i], i + 1);
        }

        this.createSpecialMention(golfestPlayer, 'Golfest Player', this.app.screen.width / 2 - 120, 120, "golfest");
        this.createSpecialMention(uppestPlayer, 'Uppest Player', this.app.screen.width / 2 + 120, 120, "uppest");
    }

    private createPlayerPodiumSpot(player: Player, position: { x: number, y: number, scale: number }, rank: number): void {
        const container = new Container();
        container.name = 'playerSpot';
        container.x = position.x;
        container.y = position.y;
        container.scale.set(position.scale);

        const circle = new Graphics();
        circle.circle(0, 0, 40);
        circle.fill({ color: Color.shared.setValue(player.ballColor.replace('#', '0x')).toNumber() });
        container.addChild(circle);

        const nameStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 24,
            fill: '#ffffff',
            align: 'center'
        });
        const nameText = new Text({ text: player.name, style: nameStyle });
        nameText.anchor.set(0.5, 0);
        nameText.y = 50;
        container.addChild(nameText);

        const scoreStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 20,
            fill: '#ffffff',
            align: 'center'
        });
        const totalScore = player.scoring.score.reduce((sum, score) => sum + score, 0);
        const scoreText = new Text({ text: `Score: ${totalScore}`, style: scoreStyle });
        scoreText.anchor.set(0.5, 0);
        scoreText.y = 80;
        container.addChild(scoreText);

        const rankStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 36,
            fill: '#FFD700',
            align: 'center'
        });
        const rankText = new Text({ text: `#${rank}`, style: rankStyle });
        rankText.anchor.set(0.5, 1);
        rankText.y = -50;
        container.addChild(rankText);

        if (this.podiumContainer) {
            this.podiumContainer.addChild(container);
        }
    }

    private createSpecialMention(player: Player, title: string, x: number, y: number, type: 'golfest' | 'uppest'): void {
        const container = new Container();
        container.name = 'specialMention';
        container.x = x;
        container.y = y;
        let stat = '';

        if (type === 'golfest') {
            const total = player.scoring.clicks.reduce((sum, clicks) => sum + clicks, 0)
            stat = `${total} click${total === 1 ? '' : 's'}`;
        }

        if (type === 'uppest') {
            stat = `${player.highestHeight.toFixed(2)}m`;
        }

        const titleStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 24,
            fill: '#FFD700',
            align: 'center'
        });
        const titleText = new Text({ text: title, style: titleStyle });
        titleText.anchor.set(0.5, 0);
        container.addChild(titleText);

        const circle = new Graphics();
        circle.circle(0, 50, 30);
        circle.fill(Color.shared.setValue(player.ballColor.replace('#', '0x')).toNumber());
        container.addChild(circle);

        const nameStyle = new TextStyle({
            fontFamily: 'Arial',
            fontSize: 20,
            fill: '#ffffff',
            align: 'center'
        });
        const nameText = new Text({ text: player.name + "\n" + stat, style: nameStyle });
        nameText.anchor.set(0.5, 0);
        nameText.y = 90;
        container.addChild(nameText);

        if (this.podiumContainer) {
            this.podiumContainer.addChild(container);
        }
    }

    private hidePodium(): void {
        if (this.podiumContainer && this.podiumContainer.parent) {
            this.app.stage.removeChild(this.podiumContainer);
        }
        if (this.podiumUpdateInterval) {
            clearInterval(this.podiumUpdateInterval);
            this.podiumUpdateInterval = null;
        }
    }

    public hideGameOver(): void {
        this.container.removeChildren();
    }

    public reset(): void {
        // Reset logic here if needed
    }
}