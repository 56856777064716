import { BOUNCE_SOUND_RATE_LIMIT } from "./constants.js";

const songToPlayForFirstTimePlayer = "/sound/music/Relaxing.mp3";
const firstTimePlayLocalStorageKey = "golfup-firstVisited"
export default class SoundManager {
    constructor() {
        this.lastTrackPlayed = localStorage.getItem('lastTrackPlayed');
        this.lastBounceSound = 0;
        this.soundCache = new Map();


        // Golf putting sounds grouped by force (1-9)
        this.puttingSounds = {
            0: ['/sound/put/GolfClubSwing_BW.48409.mp3'],
            1: ['/sound/put/GolfClubSwing_BW.48409.mp3'],
            2: ['/sound/put/GolfClubSwing_BW.48409.mp3'],
            3: ['/sound/put/Golf S1103_44_2.mp3'],
            4: ['/sound/put/GolfBallRegularHitsSnappyClubImpact_hsW87_02.mp3'],
            5: ['/sound/put/GolfClubSwing_BW.48397.mp3'],
            6: ['/sound/put/GolfClubSwing_BW.48405.mp3'],
            7: ['/sound/put/GolfClubSwing_BW.48409.mp3'],
            8: ['/sound/put/ComicGolfSwingClassicSwooshSword_IQLv2.mp3'],
            9: ['/sound/put/GolfClubSwing_BW.48406.mp3']
        };

        // Background soundtracks
        this.soundtracks = [
            "/sound/music/Tricky Two - Insomnia.mp3",
            "/sound/music/Lofi Coffee Restaurant (Full - 2 min 48 sec) - music by AZ_Studio.mp3",
            "/sound/music/v.1 (1_58).mp3",
            "/sound/music/Chill Lo Fi.mp3",
            "/sound/music/Full Version.mp3",
            "/sound/music/Ethereal .mp3",
            // '/sound/music/Boomopera - Puzzled (Full Length).mp3',
            // '/sound/music/Creative Puzzle.mp3',
            '/sound/music/Maxvic - Ambient Lounge Loop.mp3',
            '/sound/music/Maxvic - Loop for Puzzle Games.mp3',
            '/sound/music/Maxvic - Loop for Puzzle Games.wav',
            // '/sound/music/Perfect Puzzle.mp3',
            '/sound/music/Phonk Relaxing (Main Version).mp3',
            // '/sound/music/Puzzling Discoveries_compressed.mp3',
            // '/sound/music/Puzzling It Out.mp3',
            '/sound/music/Relaxing.mp3',
            '/sound/music/Soundscape Relax.mp3',
            // '/sound/music/The Secret Puzzle (Full Version).mp3',
            "/sound/music/In Lo-Fi.mp3",
            "/sound/music/A Thriller.mp3",
            "/sound/music/Yoga.mp3",
            "/sound/music/Ambient.mp3",
            "/sound/music/Elegance.mp3",
            "/sound/music/The Atmosphere.mp3",
            "/sound/music/Designer.mp3",
            "/sound/music/Inspiring.mp3",
            "/sound/music/Melodic Background.mp3",
        ];

        this.ambienceSounds = [
            "/sound/ambience/Birds_Sparrows_01.mp3",
        ];

        this.victorySoundsLayer1 = [
            "/sound/victory/Children Cheering Applauding.mp3",
            "/sound/victory/Children Cheering Kids Group Yay.mp3",
            "/sound/victory/Children Cheering Yea 2.mp3",
            "/sound/victory/Children Cheering.mp3",
            "/sound/victory/Children Yelling Yay 3.mp3",
            "/sound/victory/Kids Cheering And Laughing.mp3",
        ];

        this.victorySoundsLayer2 = [
            "/sound/victory/Cheerful Cartoon Music Accent.mp3",
            "/sound/victory/Success 04.mp3",
            "/sound/victory/Success Bonus.mp3",
            "/sound/victory/Success Finish.mp3",
            "/sound/victory/success-notification.mp3",
        ];

        const persistedSettingsString = localStorage.getItem('golfUpSettings');
        const persistedSettings = persistedSettingsString ? JSON.parse(persistedSettingsString) : {};

        this.currentSoundtrack = null;
        this.isFirstClick = true;
        this.musicMuted = persistedSettings.sound?.musicMuted;
        this.sfxMuted = persistedSettings.sound?.sfxMuted;
        this.ambienceMuted = persistedSettings.sound?.ambientMuted;
        this.musicVolume = 0.05;
        this.ambienceVolume = 0.04;
        this.sfxVolume = 0.05;
        this.currentAmbienceSound = 0;
        this.nextAmbienceSound = null;
        setTimeout(() => {
            if (!this.ambienceMuted) {
                this.playNextAmbienceSound();
            }
        }, 1000);
        this.logStorageUsage();

        // Add event listeners for visibility change
        document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
    }

    async loadSound(url) {
        if (this.soundCache.has(url)) {
            return this.soundCache.get(url);
        }

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            const audioUrl = URL.createObjectURL(blob);
            this.soundCache.set(url, audioUrl);
            return audioUrl;
        } catch (e) {
            console.error(`Failed to load sound: ${url}`, e);
            return url; // Fall back to the original URL if loading fails
        }
    }

    async playSound(url, volume = 1) {
        try {
            const soundUrl = await this.loadSound(url);
            const sound = new Howl({
                src: [soundUrl],
                volume: volume,
                format: ['mp3']
            });
            sound.play();
        } catch (error) {
            console.error(`Error playing sound: ${url}`, error);
        }
    }

    async playNextAmbienceSound() {
        if (this.ambienceMuted) return;
        this.currentAmbienceSound = (this.currentAmbienceSound + 1) % this.ambienceSounds.length;
        const soundUrl = await this.loadSound(this.ambienceSounds[this.currentAmbienceSound]);
        this.nextAmbienceSound = new Howl({
            src: [soundUrl],
            loop: false,
            volume: this.ambienceMuted ? 0 : this.ambienceVolume,
            format: ['mp3'],
            fade: 1000,
            onend: () => this.playNextAmbienceSound()
        });
        this.nextAmbienceSound.play();
        this.ambienceSound = this.nextAmbienceSound;
    }

    async playRandomSoundtrack() {

        if (this.currentSoundtrack) {
            this.currentSoundtrack.stop();
        }
        let randomIndex;
        do {
            randomIndex = Math.floor(Math.random() * this.soundtracks.length);
        } while (this.soundtracks[randomIndex] === this.lastTrackPlayed);

        const hasPlayedBefore = localStorage.getItem(firstTimePlayLocalStorageKey);
        if (!hasPlayedBefore) {
            randomIndex = this.soundtracks.indexOf(songToPlayForFirstTimePlayer);
        }

        const soundUrl = await this.loadSound(this.soundtracks[randomIndex]);
        this.currentSoundtrack = new Howl({
            src: [soundUrl],
            loop: false,
            volume: this.musicMuted ? 0 : this.musicVolume,
            format: ['mp3'],
            onend: () => this.playRandomSoundtrack()
        });
        this.currentSoundtrack.play();
        this.lastTrackPlayed = this.soundtracks[randomIndex];
        localStorage.setItem('lastTrackPlayed', this.lastTrackPlayed);
    }

    async playPutSound(force) {
        if (this.sfxMuted) return;
        if (this.isFirstClick) {
            this.playRandomSoundtrack();
            this.isFirstClick = false;
        }

        // Ensure force is a number and has a default value
        force = typeof force === 'number' ? force : 0.002;

        // Clamp force between 0.002 and 0.04
        const forceClamped = Math.max(0.002, Math.min(force, 0.04));

        // Map the force to a scale of 1-9
        const forceLevel = Math.ceil(((forceClamped - 0.002) / (0.04 - 0.002)) * 9);
        // Get the array of sounds for this force level
        const soundsForForce = this.puttingSounds[forceLevel];

        // Choose a random sound from the array
        const randomIndex = Math.floor(Math.random() * soundsForForce.length);
        const soundToPlay = soundsForForce[randomIndex];
        await this.playSound(soundToPlay, this.sfxVolume);
    }

    async playBounceSound(force) {
        if (this.sfxMuted) return;
        if (this.lastBounceSound + BOUNCE_SOUND_RATE_LIMIT < Date.now()) {
            const volume = (Math.min(force / 10, 1) * this.sfxVolume) * (0.1 * force) - 0.1;
            await this.playSound('/sound/bounce/bounce-1.mp3', volume);
            this.lastBounceSound = Date.now();
        }
    }

    async playVictorySound() {
        if (this.sfxMuted) return;
        // play a layer 1 and layer 2 sound at the same time

        await this.playSound("/sound/sfx/Win Pop Up.mp3", this.sfxVolume);
        // const randomIndex1 = Math.floor(Math.random() * this.victorySoundsLayer1.length);
        const randomIndex2 = Math.floor(Math.random() * this.victorySoundsLayer2.length);
        // await this.playSound(this.victorySoundsLayer1[randomIndex1], this.sfxVolume);
        await this.playSound(this.victorySoundsLayer2[randomIndex2], this.sfxVolume);
    }

    setMusicMuted(muted) {
        this.musicMuted = muted;
        if (this.currentSoundtrack) {
            this.currentSoundtrack.mute(muted);
            if (muted) {
                this.currentSoundtrack.stop();
            } else {
                this.currentSoundtrack.play();
            }
        }
    }

    setAmbientMuted(muted) {
        this.ambienceMuted = muted;
        if (!this.ambienceSound) {
            this.playNextAmbienceSound();
        }
        if (this.ambienceSound) {
            this.ambienceSound.mute(muted);
            if (muted) {
                this.ambienceSound.stop();
            } else {
                this.ambienceSound.play();
            }
        }
    }

    setSFXMuted(muted) {
        this.sfxMuted = muted;
    }

    setMusicVolume(volume) {
        this.musicVolume = volume;
        if (this.currentSoundtrack) {
            this.currentSoundtrack.volume(this.musicMuted ? 0 : volume);
        }
    }

    setSFXVolume(volume) {
        this.sfxVolume = volume;
    }

    skipTrack() {
        this.playRandomSoundtrack();
    }

    pauseMusic() {
        if (this.currentSoundtrack) {
            this.currentSoundtrack.pause();
        }
    }

    resumeMusic() {
        if (this.currentSoundtrack) {
            this.currentSoundtrack.play();
        }
    }

    isMusicPaused() {
        return this.currentSoundtrack && !this.currentSoundtrack.playing();
    }

    async logStorageUsage() {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            try {
                const estimate = await navigator.storage.estimate();
                const usage = estimate.usage;
                const quota = estimate.quota;
                const percentUsed = (usage / quota * 100).toFixed(2);


                if (estimate.usageDetails) {
                    const cacheUsage = estimate.usageDetails.caches;
                }
            } catch (error) {
                console.error('Error getting storage estimate:', error);
            }
        } else {
        }
    }

    formatBytes(bytes) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    playCoinSound() {
        if (this.sfxMuted) return;
        this.playSound('/sound/sfx/coin.mp3', 0.01);
    }

    playPlayerJoinSound() {
        if (this.sfxMuted) return;
        this.playSound('/sound/sfx/Star.mp3', this.sfxVolume);
    }

    playPlayerLeaveSound() {
        if (this.sfxMuted) return;
        this.playSound('/sound/sfx/Bubble_Woosh_1.mp3', this.sfxVolume);
    }

    pauseAllSounds() {
        Howler.mute(true);
    }

    resumeAllSounds() {
        Howler.mute(false);
    }

    handleVisibilityChange() {
        if (document.hidden) {
            this.pauseAllSounds();
        } else {
            this.resumeAllSounds();
        }
    }

    async playClickSound() {
        if (this.sfxMuted) return;
        await this.playSound(this.puttingSounds[3][0], this.sfxVolume);
    }

}