import { DRAG_CIRCLE_DIAMETER, CANVAS_WIDTH, CANVAS_HEIGHT, FLOOR_HEIGHT, WALL_THICKNESS, CYCLE_BACKGROUNDS, PLAYABLE_AREA } from './constants';
import defaultSettings from './defaultSettings';
import { Text, Container, Graphics, Sprite, Texture, Assets, TilingSprite, BlurFilter } from 'pixi.js';
import grass from './images/golfup-grass-2-web-optimized.webp';
import logoSpriteImg from './images/logo.png';
import { Application } from 'pixi.js';
import ViewportManager from './viewportManager';
import BallManager from './ballManager';

export default class RenderManager {
    private app: Application;
    private gameWorld: Container;
    private viewportManager: ViewportManager;
    private backgroundContainer: Container;
    private uiContainer: Container;
    private settings: any;
    private currentControlType: string;
    private backgroundImages: string[];
    private currentBackgroundIndex: number;
    private backgroundSprite: Sprite | null;
    private centerCircle: Graphics | null;
    private aimLine: Graphics;
    private throughLine: Graphics | null;
    private puttingLine: Graphics;
    private cursorTriangle: Graphics;
    public ballManager: BallManager | undefined;
    private floorSprite: TilingSprite | null;

    private cursorTargetColor: number;
    private cursorCurrentColor: number;
    private colorTransitionSpeed: number;
    logoSpriteBG: Sprite;
    logoSprite: Sprite;

    private dragZone: Graphics | null

    private backgroundBlur: BlurFilter;

    constructor(app: Application, gameWorld: Container, viewportManager: ViewportManager) {

        this.app = app;
        this.gameWorld = gameWorld;
        this.viewportManager = viewportManager;
        this.backgroundContainer = new Container();
        this.uiContainer = new Container();
        this.gameWorld.addChild(this.backgroundContainer);
        this.app.stage.addChild(this.gameWorld);
        this.app.stage.addChild(this.uiContainer);


        this.settings = JSON.parse(localStorage.getItem('golfUpSettings') || '{}') || defaultSettings;
        this.currentControlType = this.settings.player.controlType;


        this.backgroundImages = [
            "/images/backgrounds/beach-1.jpg",
            "/images/backgrounds/grass-1.jpg",
            "/images/backgrounds/grass-2.jpg",
            "/images/backgrounds/snow-1.jpg",
            // "/images/backgrounds/smaller/bubble-1.webp",
            // "/images/backgrounds/smaller/bubble-8.webp",
            // "/images/backgrounds/smaller/bubble-9.webp",
            // "/images/backgrounds/smaller/bubble-10.webp",
            // "/images/backgrounds/smaller/aurora-over-snowy-mountains-northern-lights-2023-11-27-05-17-20-utc.webp",
            // "/images/backgrounds/smaller/northern-lights-above-mountains-at-night-aurora-b-2023-11-27-04-58-44-utc.webp",
            // "/images/backgrounds/smaller/shanghai-modern-building-skyline-in-nightfall-2023-11-27-05-26-26-utc.webp",
            // "/images/backgrounds/smaller/output.webp",

        ];

        this.currentBackgroundIndex = parseInt(localStorage.getItem('backgroundIndex') || '23');
        this.backgroundSprite = null;
        this.backgroundBlur = new BlurFilter(2); // Create a BlurFilter with strength 5

        this.setupBackground();
        // this.setupGrassTexture();

        this.centerCircle = null;
        this.aimLine = new Graphics();
        this.uiContainer.addChild(this.aimLine);

        this.puttingLine = new Graphics();
        this.gameWorld.addChild(this.puttingLine);
        this.uiContainer.addChild(this.puttingLine);

        this.cursorTriangle = this.createCursorTriangle();

        this.setupControlScheme(this.currentControlType);
        this.ballManager = undefined;

        window.addEventListener('resize', this.handleResize.bind(this));

        this.createLogo();

        this.cursorTargetColor = 0xAAFF33;  // Green color
        this.cursorCurrentColor = 0xAAFF33;
        this.colorTransitionSpeed = 0.1;  // Adjust this value to change transition speed

    }

    private interpolateColor(color1: number, color2: number, factor: number): number {
        const r1 = (color1 >> 16) & 0xFF;
        const g1 = (color1 >> 8) & 0xFF;
        const b1 = color1 & 0xFF;

        const r2 = (color2 >> 16) & 0xFF;
        const g2 = (color2 >> 8) & 0xFF;
        const b2 = color2 & 0xFF;

        const r = Math.round(r1 + factor * (r2 - r1));
        const g = Math.round(g1 + factor * (g2 - g1));
        const b = Math.round(b1 + factor * (b2 - b1));

        return (r << 16) | (g << 8) | b;
    }

    setupControlScheme(controlType: string): void {
        this.currentControlType = controlType;
        if (controlType === "click-drag") {
            this.setupClickDragMode();
        } else {
            this.setupOneClickMode();
        }
    }

    setupClickDragMode(): void {

        this.puttingLine.visible = true;
    }

    setupOneClickMode(): void {
        this.clearDragZone();
        this.puttingLine.visible = false;
    }

    setupBackground(): void {
        if (CYCLE_BACKGROUNDS) {
            this.setupCyclingBackgrounds();
        } else {
            this.setupGradientBackground();
        }
    }

    setupCyclingBackgrounds(): void {
        this.backgroundSprite = new Sprite();
        this.backgroundSprite.filters = [this.backgroundBlur];
        this.backgroundContainer.addChild(this.backgroundSprite);
        this.loadNextBackground(false);
    }

    async loadNextBackground(inc: boolean = true): Promise<void> {
        try {
            if (inc) this.currentBackgroundIndex = (this.currentBackgroundIndex + 1) % this.backgroundImages.length;
            const img = await Assets.load(this.backgroundImages[this.currentBackgroundIndex]);
            const texture = new Texture({
                source: img.source,
            });

            if (this.backgroundSprite) {
                this.backgroundSprite.texture = texture;
                this.recenterBackground();
            }

            localStorage.setItem('backgroundIndex', this.currentBackgroundIndex.toString());
        } catch (e) {
            console.log("Error loading background image", e);
            this.setupGradientBackground();
        }
    }

    setupGradientBackground(): void {
        const background = new Graphics();
        const gradientHeight = CANVAS_HEIGHT * 2;
        const colorStops = [0xafddaf, 0x8dfffe, 0x2fcfff, 0x8dfffe, 0xFFEFEF].reverse();
        const totalStops = colorStops.length;
        for (let i = 0; i < gradientHeight; i++) {
            const ratio = i / gradientHeight;
            const colorIndex = Math.floor(ratio * (totalStops - 1));
            const nextColorIndex = Math.min(colorIndex + 1, totalStops - 1);
            const colorRatio = (ratio * (totalStops - 1)) - colorIndex;
            const color = this.lerpColor(colorStops[colorIndex], colorStops[nextColorIndex], colorRatio);
            background.rect(0, -CANVAS_HEIGHT + i, CANVAS_WIDTH, 1);
            background.fill({ color });
        }
        this.backgroundContainer.addChild(background);
    }

    lerpColor(a: number, b: number, amount: number): number {
        const ar = (a >> 16) & 0xff;
        const ag = (a >> 8) & 0xff;
        const ab = a & 0xff;
        const br = (b >> 16) & 0xff;
        const bg = (b >> 8) & 0xff;
        const bb = b & 0xff;
        const rr = ar + amount * (br - ar);
        const rg = ag + amount * (bg - ag);
        const rb = ab + amount * (bb - ab);
        return (rr << 16) + (rg << 8) + (rb | 0);
    }

    createCenterCircle(): void {
        if (!this.centerCircle) {
            this.centerCircle = new Graphics();
            this.uiContainer.addChild(this.centerCircle);
        }
        this.updateCenterCircle();
    }

    clearCenterCircle(): void {
        if (this.centerCircle) {
            this.uiContainer.removeChild(this.centerCircle);
            this.centerCircle = null;
        }
    }

    updateCenterCircle(): void {
        if (!this.centerCircle) return;

        const viewportWidth = window.innerWidth;
        const circleRadius = DRAG_CIRCLE_DIAMETER / 2;
        const lineWidth = 1;
        const centerX = viewportWidth / 2;
        const centerY = window.innerHeight * 0.75;

        this.centerCircle.clear();
        this.centerCircle.circle(centerX, centerY, circleRadius);
        this.centerCircle.stroke({ width: lineWidth, color: 0xFFFFFF });
        this.centerCircle.visible = true;
    }

    createDragZone(width: number, height: number, left: number, top: number) {
        if (!this.dragZone) {
            this.dragZone = new Graphics();
            this.uiContainer.addChild(this.dragZone);
        }
        this.updateDragZone(width, height, left, top);
    }

    updateDragZone(width: number, height: number, left: number, top: number) {
        this.dragZone?.clear();
        this.dragZone?.setStrokeStyle({ width: 2, color: 0xFFFFFF, alpha: 0.5 });
        this.dragZone?.rect(left, top, width, height);
        this.dragZone?.stroke();
    }

    clearDragZone() {
        if (this.dragZone) {
            this.uiContainer.removeChild(this.dragZone);
            this.dragZone = null;
        }
    }


    drawAimLine(startX: number, startY: number, endX: number, endY: number): void {
        this.aimLine.clear();
        this.aimLine.moveTo(startX, startY);
        this.aimLine.lineTo(endX, endY);
        this.aimLine.stroke({ width: 3, color: 0xFFFFFF });
    }

    clearAimLine(): void {
        this.aimLine.clear();
    }

    createCursorTriangle(): Graphics {
        const cursorTriangle = new Graphics();
        // set zIndex to lower than the force text
        cursorTriangle.zIndex = 100
        this.uiContainer.addChild(cursorTriangle);
        return cursorTriangle;
    }

    createThroughLine(): Graphics {
        this.throughLine = new Graphics();
        this.uiContainer.addChild(this.throughLine);
        return this.throughLine;
    }

    createPuttingLine(): Graphics {
        const puttingLine = new Graphics();
        this.gameWorld.addChild(puttingLine);
        return puttingLine;
    }

    createForceText(): Text {
        const forceText = new Text({ text: 'Force: 0', style: { fontFamily: 'Verdana', fontSize: 12, fill: 0xFFFFFF, stroke: 0xAAAAAA } });
        forceText.anchor.set(0);
        forceText.zIndex = 100000;
        forceText.visible = false;
        this.uiContainer.addChild(forceText);
        return forceText;
    }

    updateCursorTriangle(screenPosition: { x: number, y: number }, targetPosition: { x: number, y: number }, force: number, cursorBounce: number = 0, isClickDragMode: boolean = false): void {
        this.cursorTriangle.clear();
        this.cursorTriangle.zIndex = 100000;

        const dx = targetPosition.x - screenPosition.x;
        const dy = targetPosition.y - screenPosition.y;
        const angle = Math.atan2(dy, dx);

        const bounceDistance = 2 * Math.sin(cursorBounce);
        const bouncedX = screenPosition.x + Math.cos(angle) * bounceDistance;
        const bouncedY = screenPosition.y + Math.sin(angle) * bounceDistance;

        const triangleSize = 20 + force * 300;
        const x1 = bouncedX + Math.cos(angle) * triangleSize;
        const y1 = bouncedY + Math.sin(angle) * triangleSize;
        const x2 = bouncedX + Math.cos(angle + 2.5) * triangleSize;
        const y2 = bouncedY + Math.sin(angle + 2.5) * triangleSize;
        const x3 = bouncedX + Math.cos(angle - 2.5) * triangleSize;
        const y3 = bouncedY + Math.sin(angle - 2.5) * triangleSize;

        const normalizedForce = (force - 0.002) / (0.04 - 0.002);

        let alpha = 0.5 + normalizedForce / 2;
        if (isClickDragMode) {
            const distanceFromCenter = Math.sqrt(dx * dx + dy * dy);
            const maxDistance = DRAG_CIRCLE_DIAMETER / 2;
            alpha *= Math.min(distanceFromCenter / maxDistance, 1);
        }

        const color = this.cursorCurrentColor;
        this.cursorTriangle.poly([x1, y1, x2, y2, x3, y3]);
        this.cursorTriangle.fill({ color, alpha });


        this.cursorTriangle.visible = true;
    }

    clearCursorGraphics(): void {
        if (this.cursorTriangle) {
            this.cursorTriangle.visible = false;
        }
    }

    updateForceText(forceText: Text, force: number, x: number, y: number): void {
        forceText.text = `${force.toFixed(0)}`;
        forceText.x = x - 20;
        forceText.y = y + 20;
        forceText.visible = true;
    }

    updateThroughLine(worldPosition: { x: number, y: number }, ballPosition: { x: number, y: number }, magnitude: number): void {
        const throughLine = this.throughLine || this.createThroughLine();
        throughLine.clear();
        // if ball is in motion, don't draw the through line
        if (this.ballManager?.inMotion) {
            throughLine.visible = false;
            return;
        }
        throughLine.visible = true;

        const startScreenX = this.viewportManager.getScreenXFromWorldX(worldPosition.x - WALL_THICKNESS);
        const startScreenY = this.viewportManager.getScreenYFromWorldY(worldPosition.y);
        const endScreenX = this.viewportManager.getScreenXFromWorldX(ballPosition.x);
        const endScreenY = this.viewportManager.getScreenYFromWorldY(ballPosition.y);

        // Clamp the start and end points to the visible area
        const clampedStartY = Math.max(0, Math.min(startScreenY, window.innerHeight));
        const clampedEndY = Math.max(0, Math.min(endScreenY, window.innerHeight));

        // Only draw the line if it's at least partially visible
        if (clampedStartY < window.innerHeight || clampedEndY < window.innerHeight) {
            throughLine.moveTo(startScreenX, clampedStartY);
            throughLine.lineTo(endScreenX, clampedEndY);
            throughLine.stroke({ width: 2, color: 0xFFFFFF, alpha: 0.5 });
        }
    }

    update(delta: number): void {

        // Update cursor color
        const targetColor = this.ballManager?.inMotion ? 0xEEEEEE : 0xAAFF33;
        if (this.cursorTargetColor !== targetColor) {
            this.cursorTargetColor = targetColor;
        }

        this.cursorCurrentColor = this.interpolateColor(
            this.cursorCurrentColor,
            this.cursorTargetColor,
            this.colorTransitionSpeed
        );
    }

    calculateCoverDimensions(imageWidth: number, imageHeight: number, containerWidth: number, containerHeight: number): { width: number, height: number, x: number, y: number } {
        const imageRatio = imageWidth / imageHeight;
        const containerRatio = containerWidth / containerHeight;

        let newWidth, newHeight;

        if (containerRatio > imageRatio) {
            newWidth = containerWidth;
            newHeight = containerWidth / imageRatio;
        } else {
            newWidth = containerHeight * imageRatio;
            newHeight = containerHeight;
        }

        return {
            width: newWidth,
            height: newHeight,
            x: (containerWidth - newWidth) / 2,
            y: (containerHeight - newHeight) / 2
        };
    }

    updatePuttingLine(start: { x: number, y: number }, end: { x: number, y: number }): void {
        this.puttingLine.clear();
        this.puttingLine.moveTo(start.x, start.y);
        this.puttingLine.lineTo(end.x, end.y);
        this.puttingLine.stroke({ width: 2, color: 0xFFFFFF, alpha: 0.8 });
    }

    clearPuttingLine(): void {
        this.puttingLine.clear();
    }

    recenterBackground(): void {
        if (this.backgroundSprite) {
            const texture = this.backgroundSprite.texture;
            const imageAspectRatio = texture.width / texture.height;
            const canvasAspectRatio = CANVAS_WIDTH / CANVAS_HEIGHT;

            let newWidth, newHeight;

            if (canvasAspectRatio > imageAspectRatio) {
                // Canvas is wider than the image
                newWidth = CANVAS_WIDTH;
                newHeight = CANVAS_WIDTH / imageAspectRatio;
            } else {
                // Canvas is taller than the image
                newHeight = CANVAS_HEIGHT;
                newWidth = CANVAS_HEIGHT * imageAspectRatio;
            }

            this.backgroundSprite.width = newWidth;
            this.backgroundSprite.height = newHeight;

            // Center horizontally
            this.backgroundSprite.x = (CANVAS_WIDTH - newWidth) / 2;

            // Align bottom with canvas bottom
            this.backgroundSprite.y = CANVAS_HEIGHT - newHeight;
        }
    }

    handleResize(): void {
        this.recenterBackground();
        // this.updateGrassTexture();
        this.updateLogo();

    }
    async setupGrassTexture(): Promise<void> {
        const texture = await Assets.load(grass);
        this.floorSprite = new TilingSprite({
            texture,
            width: CANVAS_WIDTH,
            height: FLOOR_HEIGHT,
        });
        this.floorSprite.position.set(0, CANVAS_HEIGHT - FLOOR_HEIGHT);
        this.gameWorld.addChild(this.floorSprite);
    }

    updateGrassTexture(): void {
        if (this.floorSprite) {
            this.floorSprite.width = window.innerWidth;
            this.floorSprite.height = FLOOR_HEIGHT;
            this.floorSprite.anchor.set(0.5, 0.5);
            this.floorSprite.position.set(this.floorSprite.width / 2, CANVAS_HEIGHT - FLOOR_HEIGHT);
        }
    }

    // Method to toggle control scheme
    toggleControlScheme(): void {
        this.currentControlType = this.currentControlType === "click-drag" ? "1-click" : "click-drag";
        this.setupControlScheme(this.currentControlType);
    }

    // Method to get current control type
    getCurrentControlType(): string {
        return this.currentControlType;
    }

    // Method to set ball manager
    setBallManager(ballManager: BallManager): void {
        this.ballManager = ballManager;
    }

    // Method to get the UI container
    getUIContainer(): Container {
        return this.uiContainer;
    }

    // Method to get the game world container
    getGameWorld(): Container {
        return this.gameWorld;
    }

    // Method to add a child to the game world
    addToGameWorld(child: PIXI.DisplayObject): void {
        this.gameWorld.addChild(child);
    }

    // Method to remove a child from the game world
    removeFromGameWorld(child: PIXI.DisplayObject): void {
        this.gameWorld.removeChild(child);
    }

    // Method to clear all graphics
    clearAllGraphics(): void {
        this.clearAimLine();
        this.clearPuttingLine();
        this.clearCursorGraphics();
    }

    // Method to show/hide UI elements
    setUIVisibility(visible: boolean): void {
        this.uiContainer.visible = visible;
    }

    // Method to get the current background index
    getCurrentBackgroundIndex(): number {
        return this.currentBackgroundIndex;
    }

    // Method to manually set the background
    setBackground(index: number): void {
        if (index >= 0 && index < this.backgroundImages.length) {
            this.currentBackgroundIndex = index;
            this.loadNextBackground(false);
        }
    }

    // Method to toggle background cycling
    toggleBackgroundCycling(enabled: boolean): void {
        // This method would need to be implemented if you want to allow toggling of background cycling at runtime
    }

    // Method to update game world position based on viewport
    updateGameWorldPosition(): void {
        const scrollY = this.viewportManager.scrollPosition;
        this.gameWorld.y = -scrollY * this.viewportManager.scale;
    }

    async createLogo(): Promise<void> {
        const texture = await Assets.load(logoSpriteImg)
        this.logoSpriteBG = new Sprite(texture);
        this.logoSpriteBG.scale.set(0.6);
        this.logoSpriteBG.anchor.set(0.5);
        this.logoSpriteBG.position.set(CANVAS_WIDTH / 2, CANVAS_HEIGHT - FLOOR_HEIGHT / 2);
        // make sure the logo is always on top
        this.logoSpriteBG.zIndex = 100
        this.gameWorld.addChild(this.logoSpriteBG);

        this.signalReady();
    }

    signalReady(): void { }

    updateLogo(): void {
        this.logoSpriteBG = this.uiContainer.children.find((child) => child instanceof Sprite) as Sprite;
        if (this.logoSpriteBG) {
            this.logoSpriteBG.position.set(CANVAS_WIDTH / 2, CANVAS_HEIGHT - FLOOR_HEIGHT / 2);
        }
    }

    clearAimingGraphics() {
        this.cursorTriangle.clear();
        this.throughLine?.clear();
    }
}