import { PLAYABLE_AREA, CEILING_HEIGHT } from '../constants';
import { GameMode } from '../../shared/sharedTypes';
import { createArcPlatforms } from './arc';
import { createBinaryClustersParams } from './binaryCluster';
import { createRectangularPlatforms } from './rectangle';
import { createRandomSpline } from './spline';
import { platformDistributor } from './platformDistribution';

export function createPlatformParams(newRoom = true, socketClient, platformData) {
    const _settings = localStorage.getItem('golfUpSettings');
    const settings = _settings ? JSON.parse(_settings) : defaultSettings;
    platformDistributor.reset();
    settings.roomSettings.platforms.Arc.active && createArcPlatforms(settings, platformData);
    settings.roomSettings.platforms.BinaryCluster.active && createBinaryClustersParams(settings, platformData);
    settings.roomSettings.platforms.Rectangle.active && createRectangularPlatforms(settings, platformData);
    settings.roomSettings.platforms.Spline.active && createRandomSpline(settings, platformData);

    const noGoalModes = [GameMode.CoinDash, GameMode.EscapeBall];
    if (!noGoalModes.includes(settings.roomSettings.gameMode)) {
        makeGoal.call(this);
    }
    function makeGoal() {
        if (settings.roomSettings.goalType === 'finish-line') {
            const finishLineParams = {
                centerX: PLAYABLE_AREA / 2,
                y: (CEILING_HEIGHT / 2),
                width: PLAYABLE_AREA,
                height: CEILING_HEIGHT,
                isGoal: true,
                goalType: 'finish-line'
            };
            platformData.push({ type: 'rectangularMesh', params: finishLineParams });
        } else if (settings.roomSettings.goalType === 'bucket') {

            // Create bucket arc
            const bucketArcParams = {
                centerX: PLAYABLE_AREA / 2,
                y: 250,
                radius: 80,
                thickness: 40,
                startAngle: -0.2,
                endAngle: Math.PI + 0.2,
                divisions: 8,
                isGoal: true,
                goalType: 'bucket'
            };
            platformData.push({ type: 'arcMesh', params: bucketArcParams });
        }
    }

    settings.roomSettings.levelId = Math.floor(Math.random() * 1000000);

    console.log("platform params have been created", platformData);
    socketClient.platformsCreatedLocally(settings, platformData.sort((a, b) => b.height - a.height), newRoom);
}