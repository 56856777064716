import { Color, Container, Graphics } from 'pixi.js';

export class PlatformWithRainbow extends Container {
    constructor(platformGraphics, thickness = 5) {
        super();
        this.platform = platformGraphics;
        this.rainbow = new Graphics();
        this.thickness = thickness;

        this.addChild(this.rainbow);
        this.addChild(this.platform);

        this.hue = 10;
    }

    updateRainbow() {
        const bounds = this.platform.getBounds();
        this.rainbow.clear();

        // Draw the rainbow background
        const fillColor = Color.fromHSL(this.hue / 360, 1, 0.5);
        this.rainbow.rect(0, 0, bounds.width, bounds.height);
        this.rainbow.fill({ color: fillColor.toNumber() });

        // Draw the rainbow border
        const lineColor = Color.fromHSL(((this.hue + 180) % 360) / 360, 1, 0.5);
        this.rainbow.rect(0, 0, bounds.width, bounds.height);
        this.rainbow.stroke({ width: this.thickness, color: lineColor.toNumber(), alpha: 1 });

        this.hue = (this.hue + 1) % 360;
    }
}

export class PlatformWithShadow extends Container {
    constructor(platformGraphics, shadowGraphics) {
        super();
        this.shadow = shadowGraphics;
        this.platform = platformGraphics;

        this.addChild(this.shadow);
        this.addChild(this.platform);

        // Initial shadow offset
        this.shadowOffset = { x: 0, y: 0 };
        this.shadow.position.set(this.shadowOffset.x, this.shadowOffset.y);

        // Maximum shadow offset
        this.maxOffset = 8;
    }

    updateShadow(ballX, ballY, yOffset) {
        const platformBounds = this.platform.getBounds();
        // Calculate the center of the platform
        const platformCenterX = platformBounds.x + platformBounds.width / 2;
        const platformCenterY = platformBounds.y + platformBounds.height / 2 + yOffset;

        // Calculate the angle and distance from the platform center to the ball
        const dx = ballX - platformCenterX;
        const dy = ballY - platformCenterY;
        const angle = Math.atan2(dy, dx);
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Calculate the shadow offset
        const maxOffset = this.maxOffset;
        const offsetMagnitude = Math.min(distance / 10, maxOffset);
        this.shadowOffset.x = -(offsetMagnitude * Math.cos(angle));
        this.shadowOffset.y = -(offsetMagnitude * Math.sin(angle));

        // Apply the offset to the shadow
        this.shadow.position.set(this.shadowOffset.x, this.shadowOffset.y);
    }
}