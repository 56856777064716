import { CANVAS_WIDTH, CANVAS_HEIGHT, PLAYABLE_AREA, WALL_THICKNESS } from './constants.js';
import { lerp } from './utils.js';
import { CameraMode, CameraMotionMode } from '../shared/sharedTypes.js';
import * as PIXI from 'pixi.js';

const SCROLL_TO_BOTTOM_ON_START = true;

export default class ViewportManager {
    app: PIXI.Application;
    gameWorld: PIXI.Container;
    scale: number;
    scrollPosition: number;
    inputManager: any;
    lastTouchY: number;
    isTouching: boolean;
    ballManager: any;
    cameraMode: CameraMode;
    cameraMotionMode: CameraMotionMode;
    spectateAfterFinished: boolean;

    waitForInitialScrollToFinish: boolean;
    lastPlayerScrollTime: number;

    constructor(app, gameWorld) {
        this.app = app;
        this.gameWorld = gameWorld;
        this.scale = 1;
        this.scrollPosition = 0;
        this.inputManager = null;
        this.lastTouchY = 0;
        this.isTouching = false;
        this.init();
        this.ballManager = undefined;
        this.cameraMode = CameraMode.KEEP_BALL_IN_VIEW;
        this.cameraMotionMode = CameraMotionMode.LERP;
        this.spectateAfterFinished = false;
        this.waitForInitialScrollToFinish = true;
        this.lastPlayerScrollTime = 0;

        window.scrollTo(0, 1);
    }

    init() {
        this.adjustViewport();
        this.setupEventListeners();
        if (SCROLL_TO_BOTTOM_ON_START) {
            setTimeout(
                () => this.lerpViewportScrollPositionTo(CANVAS_HEIGHT - window.innerHeight / this.scale, 0.03, 3000),
                400
            );
        }
        setTimeout(() => {
            this.waitForInitialScrollToFinish = false;
        }, 4000);
        window.addEventListener('click', () => {
            this.waitForInitialScrollToFinish = false;
        });
        window.addEventListener('touchstart', () => {
            this.waitForInitialScrollToFinish = false;
        });
    }

    adjustViewport() {
        const viewportWidth = window.innerWidth;
        const scaleFactor = viewportWidth < PLAYABLE_AREA ? viewportWidth / PLAYABLE_AREA : 1;
        this.scale = scaleFactor;

        this.app.renderer.resize(viewportWidth, window.innerHeight);

        if (this.gameWorld) {
            this.gameWorld.scale.set(this.scale);
            const offsetX = (viewportWidth - PLAYABLE_AREA * this.scale) / 2;
            this.gameWorld.x = offsetX - WALL_THICKNESS * this.scale;
            const offsetY = (window.innerHeight - CANVAS_HEIGHT * this.scale) / 2;
            this.gameWorld.y = offsetY;
        }
    }

    lerpViewportScrollPositionTo(targetY: number, speed: number, timeout?: number) {
        const maxScroll = CANVAS_HEIGHT - this.getVisibleHeight();
        targetY = Math.max(0, Math.min(targetY, maxScroll));
        
        let startTime = Date.now();
        let isScrolling = true;

        const animate = () => {
            if (!isScrolling) return;

            const currentTime = Date.now();
            if (timeout !== undefined && currentTime - startTime > timeout) {
                isScrolling = false;
                return;
            }

            this.scrollPosition = lerp(this.scrollPosition, targetY, speed);
            this.scrollPosition = Math.max(0, Math.min(this.scrollPosition, maxScroll));
            const newY = -this.scrollPosition * this.scale;
            this.gameWorld.y = Math.max(-maxScroll * this.scale, Math.min(0, newY));
            this.inputManager.updateMousePositionRelativeToViewport();

            if (Math.abs(this.scrollPosition - targetY) > 0.1 && this.scrollPosition < maxScroll) {
                requestAnimationFrame(animate);
            } else {
                isScrolling = false;
            }
        };

        animate();

        const cancelScroll = () => {
            isScrolling = false;
        };

        window.addEventListener('touchstart', cancelScroll, { once: true });
        window.addEventListener('wheel', cancelScroll, { once: true });
        window.addEventListener('mousewheel', cancelScroll, { once: true });
    }

    setupEventListeners() {
        window.addEventListener('resize', this.adjustViewport.bind(this));
        window.addEventListener('orientationchange', this.adjustViewport.bind(this));
        window.addEventListener('load', () => {
            setTimeout(() => {
                this.adjustViewport();
            }, 0);
        });
    }

    update() {
        if (this.waitForInitialScrollToFinish || (this.lastPlayerScrollTime + 1000 > Date.now())) {
            return;
        }
        if (!this.ballManager.inMotion) {
            return;
        }
        const ballPosition = this.ballManager.getPosition();
        if (ballPosition) {
            switch (this.cameraMode) {
                case CameraMode.CENTER_BALL:
                    this.lerpViewportScrollPositionTo(
                        ballPosition.y - this.getVisibleHeight() / 2,
                        this.cameraMotionMode === CameraMotionMode.LERP ? 0.03 : 1
                    );
                    break;
                case CameraMode.KEEP_BALL_IN_VIEW:
                    const screenY = this.getScreenYFromWorldY(ballPosition.y);
                    const visibleHeight = this.getVisibleHeight();
                    const lowerLimit = visibleHeight * 0.6;
                    const upperLimit = visibleHeight * 0.4;
                    let target = this.scrollPosition; // Start with current position

                    if (screenY > visibleHeight - lowerLimit) {
                        target = ballPosition.y - visibleHeight + lowerLimit;
                    } else if (screenY < upperLimit) {
                        target = ballPosition.y - upperLimit;
                    }

                    // Clamp the target to the game world boundaries
                    const maxScroll = CANVAS_HEIGHT - visibleHeight;
                    target = Math.max(0, Math.min(target, maxScroll));

                    this.lerpViewportScrollPositionTo(target, this.cameraMotionMode === CameraMotionMode.LERP ? 0.03 : 1);
                    break;
                case CameraMode.FREE:
                    break;
                case CameraMode.SPECTATOR:
                    break;
            }
        }
    }

    updateScrollPosition(deltaY) {
        this.lastPlayerScrollTime = Date.now();
        const prevScrollPosition = this.scrollPosition;
        this.scrollPosition += (deltaY * 0.4) / this.scale;

        const maxScroll = CANVAS_HEIGHT - this.getVisibleHeight();
        const minScroll = 0;
        this.scrollPosition = Math.max(minScroll, Math.min(this.scrollPosition, maxScroll));

        if (this.scrollPosition !== prevScrollPosition) {
            this.gameWorld.y = -this.scrollPosition * this.scale;
        }

        return this.scrollPosition !== prevScrollPosition;
    }

    getWorldYFromScreenY(screenY) {
        return (screenY / this.scale) + this.scrollPosition;
    }

    getWorldXFromScreenX(screenX) {
        return screenX / this.scale - (this.gameWorld ? this.gameWorld.x / this.scale : 0);
    }

    getScreenXFromWorldX(worldX) {
        return (worldX + (this.gameWorld ? this.gameWorld.x / this.scale : 0)) * this.scale;
    }

    getScreenYFromWorldY(worldY) {
        return (worldY - this.scrollPosition) * this.scale;
    }

    getVisibleHeight() {
        return window.innerHeight / this.scale;
    }

    public setCameraMode(mode: CameraMode) {
        this.cameraMode = mode;
    }

    public setCameraMotionMode(mode: CameraMotionMode) {
        this.cameraMotionMode = mode;
    }

    public setSpectateAfterFinished(value: boolean): void {
        this.spectateAfterFinished = value;
    }
}