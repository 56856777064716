import { RoomState, Player, BallUpdate, GameMode } from '../shared/sharedTypes';
import SocketClient from './socket-client';
import SoundManager from './sound';
import PlatformManager from './platformManager';
import { calculateCoinDashScore } from '../shared/utils.js';

export default class GameStateManager {
    private socketClient: SocketClient;
    private soundManager: SoundManager;
    public isroundEnded: boolean;
    public isGameOver: boolean;
    private currentPlayerId: string | null;
    private gameStartTime: number;
    private roundTimer: NodeJS.Timeout | null;
    public platformManager: PlatformManager | null;
    private _roomState: RoomState;
    timeUntilNextRound: number;

    constructor(bootstrapRoomState: RoomState, socketClient: SocketClient, soundManager: SoundManager) {
        this.socketClient = socketClient;
        this.isroundEnded = false;
        this.isGameOver = false;
        this.currentPlayerId = socketClient.socket.id as string;
        this.gameStartTime = Date.now();
        this.roundTimer = null;
        this.setEventListeners();
        this.platformManager = null;
        this.soundManager = soundManager;
        this._roomState = bootstrapRoomState;
        this.socketClient.handleCoinCollected = this.onCoinCollected.bind(this);
        this.socketClient.handleInitialPeerData = this.handleInitialPeerData.bind(this);
    }

    // Public getter for read-only access
    public get roomState(): Readonly<RoomState> {
        return this._roomState;
    }

    private setEventListeners(): void {
        // this.matterWorker.onmessage = this.handleWorkerMessage.bind(this);

        this.socketClient.socket.on('roomStateReceived', this.handleRoomStateReceived.bind(this));
        this.socketClient.socket.on('roundStarted', this.handleRoundStarted.bind(this));
        this.socketClient.socket.on('coinCollected', this.onCoinCollected.bind(this));
        this.socketClient.socket.on('playerJoined', this.addPlayer.bind(this));
        this.socketClient.socket.on('playerLeft', this.removePlayer.bind(this));
        this.socketClient.socket.on('playerUpdated', this.updatePlayer.bind(this));
        this.socketClient.socket.on('roundEnded', this.handleRoundEnded.bind(this));
        this.socketClient.socket.on('ballUpdated', this.updatePlayer.bind(this));

        this.socketClient.socket.on('batchedBallUpdates', (updates: { [playerId: string]: BallUpdate }) => {
            switch (this._roomState.roomSettings.gameMode) {
                case GameMode.RegularGolf:
                    Object.entries(updates).forEach(([playerId, update]) => {
                        const updatedClicks = [...this._roomState.players[playerId].scoring.clicks];
                        updatedClicks[this._roomState.round] = update.clicks;
                        const updatedScoring = { ...this._roomState.players[playerId].scoring, clicks: updatedClicks, score: updatedClicks };
                        this.updatePlayer(playerId, { scoring: updatedScoring });
                    });
                    break;
                case GameMode.CoinDash:
                    Object.entries(updates).forEach(([playerId, update]) => {
                        this.updatePlayer(playerId, { highestHeight: update.highestHeight });
                    });
            }

        });
    }
    public updatePlayerFromWebRTCBallUpdate(playerId: string, update: BallUpdate): void {
        if (this._roomState.players[playerId]) {
            const player = this._roomState.players[playerId];
            const currentRound = this._roomState.round;

            // Update position, motion state, and highest height
            player.position = update.position;
            player.inMotion = update.inMotion;
            player.initialPosition = update.initialPosition;
            player.highestHeight = Math.max(player.highestHeight || 0, update.highestHeight);
            player.angle = update.angle;

            // Update scoring
            if (!player.scoring) {
                player.scoring = {
                    score: Array(this._roomState.totalRounds).fill(0),
                    coins: Array(this._roomState.totalRounds).fill(0),
                    clicks: Array(this._roomState.totalRounds).fill(0),
                    hx: Array(this._roomState.totalRounds).fill(0),
                    ttg: Array(this._roomState.totalRounds).fill(0)
                };
            }

            // Merge incoming scoring data
            ['score', 'coins', 'clicks', 'hx', 'ttg'].forEach(key => {
                if (update.scoring[key]) {
                    player.scoring[key][currentRound] = update.scoring[key][currentRound];
                }
            });

            switch (this._roomState.roomSettings.gameMode) {
                case GameMode.RegularGolf:
                    player.scoring.score[currentRound] = player.scoring.clicks[currentRound];
                    break;
                case GameMode.CoinDash:
                    const updatedHX = Math.max(player.scoring.hx[currentRound], update.highestHeight);
                    player.scoring.hx[currentRound] = updatedHX;
                    player.scoring.score[currentRound] = calculateCoinDashScore(updatedHX, player.scoring.coins[currentRound]);
                    break;
            }
        }
    }

    private handleRoomStateReceived(roomState: RoomState): void {
        this._roomState = roomState;
    }

    private handleRoundStarted(data: { round: number; startTime: number }): void {
        this._roomState.roundStartTime = data.startTime;
        this._roomState.round = data.round;
        this.isroundEnded = false;
        this.isGameOver = false;
        for (const playerId in this._roomState.players) {
            // double-check if i didnt hit the goal last round and it was regularGolf, then set my score to 15
            if (this._roomState.roomSettings.gameMode === GameMode.RegularGolf && !this._roomState.players[playerId].hasHitTheGoal) {
                this._roomState.players[playerId].scoring.clicks[this._roomState.round] = 15;
            }
            this.updatePlayer(playerId, { hasHitTheGoal: false });
        }
        // if this is the first round of the game, reset all of everyone's scores
        if (this._roomState.round === 0) {
            for (const playerId in this._roomState.players) {
                this._roomState.players[playerId].scoring = {
                    score: Array(this._roomState.totalRounds).fill(0),
                    clicks: Array(this._roomState.totalRounds).fill(0),
                    coins: Array(this._roomState.totalRounds).fill(0),
                    hx: Array(this._roomState.totalRounds).fill(0)
                };
            }
        }
    }

    public clearRoundTimer(): void {
        if (this.roundTimer) {
            clearTimeout(this.roundTimer);
            this.roundTimer = null;
        }
    }

    private handleRoundEnded(data): void {
        this.isroundEnded = true;
        console.log("round ended", this._roomState.players[this.socketClient.socket.id].hasHitTheGoal);
        // send everyone's final score to each other 
        // if this is regularGolf, and i havent finished, then set my score for this round to 15
        if (this._roomState.roomSettings.gameMode === GameMode.RegularGolf && !this._roomState.players[this.socketClient.socket.id].hasHitTheGoal) {
            console.log("setting my score to 15");
            this._roomState.players[this.socketClient.socket.id].scoring.clicks[this._roomState.round] = 15;
        }

        // send my final scores to everyone
        this.socketClient.sendToPeers({ id: this.socketClient.socket.id, type: "finalScore", data: this._roomState.players[this.socketClient.socket.id].scoring });
        if (this._roomState.round === this._roomState.totalRounds - 1) {
            this.isGameOver = true;
        }
        this.timeUntilNextRound = data.timeUntilNextRound;
        this._roomState.round = data.round;
        // this._roomState.players = data.players;

        const adjustedTimeUntilNextRound = this.timeUntilNextRound - this.getRoundTimeRemaining();
        setTimeout(() => {
            this.isroundEnded = false;
            this.isGameOver = false;
        }, adjustedTimeUntilNextRound);
    }
    public handleFinalScore(id, scoring) {
        this._roomState.players[id].scoring = scoring;
    }
    private addPlayer(player: Player): void {
        if (!this._roomState.players[player.id]) {
            if (this.socketClient.socket.id !== player.id) {
                this.soundManager.playPlayerJoinSound();
            }
            this._roomState.players[player.id] = player;
        }
    }

    private removePlayer(id: string): void {
        this.soundManager.playPlayerLeaveSound();
        delete this._roomState.players[id];
    }

    private onCoinCollected(data: { playerId: string; index: number; timeStamp: number }): void {
        const { playerId, index } = data;

        if (this._roomState.players[playerId]) {
            const player = this._roomState.players[playerId];
            const round = this._roomState.round;
            player.scoring.coins[round] += 1;
            this.soundManager.playCoinSound();
            this.platformManager?.removeCoin(index, false);
        }
    }

    private handleInitialPeerData(data): void {
        this._roomState.players[data.id] = data;
    }

    private updatePlayer(id: string, playerData: Partial<Player>): void {
        if (this._roomState.players[id]) {
            const player = this._roomState.players[id];
            Object.assign(player, playerData);
            this._roomState.players[id] = player;
        }
    }

    public iHitTheGoal() {

        if (this._roomState.roomSettings.gameMode === GameMode.GolfUP) {
            // set the score for this round to 1
            const scoringCopy = { ...this._roomState.players[this.socketClient.socket.id].scoring };
            scoringCopy.score[this._roomState.round] = 1;
            this.updatePlayer(this.socketClient.socket.id, { scoring: scoringCopy, hasHitTheGoal: true });
        } else {
            this.updatePlayer(this.socketClient.socket.id, { hasHitTheGoal: true });
        }
    }

    public getCurrentPlayer(): Player | undefined {
        return this.currentPlayerId ? this._roomState.players[this.currentPlayerId] : undefined;
    }

    public getGameDuration(): number {
        return (Date.now() - this._roomState.gameStartTime) / 1000; // in seconds
    }

    public getRoundDuration(): number {
        return (Date.now() - this.gameStartTime) / 1000; // in seconds
    }

    public getRoundTimeRemaining(): number {
        const roundStartTime = this._roomState.roundStartTime;
        const roundDuration = this._roomState.roundDuration; // in ms
        return Math.max(0, roundDuration - (Date.now() - roundStartTime));
    }

    public reset(): void {
        this.isroundEnded = false;
        this.isGameOver = false;
        this.gameStartTime = Date.now();
        this.clearRoundTimer();
    }
}